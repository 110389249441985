import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';

export interface ConfirmationDialogData {
  message: string;
}

export interface ConfirmationDialogResult {
  action: boolean;
}

@Component({
  selector: 'jw-confirmation-dialog',
  standalone: true,
  imports: [DynamicDialogModule, ButtonModule],
  templateUrl: './confirmation-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationDialogComponent {
  readonly message: string;

  constructor(
    private dynamicDialogRef: DynamicDialogRef,
    private dynamicDialogConfig: DynamicDialogConfig<ConfirmationDialogData>,
  ) {
    this.message = this.dynamicDialogConfig.data?.message as string;
  }

  cancel(): void {
    this.dynamicDialogRef.close({ action: false });
  }

  accept(): void {
    this.dynamicDialogRef.close({ action: true });
  }
}
