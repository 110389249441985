import { BusinessPlanProjectByIdFeatureConfiguration, BusinessPlanSalesForecastType, Enums } from '@joorney/shell-shared-jwriter-core-api-data-access';
import { JwWordBusinessPlanSectionName, JwWordBusinessPlanTable } from './business-plan.enum';
import { JwWordTable, JwWordTableSection } from './table-section.interface';

export const getBusinessPlanTables = (
  featureConfiguration: BusinessPlanProjectByIdFeatureConfiguration | null,
  salesForecastType?: Enums['BusinessPlanSalesForecastType'] | null,
): JwWordTable[] => {
  const sections = getBusinessPlanSections(featureConfiguration, salesForecastType);
  return sections.reduce((acc, section) => {
    acc.push(...section.tables);
    return acc;
  }, [] as JwWordTable[]);
};

const CHART_BAR_ICON_URL = 'assets/images/icons/chart-bar.svg';
const TEXT_ICON_URL = 'assets/images/icons/t-solid.svg';
const TABLE_ICON_URL = 'assets/images/icons/table.svg';

const SECTION_NAMES: Record<JwWordBusinessPlanSectionName, string> = {
  [JwWordBusinessPlanSectionName.SummaryOfInvestment]: 'Summary Of Investment',
  [JwWordBusinessPlanSectionName.PersonnelPlan]: 'Personnel Plan',
  [JwWordBusinessPlanSectionName.SalesForecast]: 'Sales Forecast',
  [JwWordBusinessPlanSectionName.ProfitAndLoss]: 'Profit and Loss',
  [JwWordBusinessPlanSectionName.BalanceSheet]: 'Balance Sheet',
  [JwWordBusinessPlanSectionName.BreakEvenAnalysis]: 'Break-Even Analysis',
  [JwWordBusinessPlanSectionName.Feasibility]: 'Feasibility',
  [JwWordBusinessPlanSectionName.CompanyImpactOnEconomy]: 'Company\'s Impact on Economy',
  [JwWordBusinessPlanSectionName.CashFlow]: 'Cash Flow',
  [JwWordBusinessPlanSectionName.Conclusion]: 'Conclusion',
  [JwWordBusinessPlanSectionName.ExecutiveSummary]: 'Executive Summary',
};

export const TABLE_NAMES: Record<JwWordBusinessPlanTable, string> = {
  [JwWordBusinessPlanTable.SourceOfFunds]: 'Source of Funds',
  [JwWordBusinessPlanTable.UseOfProceeds]: 'Use of Proceeds',
  [JwWordBusinessPlanTable.PersonnelPlanNumberOfEmployees]: 'Number of Employees per Position',
  [JwWordBusinessPlanTable.PersonnelPlanDesignatedSalary]: 'Designated Salary per Position',
  [JwWordBusinessPlanTable.PersonnelPlan]: 'Personnel Plan',
  [JwWordBusinessPlanTable.PersonnelPlanOrgChart]: 'Org Chart',
  [JwWordBusinessPlanTable.PersonnelSummary]: 'Personnel Summary',
  [JwWordBusinessPlanTable.ApplicantPosition]: 'Applicant\'s Position Intro',
  [JwWordBusinessPlanTable.SalesForecast]: 'Sales Forecast',
  [JwWordBusinessPlanTable.ProfitAndLoss]: 'Pro Forma Profit and Loss',
  [JwWordBusinessPlanTable.ProfitAndLossChart]: 'Pro Forma Profit and Loss Chart',
  [JwWordBusinessPlanTable.BalanceSheetTable]: 'Pro Forma Balance Sheet',
  [JwWordBusinessPlanTable.BreakEvenAnalysisTable]: 'Break-Even Analysis',
  [JwWordBusinessPlanTable.BreakEvenAnalysisChart]: 'Break-Even Analysis Chart',
  [JwWordBusinessPlanTable.FeasibilityAnalysis]: 'Feasibility Analysis',
  [JwWordBusinessPlanTable.CostBenefitAnalysis]: 'Cost / Benefit Analysis',
  [JwWordBusinessPlanTable.CompaniesImpactOnEconomy]: 'Company\'s Impact on Economy Chart',
  [JwWordBusinessPlanTable.CashFlowTable]: 'Cash Flow Table',
  [JwWordBusinessPlanTable.Conclusion]: 'Conclusion Key Numbers',
  [JwWordBusinessPlanTable.ExecutiveSummary]: 'Executive Summary Key Numbers',
};
export const getBusinessPlanSections = (
  featureConfiguration: BusinessPlanProjectByIdFeatureConfiguration | null,
  salesForecastType?: Enums['BusinessPlanSalesForecastType'] | null,
): JwWordTableSection[] => {
  const sections = [
    {
      tag: JwWordBusinessPlanSectionName.SummaryOfInvestment,
      name: SECTION_NAMES.SUMMARY_OF_INVESTMENT,
      tables: [
        {
          tag: JwWordBusinessPlanTable.SourceOfFunds,
          name: TABLE_NAMES.SOURCE_OF_FUNDS,
          imgUrls: [TEXT_ICON_URL, TABLE_ICON_URL],
        },
        {
          tag: JwWordBusinessPlanTable.UseOfProceeds,
          name: TABLE_NAMES.USE_OF_PROCEEDS,
          imgUrls: [TABLE_ICON_URL],
        },
      ],
    },
    {
      tag: JwWordBusinessPlanSectionName.PersonnelPlan,
      name: SECTION_NAMES.PERSONNEL_PLAN,
      tables: [
        {
          tag: JwWordBusinessPlanTable.PersonnelPlanNumberOfEmployees,
          name: TABLE_NAMES.PERSONNEL_PLAN_NUMBER_OF_EMPLOYEES,
          imgUrls: [TABLE_ICON_URL],
        },
        {
          tag: JwWordBusinessPlanTable.PersonnelPlanDesignatedSalary,
          name: TABLE_NAMES.PERSONNEL_PLAN_DESIGNATED_SALARY,
          imgUrls: [TABLE_ICON_URL],
        },
        {
          tag: JwWordBusinessPlanTable.PersonnelPlan,
          name: TABLE_NAMES.PERSONNEL_PLAN,
          imgUrls: [TABLE_ICON_URL],
        },
        {
          tag: JwWordBusinessPlanTable.PersonnelPlanOrgChart,
          name: TABLE_NAMES.PERSONNEL_PLAN_ORG_CHART,
          imgUrls: [CHART_BAR_ICON_URL],
        },
        {
          tag: JwWordBusinessPlanTable.PersonnelSummary,
          name: TABLE_NAMES.PERSONNEL_SUMMARY,
          imgUrls: [TEXT_ICON_URL, TABLE_ICON_URL],
        },
        {
          tag: JwWordBusinessPlanTable.ApplicantPosition,
          name: TABLE_NAMES.APPLICANT_POSITION,
          imgUrls: [TEXT_ICON_URL],
        },
      ],
    },
    {
      tag: JwWordBusinessPlanSectionName.SalesForecast,
      name: SECTION_NAMES.SALES_FORECAST,
      tables: [
        {
          tag: JwWordBusinessPlanTable.SalesForecast,
          name: TABLE_NAMES.SALES_FORECAST,
          imgUrls: [TABLE_ICON_URL],
        },
      ],
    },
    {
      tag: JwWordBusinessPlanSectionName.ProfitAndLoss,
      name: SECTION_NAMES.PROFIT_AND_LOSS,
      tables: [
        {
          tag: JwWordBusinessPlanTable.ProfitAndLoss,
          name: TABLE_NAMES.PROFIT_AND_LOSS,
          imgUrls: [TABLE_ICON_URL],
        },
        {
          tag: JwWordBusinessPlanTable.ProfitAndLossChart,
          name: TABLE_NAMES.PROFIT_AND_LOSS_CHART,
          imgUrls: [CHART_BAR_ICON_URL],
        },
      ],
    },
    {
      tag: JwWordBusinessPlanSectionName.BalanceSheet,
      name: SECTION_NAMES.BALANCE_SHEET,
      tables: [
        {
          tag: JwWordBusinessPlanTable.BalanceSheetTable,
          name: TABLE_NAMES.BALANCE_SHEET_TABLE,
          imgUrls: [TABLE_ICON_URL],
        },
      ],
    },
    {
      tag: JwWordBusinessPlanSectionName.BreakEvenAnalysis,
      name: SECTION_NAMES.BREAK_EVEN_ANALYSIS,
      tables: [
        {
          tag: JwWordBusinessPlanTable.BreakEvenAnalysisTable,
          name: TABLE_NAMES.BREAK_EVEN_ANALYSIS_TABLE,
          imgUrls: [TEXT_ICON_URL, TABLE_ICON_URL],
        },
        {
          tag: JwWordBusinessPlanTable.BreakEvenAnalysisChart,
          name: TABLE_NAMES.BREAK_EVEN_ANALYSIS_CHART,
          disabled: !salesForecastType || salesForecastType === BusinessPlanSalesForecastType.VALUE,
          imgUrls: [CHART_BAR_ICON_URL],
        },
      ],
      disabled: !featureConfiguration?.breakEvenAnalysis,
    },
    {
      tag: JwWordBusinessPlanSectionName.Feasibility,
      name: SECTION_NAMES.FEASIBILITY,
      tables: [
        {
          tag: JwWordBusinessPlanTable.FeasibilityAnalysis,
          name: TABLE_NAMES.FEASIBILITY_ANALYSIS,
          disabled: !featureConfiguration?.feasibilityAnalysis,
          imgUrls: [TEXT_ICON_URL, TABLE_ICON_URL],
        },
        {
          tag: JwWordBusinessPlanTable.CostBenefitAnalysis,
          name: TABLE_NAMES.COST_BENEFIT_ANALYSIS,
          disabled: !featureConfiguration?.feasibilityAnalysis,
          imgUrls: [TEXT_ICON_URL, TABLE_ICON_URL],
        },
      ],
      disabled: !featureConfiguration?.feasibilityAnalysis,
    },
    {
      tag: JwWordBusinessPlanSectionName.CompanyImpactOnEconomy,
      name: SECTION_NAMES.COMPANY_IMPACT_ON_ECONOMY,
      tables: [
        {
          tag: JwWordBusinessPlanTable.CompaniesImpactOnEconomy,
          name: TABLE_NAMES.COMPANIES_IMPACT_ON_ECONOMY,
          imgUrls: [TEXT_ICON_URL, CHART_BAR_ICON_URL],
        },
      ],
    },
    {
      tag: JwWordBusinessPlanSectionName.CashFlow,
      name: SECTION_NAMES.CASH_FLOW,
      tables: [
        {
          tag: JwWordBusinessPlanTable.CashFlowTable,
          name: TABLE_NAMES.CASH_FLOW_TABLE,
          imgUrls: [TABLE_ICON_URL],
        },
      ],
      disabled: !featureConfiguration?.cashFlowTable,
    },
    {
      tag: JwWordBusinessPlanSectionName.Conclusion,
      name: SECTION_NAMES.CONCLUSION,
      tables: [
        {
          tag: JwWordBusinessPlanTable.Conclusion,
          name: TABLE_NAMES.CONCLUSION,
          imgUrls: [TEXT_ICON_URL],
        },
      ],
    },
    {
      tag: JwWordBusinessPlanSectionName.ExecutiveSummary,
      name: SECTION_NAMES.EXECUTIVE_SUMMARY,
      tables: [
        {
          tag: JwWordBusinessPlanTable.ExecutiveSummary,
          name: TABLE_NAMES.EXECUTIVE_SUMMARY,
          imgUrls: [TEXT_ICON_URL],
        },
      ],
    },
  ];

  return sections;
};
