const VOWELS = ['a', 'e', 'i', 'o', 'u'];
const A_ARTICLE = 'a';
const AN_ARTICLE = 'an';

// exception list for words that do not follow the simple vowel rule
const EXCEPTIONS = {
  'x (formerly known as Twitter)': AN_ARTICLE,
};

const LOWERCASE_EXCEPTIONS = Object.fromEntries(Object.entries(EXCEPTIONS).map(([key, value]) => [key.toLowerCase(), value]));

/**
 * Returns the appropriate article ('a' or 'an') based on the first character of a word.
 * Includes handling for special cases like 'instagram'.
 * I.E. 'apple' returns 'an' and 'banana' returns 'a'.
 */
export const getArticle = (word: string) => {
  if (LOWERCASE_EXCEPTIONS[word.toLowerCase()] !== undefined) {
    return LOWERCASE_EXCEPTIONS[word.toLowerCase()];
  }

  const [firstCharacter] = word;
  return VOWELS.includes((firstCharacter ?? '').toLowerCase()) ? AN_ARTICLE : A_ARTICLE;
};
