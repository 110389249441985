import { RouterReducerState, getRouterSelectors } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

const $selectRouter = createFeatureSelector<RouterReducerState>('router');

export const {
  selectCurrentRoute: $currentRoute, // select the current route
  selectFragment: $fragment, // select the current route fragment
  selectQueryParams: $queryParams, // select the current route query params
  selectQueryParam: $queryParam, // factory function to select a query param
  selectRouteParams: $routeParams, // select the current route params
  selectRouteParam: $routeParam, // factory function to select a route param
  selectRouteData, // select the current route data
  selectUrl: $url, // select the current url,
} = getRouterSelectors();

export const ROUTE_PARAM_BUSINESS_PLAN_ID = 'bpID';

interface JwRouteParams {
  [ROUTE_PARAM_BUSINESS_PLAN_ID]?: string;
}

export const $routeNestedParams = createSelector($selectRouter, (router) => {
  if (!router) {
    return {};
  }
  let currentRoute = router.state.root;
  let params: JwRouteParams = {};
  while (currentRoute?.firstChild) {
    currentRoute = currentRoute.firstChild;
    params = {
      ...params,
      ...currentRoute.params,
    };
  }
  return params;
});

export const $routeNestedParam = (key: keyof JwRouteParams) => createSelector($routeNestedParams, (params) => params[key]);
export const $routeBusinessPlanIdParam = createSelector($routeNestedParam(ROUTE_PARAM_BUSINESS_PLAN_ID), (businessPlanId) =>
  businessPlanId !== undefined ? +businessPlanId : undefined,
);
