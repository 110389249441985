import { createFeature, createReducer, on } from '@ngrx/store';
import { MARKETING_SECTION_FEATURE_KEY, marketingSectionActions } from './marketing-section.actions';
import { produce } from 'immer';

export interface MarketingSectionState {
  selectedSectionId: string | null;
}

const initialState: MarketingSectionState = {
  selectedSectionId: null,
};

export const marketingSectionFeature = createFeature({
  name: MARKETING_SECTION_FEATURE_KEY,
  reducer: createReducer(
    initialState,
    on(marketingSectionActions.marketingSectionClicked, (state, { id }) =>
      produce(state, (draft) => {
        draft.selectedSectionId = id;
      }),
    ),
    on(marketingSectionActions.marketingSectionReturnClicked, (state) =>
      produce(state, (draft) => {
        draft.selectedSectionId = null;
      }),
    ),
  ),
});
