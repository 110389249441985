import { createActionGroup, props } from '@ngrx/store';

export interface MessageOptions {
  manualDismiss?: boolean;
}

export const TOAST_FEATURE_KEY = 'toast';

export const toastActions = createActionGroup({
  source: TOAST_FEATURE_KEY,
  events: {
    displaySuccessMessage: props<{ messageText?: string; options?: MessageOptions }>(),
    displayErrorMessage: props<{ messageText?: string; options?: MessageOptions }>(),
    displayWarningMessage: props<{ messageText?: string; options?: MessageOptions }>(),
    displayInfoMessage: props<{ messageText: string; options?: MessageOptions }>(),
  },
});
