import { Injectable } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { BusinessPlanCompareVisaTypeModalFeatureComponent, ModalCompareVisaTypeMetadata } from './business-plan-compare-visa-type-feature.component';

@Injectable()
export class BusinessPlanCompareVisaTypeModalFeatureService {
  constructor(private dialogService: DialogService) {}

  openDialog$(metadata: ModalCompareVisaTypeMetadata) {
    const ref = this.dialogService.open(BusinessPlanCompareVisaTypeModalFeatureComponent, {
      style: { width: '30vw' },
      header: 'Warning',
      data: metadata,
    });
    return ref.onClose as Observable<boolean>;
  }
}
