import { BP_VISA_TYPE_COUNTRY_NAME } from '@joorney/business-plan-shared-frontend-business-plan-domain';
import { usefulWebsitesFeature } from './useful-websites-store.reducer';
import { createSelector } from '@ngrx/store';
import * as _ from 'lodash';

export const {
  selectSelectedRowId: $usefulWebsitesSelectedRowId,
  selectData: $usefulWebsitesData,
  selectCategories: $usefulWebsitesCategories,
  selectFormData: $usefulWebsitesFormData,
  selectSelectedCategoryCountryId: $usefulWebsitesSelectedCategoryCountryId,
  selectIsMine: $usefulWebsitesIsMine,
} = usefulWebsitesFeature;

export const $usefulWebsitesTableData = createSelector($usefulWebsitesData, (usefulWebsites) =>
  usefulWebsites.map((usefulWebsite) => {
    const { countryCode } = usefulWebsite;
    const countryName = BP_VISA_TYPE_COUNTRY_NAME[countryCode] ?? countryCode;
    return { ...usefulWebsite, countryName };
  }),
);

export const $usefulWebsitesFormDataCountryCode = createSelector($usefulWebsitesFormData, (formData) => formData.countryCode);

export const $usefulWebsitesByCountry = createSelector($usefulWebsitesData, (usefulWebsites) => _.groupBy(usefulWebsites, (item) => item.countryCode));

export const $usefulWebsitesCategoriesByCountry = createSelector($usefulWebsitesCategories, (categories) => _.groupBy(categories, (item) => item.countryCode));

export const $usefulWebsitesCategoryCountryIds = createSelector($usefulWebsitesData, (usefulWebsites) =>
  _.chain(usefulWebsites)
    .map((item) => item.categoryCountryId)
    .uniq()
    .value(),
);

export const $usefulWebsitesCountryOptions = createSelector($usefulWebsitesCategoriesByCountry, (categoriesByCountry) =>
  _.chain(categoriesByCountry)
    .map((_, key) => ({ code: key, name: BP_VISA_TYPE_COUNTRY_NAME[key] ?? key }))
    .sortBy(({ name }) => name)
    .value(),
);
export const $usefulWebsitesCategoriesByCountryOptions = createSelector(
  $usefulWebsitesFormDataCountryCode,
  $usefulWebsitesCategoriesByCountry,
  (countryCode, categoriesByCountry) => categoriesByCountry[countryCode ?? ''] ?? [],
);
