import { $contextCurrentBusinessPlanId } from '@joorney/business-plan-shared-frontend-context-store';
import { $tabNavActiveSectionId } from '@joorney/layout-jwriter-word-tab-nav-store';
import { JwwNavSection } from '@joorney/layout-shared-frontend-tab-nav-domain';
import { UsageTimesAppTag } from '@joorney/shell-shared-jwriter-core-api-data-access';
import { createSelector } from '@ngrx/store';

export const $usageTimeEntryData = createSelector({
  businessPlanId: $contextCurrentBusinessPlanId,
  page: $tabNavActiveSectionId,
});

const usageTimesPage: Record<JwwNavSection, string> = {
  [JwwNavSection.Financial]: 'Financial',
  [JwwNavSection.KnowledgeBase]: 'KnowledgeBase',
  [JwwNavSection.MarketingSection]: 'MarketingSection',
  [JwwNavSection.DataSync]: 'DataSync',
  // unhandled pages
  [JwwNavSection.Beta]: 'None',
};

export const usageTimeAppPages = {
  appTag: UsageTimesAppTag.JWW,
  usageTimesPage,
};
