import { Route } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { JW_WORD_ROUTING_DICTIONARY } from '@joorney/shell-jwriter-word-main-routing-domain';

export const JW_WORD_MAIN_ROUTING_ROUTES: Route[] = [
  { path: JW_WORD_ROUTING_DICTIONARY.login.path, loadChildren: () => import('@joorney/users-jwriter-word-login-page-feature').then((m) => m.JW_WORD_LOGIN_PAGE_ROUTES) },
  {
    path: '',
    canActivate: [MsalGuard],
    children: [
      { path: JW_WORD_ROUTING_DICTIONARY.poc.path, loadChildren: () => import('@joorney/ms-office-jwriter-word-content-poc-feature').then((m) => m.JW_WORD_POC_PAGE_ROUTES) },
      { path: JW_WORD_ROUTING_DICTIONARY.debug.path, loadChildren: () => import('@joorney/ms-office-jwriter-word-debug-content-feature').then((m) => m.JW_WORD_DEBUG_PAGE_ROUTES) },
      { path: '', loadChildren: () => import('@joorney/shell-jwriter-word-main-page-feature').then((m) => m.JW_WORD_MAIN_PAGE_ROUTES) },
    ],
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '',
  },
];
