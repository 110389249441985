import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BusinessPlanGetVisaTypeComparisonDTO } from '@joorney/shell-shared-jwriter-core-api-data-access';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { RippleModule } from 'primeng/ripple';

export interface ModalCompareVisaTypeMetadata extends BusinessPlanGetVisaTypeComparisonDTO {
  currentVisaType: string;
  newVisaType: string;
}

const FEATURE_CONFIGURATION_LABELS = {
  fixedDateView: 'Fixed data view',
  breakEvenAnalysis: 'Break even analysis',
  personnelTable: 'Personnel table',
  costBenefitAnalysis: 'Cost benefit analysis',
  feasibilityAnalysis: 'Feasibility Analysis',
  cashFlowTable: 'Cashflow table',
};

@Component({
  selector: 'jw-business-plan-visa-type-change-modal-feature',
  standalone: true,
  imports: [ButtonModule, RippleModule],
  templateUrl: './business-plan-compare-visa-type-modal-feature.component.html',
  styleUrl: './business-plan-compare-visa-type-modal-feature.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BusinessPlanCompareVisaTypeModalFeatureComponent {
  currentVisaType = '';
  newVisaType = '';
  gainedFeatures: string[] = [];
  lostFeatures: string[] = [];
  showGainedFeatures = false;
  showLostFeatures = false;

  constructor(
    private dynamicDialogConfig: DynamicDialogConfig<ModalCompareVisaTypeMetadata>,
    private dynamicDialogRef: DynamicDialogRef,
  ) {
    this.setData(this.dynamicDialogConfig.data as ModalCompareVisaTypeMetadata);
  }

  private setData(data: ModalCompareVisaTypeMetadata) {
    const { currentVisaType, newVisaType, gainedFeaturesNames, lostFeaturesNames } = data;
    this.currentVisaType = currentVisaType;
    this.newVisaType = newVisaType;
    if (gainedFeaturesNames.length) {
      this.showGainedFeatures = true;
      this.gainedFeatures = gainedFeaturesNames.map((key) => FEATURE_CONFIGURATION_LABELS[key] ?? key);
    }
    if (lostFeaturesNames.length) {
      this.showLostFeatures = true;
      this.lostFeatures = lostFeaturesNames.map((key) => FEATURE_CONFIGURATION_LABELS[key] ?? key);
    }
  }

  confirmConfirmation(): void {
    this.dynamicDialogRef.close(true);
  }

  cancelConfirmation(): void {
    this.dynamicDialogRef.close(false);
  }
}
