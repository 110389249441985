import {
  OTHER_MARKETING_STRATEGY_ITEMS,
  OtherMarketingStrategiesPayload,
  OtherMarketingStrategy,
  SOCIAL_MEDIA_ITEMS,
  SocialMedia,
  SocialMediaPayload,
  WebsiteStatus,
} from '@joorney/marketing-jwriter-word-marketing-section-domain';
import { WORD_BOLD_TAG, WORD_FOOTNOTE_TAG, WORD_HIGHLIGHT_TAG } from '@joorney/ms-office-jwriter-word-office-api-data-access';
import { enumerateValuesToString, getArticle } from '@joorney/utils-shared-ts-utils';

//#region Events Shows
export const generateNoEngagedInEventsText = (name: string, countryName: string) =>
  `${name} will regularly attend industry-relevant trade shows in the country (${countryName}), which will represent a great opportunity to raise brand awareness among potential customers. Trade shows make a significant contribution to many business sectors around the world. Forging alliances with other industry-related businesses can help the Company to enter vertical markets that it may not be able to break into otherwise. During each trade show, ${name} will have the opportunity to meet with potential partners and customers and, in turn, promote its services. Additionally, trade shows serve to expose the current strategies and best offerings of the industry’s leading players.`;

export const generateEngagedInEventsText = (name: string, countryName: string, events: string[]) => {
  const eventsText = events.length ? `<${WORD_HIGHLIGHT_TAG}>The Company has attended ${enumerateValuesToString(events)} event(s).</${WORD_HIGHLIGHT_TAG}> ` : '';
  return `${name} regularly attends industry-relevant trade shows in the country (${countryName}), which represent a great opportunity to raise brand awareness among potential customers. ${eventsText}Trade shows make a significant contribution to many business sectors around the world. Forging alliances with other industry-related businesses can help the Company to enter vertical markets that it may not be able to break into otherwise. During each trade show, ${name} has an opportunity to meet with potential partners and customers and, in turn, promote its services. Additionally, trade shows serve to expose the current strategies and best offerings of the industry’s leading players.`;
};
//#endregion Events

//#region Website
export const generateWebsiteWithNoUrlText = (name: string) => [
  {
    paragraphText: `${name} will maintain a user-friendly website. Search engine optimization of the website will ensure that proper titles, meta descriptions, and keywords, both short- and long-tailed, will be present on all pages of the website.`,
    footNotes: [],
  },
  {
    paragraphText: `The Company will also devote a part of its budget to digital marketing in order to drive traffic to its website. Digital marketing will primarily focus on Google Ads keyword advertising campaigns and the display network. In addition to directly bringing visitors to the website, the digital marketing campaigns will build brand awareness by displaying ${name}’s logo and ads on the Google display network, and, therefore, numerous websites with visitors interested in the Company’s <${WORD_HIGHLIGHT_TAG}>products/services</${WORD_HIGHLIGHT_TAG}>.`,
    footNotes: [],
  },
  {
    paragraphText: `Proper search engine optimization and successful digital marketing campaigns will be among the key growth and development factors of ${name}’s business. Online marketing and promotional efforts will help the Company rank higher in search engine result pages, which will lead to an increased number of visitors and, consequently, <${WORD_HIGHLIGHT_TAG}>customers/clients</${WORD_HIGHLIGHT_TAG}>.`,
    footNotes: [],
  },
  {
    paragraphText: `${name} will promote the website by conducting keyword marketing campaigns that focus on words and phrases such <${WORD_HIGHLIGHT_TAG}>as you figure it out</${WORD_HIGHLIGHT_TAG}>.`,
    footNotes: [],
  },
  { paragraphText: ` <${WORD_HIGHLIGHT_TAG}>GOOGLE KEY WORDS SNIP</${WORD_HIGHLIGHT_TAG}> `, footNotes: [] }, // paragraphs starting with mark tags must have spaces to avoid highlighting the whole paragraph
  {
    paragraphText: `<${WORD_FOOTNOTE_TAG}>According to Google Trends,</${WORD_FOOTNOTE_TAG}> as demonstrated in the graph above, the term <${WORD_HIGHLIGHT_TAG}>you figure it out</${WORD_HIGHLIGHT_TAG}> has been showing a <${WORD_HIGHLIGHT_TAG}>consistent/rising</${WORD_HIGHLIGHT_TAG}> search frequency over the previous few years, and this trend is expected to continue.`,
    footNotes: ['Source: https://trends.google.com/trends/'],
  },
  { paragraphText: ` <${WORD_HIGHLIGHT_TAG}>GOOGLE KEY WORDS SNIP</${WORD_HIGHLIGHT_TAG}> `, footNotes: [] }, // paragraphs starting with mark tags must have spaces to avoid highlighting the whole paragraph
  {
    paragraphText: `<${WORD_FOOTNOTE_TAG}>According to Google Trends,</${WORD_FOOTNOTE_TAG}> as demonstrated in the graph above, the term <${WORD_HIGHLIGHT_TAG}>you figure it out</${WORD_HIGHLIGHT_TAG}> has been showing a <${WORD_HIGHLIGHT_TAG}>consistent/rising</${WORD_HIGHLIGHT_TAG}> search frequency over the previous few years, and this trend is expected to continue.`,
    footNotes: ['Source: https://trends.google.com/trends/'],
  },
];

export const generateWebsiteWithUrlText = (name: string, websiteStatus: WebsiteStatus, websiteUrl: string) => {
  switch (websiteStatus) {
    case WebsiteStatus.HasDomain:
      return [
        {
          paragraphText: `${name} will maintain a user-friendly website at the address: ${websiteUrl}. Search engine optimization of the website will ensure that proper titles, meta descriptions, and keywords, both short- and long-tailed, will be present on all pages of the website.`,
          footNotes: [],
        },
        {
          paragraphText: `The Company will also devote a part of its budget to digital marketing in order to drive traffic to its website. Digital marketing will primarily focus on Google Ads keyword advertising campaigns and the display network. In addition to directly bringing visitors to the website, the digital marketing campaigns will build brand awareness by displaying ${name}’s logo and ads on the Google display network, and, therefore, numerous websites with visitors interested in the Company’s <${WORD_HIGHLIGHT_TAG}>products/services</${WORD_HIGHLIGHT_TAG}>.`,
          footNotes: [],
        },
        {
          paragraphText: `Proper search engine optimization and successful digital marketing campaigns will be among the key growth and development factors of ${name}’s business. Online marketing and promotional efforts will help the Company rank higher in search engine result pages, which will lead to an increased number of visitors and, consequently, <${WORD_HIGHLIGHT_TAG}>customers/clients</${WORD_HIGHLIGHT_TAG}>.`,
          footNotes: [],
        },
        {
          paragraphText: `${name} will promote the website by conducting keyword marketing campaigns that focus on words and phrases such <${WORD_HIGHLIGHT_TAG}>as you figure it out</${WORD_HIGHLIGHT_TAG}>.`,
          footNotes: [],
        },
        { paragraphText: ` <${WORD_HIGHLIGHT_TAG}>GOOGLE KEY WORDS SNIP</${WORD_HIGHLIGHT_TAG}> `, footNotes: [] }, // paragraphs starting with mark tags must have spaces to avoid highlighting the whole paragraph
        {
          paragraphText: `<${WORD_FOOTNOTE_TAG}>According to Google Trends,</${WORD_FOOTNOTE_TAG}> as demonstrated in the graph above, the term <${WORD_HIGHLIGHT_TAG}>you figure it out</${WORD_HIGHLIGHT_TAG}> has been showing a <${WORD_HIGHLIGHT_TAG}>consistent/rising</${WORD_HIGHLIGHT_TAG}> search frequency over the previous few years, and this trend is expected to continue.`,
          footNotes: ['Source: https://trends.google.com/trends/'],
        },
        { paragraphText: ` <${WORD_HIGHLIGHT_TAG}>GOOGLE KEY WORDS SNIP</${WORD_HIGHLIGHT_TAG}> `, footNotes: [] }, // paragraphs starting with mark tags must have spaces to avoid highlighting the whole paragraph
        {
          paragraphText: `<${WORD_FOOTNOTE_TAG}>According to Google Trends,</${WORD_FOOTNOTE_TAG}> as demonstrated in the graph above, the term <${WORD_HIGHLIGHT_TAG}>you figure it out</${WORD_HIGHLIGHT_TAG}> has been showing a <${WORD_HIGHLIGHT_TAG}>consistent/rising</${WORD_HIGHLIGHT_TAG}> search frequency over the previous few years, and this trend is expected to continue.`,
          footNotes: ['Source: https://trends.google.com/trends/'],
        },
      ];

    case WebsiteStatus.HasWebsite:
      return [
        {
          paragraphText: `${name} maintains a user-friendly website at ${websiteUrl}. Search engine optimization of the website will ensure that proper titles, meta descriptions, and keywords, both short- and long-tailed, are present on all pages of the website.`,
          footNotes: [],
        },
        {
          paragraphText: `The Company will also devote a part of its budget to digital marketing in order to drive traffic to its website. Digital marketing will primarily focus on Google Ads keyword advertising campaigns and the display network. In addition to directly bringing visitors to the website, the digital marketing campaigns will build brand awareness by displaying ${name}’s logo and ads on the Google display network, and, therefore, numerous websites with visitors interested in the Company’s <${WORD_HIGHLIGHT_TAG}>products/services</${WORD_HIGHLIGHT_TAG}>.`,
          footNotes: [],
        },
        {
          paragraphText: `Proper search engine optimization and successful digital marketing campaigns will be among the key growth and development factors of ${name}’s business. Online marketing and promotional efforts will help The Company rank higher in search engine result pages, which will lead to an increased number of visitors and, consequently, <${WORD_HIGHLIGHT_TAG}>customers/clients</${WORD_HIGHLIGHT_TAG}>.`,
          footNotes: [],
        },
        {
          paragraphText: `${name} will promote the website by conducting keyword marketing campaigns that focus on words and phrases such <${WORD_HIGHLIGHT_TAG}>as you figure it out</${WORD_HIGHLIGHT_TAG}>.`,
          footNotes: [],
        },
        { paragraphText: ` <${WORD_HIGHLIGHT_TAG}>GOOGLE KEY WORDS SNIP</${WORD_HIGHLIGHT_TAG}> `, footNotes: [] }, // paragraphs starting with mark tags must have spaces to avoid highlighting the whole paragraph
        {
          paragraphText: `<${WORD_FOOTNOTE_TAG}>According to Google Trends,</${WORD_FOOTNOTE_TAG}> as demonstrated in the graph above, the term <${WORD_HIGHLIGHT_TAG}>you figure it out</${WORD_HIGHLIGHT_TAG}> has been showing a <${WORD_HIGHLIGHT_TAG}>consistent/rising</${WORD_HIGHLIGHT_TAG}> search frequency over the previous few years, and this trend is expected to continue.`,
          footNotes: ['Source: https://trends.google.com/trends/'],
        },
        { paragraphText: ` <${WORD_HIGHLIGHT_TAG}>GOOGLE KEY WORDS SNIP</${WORD_HIGHLIGHT_TAG}> `, footNotes: [] }, // paragraphs starting with mark tags must have spaces to avoid highlighting the whole paragraph
        {
          paragraphText: `<${WORD_FOOTNOTE_TAG}>According to Google Trends,</${WORD_FOOTNOTE_TAG}> as demonstrated in the graph above, the term <${WORD_HIGHLIGHT_TAG}>you figure it out</${WORD_HIGHLIGHT_TAG}> has been showing a <${WORD_HIGHLIGHT_TAG}>consistent/rising</${WORD_HIGHLIGHT_TAG}> search frequency over the previous few years, and this trend is expected to continue.`,
          footNotes: ['Source: https://trends.google.com/trends/'],
        },
      ];
    default:
      return [{ paragraphText: '', footNotes: [] }];
  }
};
//#endregion Website

//#region Review Platforms
export const generateReviewPlatformsText = (name: string, reviewPlatforms: string[]) => ({
  paragraphText: `${name} <${WORD_HIGHLIGHT_TAG}>maintains / will maintain</${WORD_HIGHLIGHT_TAG}> pages on review platforms such as ${enumerateValuesToString(reviewPlatforms)} to showcase its business and connect with potential consumers. <${WORD_FOOTNOTE_TAG}>According to Qualtrics</${WORD_FOOTNOTE_TAG}>, nearly 97% of consumers now use online media when researching products or services in their local area. Review-based platforms <${WORD_HIGHLIGHT_TAG}>allow / will allow</${WORD_HIGHLIGHT_TAG}> the Company to increase brand awareness and let potential customers see other customers’ opinions and experiences in order to gain better insight into ${name}’s <${WORD_HIGHLIGHT_TAG}>products/services</${WORD_HIGHLIGHT_TAG}>. The feedback obtained from customers <${WORD_HIGHLIGHT_TAG}>help / will help</${WORD_HIGHLIGHT_TAG}> the Company stay in touch with its customer base. Reading positive testimonials from other customers will increase the willingness of potential consumers to purchase <${WORD_HIGHLIGHT_TAG}>products/services</${WORD_HIGHLIGHT_TAG}>. Moreover, review platforms <${WORD_HIGHLIGHT_TAG}>prove / will prove</${WORD_HIGHLIGHT_TAG}> highly beneficial in attracting clients, who trust positive reviews and opinions when deciding where to spend their money.`,
  footNotes: ['Source: https://www.qualtrics.com/blog/online-review-stats/'],
});
//#endregion Review Platforms

//#region Social Media
const getSocialMediaListText = (socialMedia: { type: SocialMedia }[]) => enumerateValuesToString(socialMedia.map(({ type }) => SOCIAL_MEDIA_ITEMS[type].textName));

const getCurrentSocialMediaText = (name: string, socialMedia: SocialMediaPayload[]) => {
  const hasSocialMediaList = socialMedia
    .filter(({ status }) => status === 'Has')
    .map(({ type, url }) => `${getArticle(SOCIAL_MEDIA_ITEMS[type].textName)} ${SOCIAL_MEDIA_ITEMS[type].textName} page at: ${url}`);
  const hasSocialMediaListText = enumerateValuesToString(hasSocialMediaList);
  return hasSocialMediaListText === '' ? '' : `This is why ${name} maintains ${hasSocialMediaListText}.`;
};

const socialMediaIntroText = 'An ever-expanding social media presence is crucial in the modern business environment.';

export const generateSocialMediaText = (name: string, socialMedia: SocialMediaPayload[]) => {
  const futureSocialMediaListText = getSocialMediaListText(socialMedia.filter(({ status }) => status === 'Will'));

  if (socialMedia.every(({ status }) => ['Will', 'None'].includes(status))) {
    const futureSocialMediaText =
      futureSocialMediaListText === '' ? '' : `This is why ${name} will maintain professional pages on social media outlets, such as ${futureSocialMediaListText}.`;
    const futureSocialMediaBenefitsText = `The focus on social media will help ${futureSocialMediaListText === '' ? name : 'the Company'} build awareness and credibility and allow it to communicate directly with prospective clients and understand their needs. By using social media, the Company will build long-lasting relationships with clients and increase awareness of and demand for its <${WORD_HIGHLIGHT_TAG}>products/services</${WORD_HIGHLIGHT_TAG}>.`;
    return [[socialMediaIntroText, futureSocialMediaText, futureSocialMediaBenefitsText].filter((t) => t !== '').join(' ')];
  }

  const currentSocialMediaText = getCurrentSocialMediaText(name, socialMedia);
  const currentSocialMediaBenefitsText = `The focus on social media helps the Company build awareness and credibility and allows it to communicate directly with prospective clients and understand their needs. By using social media, ${currentSocialMediaText === '' ? name : 'the Company'} builds long-lasting relationships with clients and increases awareness of and demand for its <${WORD_HIGHLIGHT_TAG}>products/services</${WORD_HIGHLIGHT_TAG}>.`;

  if (socialMedia.every(({ status }) => ['Has', 'None'].includes(status))) {
    return [[socialMediaIntroText, currentSocialMediaText, currentSocialMediaBenefitsText].filter((t) => t !== '').join(' ')];
  }

  const mixedSocialMediaText = `${currentSocialMediaText} Furthermore, the Company will also create profiles on ${futureSocialMediaListText}.`;
  return [`${socialMediaIntroText} ${mixedSocialMediaText}`, currentSocialMediaBenefitsText];
};
//#endregion Social Media

//#region Other Marketing Strategies
const emailCampaignsText = (name: string, status: OtherMarketingStrategiesPayload['status']) =>
  status === 'Has'
    ? {
        paragraphText: `${name} periodically executes focused email outreach initiatives and curated newsletters to engage its target clients. These targeted email campaigns are crafted to engage clients operating in various segments within the business community. Utilizing extensive databases, the Company customizes its messaging to cater to its clients’ unique needs and preferences. These campaigns serve as direct channels for ${name} to share valuable insights, success stories, and updates regarding its marketing and sales strategies services.`,
        footNotes: [],
      }
    : {
        paragraphText: `${name} will periodically execute focused email outreach initiatives and curated newsletters to engage its target clients. These targeted email campaigns will be crafted to engage clients operating in various segments within the business community. Utilizing extensive databases, the Company will customize its messaging to cater to its clients’ unique needs and preferences. These campaigns will serve as direct channels for ${name} to share valuable insights, success stories, and updates regarding its marketing and sales strategies services.`,
        footNotes: [],
      };

const wordMouthMarketingText = (name: string, status: OtherMarketingStrategiesPayload['status']) =>
  status === 'Has'
    ? {
        paragraphText: `As ${name} grows, referrals will become an essential component in further expanding brand recognition. Those who establish relationships with the Company will share their experiences of the ${name}’s services with their own personal and professional contacts. This will generate priceless leads, as well as a strong base of prospective new customers and clients. <${WORD_FOOTNOTE_TAG}>According to research conducted by Ogilvy, Google, and TNS, word of mouth remains among the most influential forms of advertising, with 74% of consumers identifying word-of-mouth marketing as a key influencer in their purchasing decision.</${WORD_FOOTNOTE_TAG}>`,
        footNotes: ['Source: 42 Referral Marketing Statistics to get you started on RAF Program'],
      }
    : {
        paragraphText: `As ${name} grows, referrals will become an essential component in further expanding brand recognition. Those who establish relationships with the Company will share their experiences of the ${name}’s services with their own personal and professional contacts. This will generate priceless leads, as well as a strong base of prospective new customers and clients. <${WORD_FOOTNOTE_TAG}>According to research conducted by Ogilvy, Google, and TNS, word of mouth remains among the most influential forms of advertising, with 74% of consumers identifying word-of-mouth marketing as a key influencer in their purchasing decision.</${WORD_FOOTNOTE_TAG}>`,
        footNotes: ['Source: 42 Referral Marketing Statistics to get you started on RAF Program'],
      };

const brandedMaterialText = (name: string, status: OtherMarketingStrategiesPayload['status']) =>
  status === 'Has'
    ? {
        paragraphText: `${name} maintains a range of promotional materials, including flyers, booklets, catalogues, and brochures. These materials will be thoughtfully designed to effectively communicate the Company's diverse services and commitment to <${WORD_HIGHLIGHT_TAG}>sustainable energy solutions</${WORD_HIGHLIGHT_TAG}>. By distributing these informative materials at events, workshops, and promotional campaigns, ${name} will raise awareness, attract potential clients, and establish a positive brand image.`,
        footNotes: [],
      }
    : {
        paragraphText: `${name} will introduce a range of promotional materials, including flyers, booklets, catalogues, and brochures. These materials will be thoughtfully designed to effectively communicate the Company's diverse services and commitment to <${WORD_HIGHLIGHT_TAG}>sustainable energy solutions</${WORD_HIGHLIGHT_TAG}>. By distributing these informative materials at events, workshops, and promotional campaigns, ${name} will raise awareness, attract potential clients, and establish a positive brand image.`,
        footNotes: [],
      };

const newspapersMagazinesText = (name: string, status: OtherMarketingStrategiesPayload['status']) =>
  status === 'Has'
    ? {
        paragraphText: `Newspaper and magazine advertising can target specific demographics that are traditionally more difficult to reach through other mediums. This allows companies to reach smaller, niche audiences, including those in specific geographical areas. This targeted advertising can range from simple inserts to regular weekly sections in local newspapers and other special publications. Newspapers and magazines offer more flexibility than any other advertising medium. Advertisers choose the exact size and location of the ad (including section and page), as well as the design. They also choose the exact time the ad appears and its frequency, ensuring that readers will see the same information, in the same format, and in the same location. ${name} advertises its services through industry-relevant magazines and newspapers, thus effectively reaching targets who stand to benefit the most from the Company’s presence and who are most likely to choose its <${WORD_HIGHLIGHT_TAG}>products/services</${WORD_HIGHLIGHT_TAG}>.`,
        footNotes: [],
      }
    : {
        paragraphText: `Newspaper and magazine advertising can target specific demographics that are traditionally more difficult to reach through other mediums. This allows companies to reach smaller, niche audiences, including those in specific geographical areas. This targeted advertising can range from simple inserts to regular weekly sections in local newspapers and other special publications. Newspapers and magazines offer more flexibility than any other advertising medium. Advertisers choose the exact size and location of the ad (including section and page), as well as the design. They also choose the exact time the ad appears and its frequency, ensuring that readers will see the same information, in the same format, and in the same location. ${name} will advertise its services through industry-relevant magazines and newspapers, thus effectively reaching targets who stand to benefit the most from the Company’s presence and who are most likely to choose its <${WORD_HIGHLIGHT_TAG}>products/services</${WORD_HIGHLIGHT_TAG}>.`,
        footNotes: [],
      };

const eCommerceMarketingText = (name: string, status: OtherMarketingStrategiesPayload['status']) =>
  status === 'Has'
    ? {
        paragraphText: `${name} has established presence on large e-commerce platforms, popular in the U.S., such as <${WORD_HIGHLIGHT_TAG}>Amazon and eBay</${WORD_HIGHLIGHT_TAG}>. <${WORD_HIGHLIGHT_TAG}>Moreover, the Company capitalizes on tools available through social media platforms, such as Facebook Marketplace and Instagram sales options</${WORD_HIGHLIGHT_TAG}>. Through these platforms, ${name} is able to list its products and ensure that those products are displayed as ads on the internet when relevant search phrases are used. In addition to being a powerful sales tool, e-commerce platforms also act as a strong marketing tools, as customers can leave their reviews and commentaries about the Company’s products. This will ensure that ${name}’s customers can make informed decisions when opting for its products, while also acting as a strong word-of-mouth marketing tool. <${WORD_HIGHLIGHT_TAG}>Lastly, the Company has also established  a user-friendly website to act as its proprietary e-commerce platform</${WORD_HIGHLIGHT_TAG}>.`,
        footNotes: [],
      }
    : {
        paragraphText: `${name} has will establish presence on large e-commerce platforms, popular in the U.S., such as <${WORD_HIGHLIGHT_TAG}>Amazon and eBay</${WORD_HIGHLIGHT_TAG}>. <${WORD_HIGHLIGHT_TAG}>Moreover, the Company capitalizes/will capitalize on tools available through social media platforms, such as Facebook Marketplace and Instagram sales options</${WORD_HIGHLIGHT_TAG}>. Through these platforms, ${name} will be able to list its products and ensure that those products are displayed as ads on the internet when relevant search phrases are used. In addition to being a powerful sales tool, e-commerce platforms also act as a strong marketing tools, as customers can leave their reviews and commentaries about the Company’s products. This will ensure that ${name}’s customers can make informed decisions when opting for its products, while also acting as a strong word-of-mouth marketing tool. <${WORD_HIGHLIGHT_TAG}>Lastly, the Company will also establish a user-friendly website to act as its proprietary e-commerce platform</${WORD_HIGHLIGHT_TAG}>.`,
        footNotes: [],
      };

const blogText = (name: string, status: OtherMarketingStrategiesPayload['status']) =>
  status === 'Has'
    ? {
        paragraphText: `${name} maintains a blog dedicated to informing industry trends and its unique services and methodologies. Blogs are one of the most powerful tools individuals and businesses have at their disposal to engage with clients. Blogs allow for comments from readers, which can help build relationships with them. <${WORD_FOOTNOTE_TAG}>Additionally, blogs provide a platform for fresh content on a regular basis.</${WORD_FOOTNOTE_TAG}> This marketing strategy enhances the Company’s visibility, create new leads, promote valuable engagement, and, ultimately, help ${name} understand its clients’ needs.`,
        footNotes: ['Source: Blog vs Newsletter: Which Is Best for Empowering Creators? — Marvelous - Create and sell the most beautiful courses and memberships on the Internet'],
      }
    : {
        paragraphText: `${name} will maintain a blog dedicated to informing industry trends and its unique services and methodologies. Blogs are one of the most powerful tools individuals and businesses have at their disposal to engage with clients. Blogs allow for comments from readers, which can help build relationships with them. <${WORD_FOOTNOTE_TAG}>Additionally, blogs provide a platform for fresh content on a regular basis.</${WORD_FOOTNOTE_TAG}> This marketing strategy will enhance the Company’s visibility, create new leads, promote valuable engagement, and, ultimately, help ${name} understand its clients’ needs.`,
        footNotes: ['Source: Blog vs Newsletter: Which Is Best for Empowering Creators? — Marvelous - Create and sell the most beautiful courses and memberships on the Internet'],
      };

const radioAdvertisingText = (name: string, status: OtherMarketingStrategiesPayload['status']) =>
  status === 'Has'
    ? {
        paragraphText: `${name} advertises its business across multiple radio stations. The Company advertises its location as well as the services it will provide. <${WORD_FOOTNOTE_TAG}>According to The Nielsen Company’s report,</${WORD_FOOTNOTE_TAG}> radio reaches 93% of all American adults every week and as many as 98% of Americans older than 50. Brands that advertise on radio stations enjoy higher levels of awareness and brand consideration. An integrated marketing approach is the most effective way to deliver a consistent message across multiple channels. Radio advertising pairs well with other marketing strategies and helps drive client engagement in a cost-effective way, which is why ${name} will capitalize on this versatile advertising platform.`,
        footNotes: ['Source: The Nielsen Company, Audio Today 2022 - How America Listens, June 2022'],
      }
    : {
        paragraphText: `${name} will advertise its business across multiple radio stations. The Company will advertise its location as well as the services it will provide. <${WORD_FOOTNOTE_TAG}>According to The Nielsen Company’s report,</${WORD_FOOTNOTE_TAG}> radio reaches 93% of all American adults every week and as many as 98% of Americans older than 50. Brands that advertise on radio stations enjoy higher levels of awareness and brand consideration. An integrated marketing approach is the most effective way to deliver a consistent message across multiple channels. Radio advertising pairs well with other marketing strategies and helps drive client engagement in a cost-effective way, which is why ${name} will capitalize on this versatile advertising platform.`,
        footNotes: ['Source: The Nielsen Company, Audio Today 2022 - How America Listens, June 2022'],
      };

const influencerMarketingText = (name: string, status: OtherMarketingStrategiesPayload['status']) =>
  status === 'Has'
    ? {
        paragraphText: `<${WORD_FOOTNOTE_TAG}>According to Grin,</${WORD_FOOTNOTE_TAG}>  influencers are characterized by a large number of loyal and dedicated social media followers. They are perceived as experts in their niches and their recommendations are often highly regarded by their followers. What distinguishes influencers from traditional celebrities is that the former shares a strong rapport with their fan communities. They harness the accessibility of social media to establish personal connections with and gain the trust of their followers. That is why, as a part of its online marketing strategy, ${name} targets and relies on the use of influencers to increase brand visibility to larger audiences, build strong brand awareness, and increase revenue, while also eliminating costs associated with other traditional marketing campaigns.`,
        footNotes: ['Source: Why Influencer Marketing Is Important | GRIN'],
      }
    : {
        paragraphText: `<${WORD_FOOTNOTE_TAG}>According to Grin,</${WORD_FOOTNOTE_TAG}>  influencers are characterized by a large number of loyal and dedicated social media followers. They are perceived as experts in their niches and their recommendations are often highly regarded by their followers. What distinguishes influencers from traditional celebrities is that the former shares a strong rapport with their fan communities. They harness the accessibility of social media to establish personal connections with and gain the trust of their followers. That is why, as a part of its online marketing strategy, ${name} will target and rely on the use of influencers to increase brand visibility to larger audiences, build strong brand awareness, and increase revenue, while also eliminating costs associated with other traditional marketing campaigns.`,
        footNotes: ['Source: Why Influencer Marketing Is Important | GRIN'],
      };

const discountsCouponsText = (name: string, status: OtherMarketingStrategiesPayload['status']) =>
  status === 'Has'
    ? {
        paragraphText: `${name} uses coupons and loyalty programs to ensure the return of its customers as well as gain new potential customers. The three-fold nature of the coupon – to attract new customers, to keep current customers coming back, and to re-activate former customers – takes business away from competitors. Depending on the distribution method, marketing areas can be expanded, and coupons improve the customer conversion rates of every channel through which they are distributed. Additionally, the unique and trackable codes that can be assigned to coupons make them easily measurable. Assessing the effectiveness of a campaign is as simple as determining how many coupons were redeemed by customers. Furthermore, by offering discounts that vary between products and limiting their availability, the Company is able to drive client engagement seemingly at will.`,
        footNotes: [],
      }
    : {
        paragraphText: `${name} will use coupons and loyalty programs to ensure the return of its customers as well as gain new potential customers. The three-fold nature of the coupon – to attract new customers, to keep current customers coming back, and to re-activate former customers – takes business away from competitors. Depending on the distribution method, marketing areas can be expanded, and coupons improve the customer conversion rates of every channel through which they are distributed. Additionally, the unique and trackable codes that can be assigned to coupons make them easily measurable. Assessing the effectiveness of a campaign is as simple as determining how many coupons were redeemed by customers. Furthermore, by offering discounts that vary between products and limiting their availability, the Company will be able to drive client engagement seemingly at will.`,
        footNotes: [],
      };

const bookingPlatformsText = (name: string, status: OtherMarketingStrategiesPayload['status']) =>
  status === 'Has'
    ? {
        paragraphText: `In addition to allowing clients to search for an adequate solution, booking platforms offer simplicity in terms of booking management, thereby enhancing performance of ${name}’s staff. Moreover, booking platforms offer in-depth analysis tools for estimating a business’ success rate, the demographic that requests its services the most, clients’ preferences, and more. The Company has established a presence on online booking platforms through which it will have an efficient way of booking appointments and increase ${name}’s visibility.`,
        footNotes: [],
      }
    : {
        paragraphText: `In addition to allowing clients to search for an adequate solution, booking platforms offer simplicity in terms of booking management, thereby enhancing performance of ${name}’s staff. Moreover, booking platforms offer in-depth analysis tools for estimating a business’ success rate, the demographic that requests its services the most, clients’ preferences, and more. The Company will establish a presence on online booking platforms through which it will have an efficient way of booking appointments and increase ${name}’s visibility.`,
        footNotes: [],
      };

const newsletterText = (name: string, status: OtherMarketingStrategiesPayload['status']) =>
  status === 'Has'
    ? {
        paragraphText: `${name} deploys a regular newsletter as a pivotal marketing tool, facilitating direct communication with its target audience and fostering ongoing engagement. Through the newsletter, subscribers will receive curated content, updates on relevant industry trends, and exclusive offers, establishing the Company as a trusted source of valuable information. The newsletter strengthens brand awareness and loyalty by providing personalized insights and fostering a sense of community among subscribers. Additionally, it serves as a platform for promoting events, services, and special initiatives, driving conversions, and nurturing long-term relationships with clients. Overall, the newsletter embodies ${name}’s dedication to proactive and client-oriented excellence in services, facilitating meaningful connections and driving business growth.`,
        footNotes: [],
      }
    : {
        paragraphText: `${name} will deploy a regular newsletter as a pivotal marketing tool, facilitating direct communication with its target audience and fostering ongoing engagement. Through the newsletter, subscribers will receive curated content, updates on relevant industry trends, and exclusive offers, establishing the Company as a trusted source of valuable information. The newsletter will strengthen brand awareness and loyalty by providing personalized insights and fostering a sense of community among subscribers. Additionally, it will serve as a platform for promoting events, services, and special initiatives, driving conversions, and nurturing long-term relationships with clients. Overall, the newsletter will embody ${name}’s dedication to proactive and client-oriented excellence in services, facilitating meaningful connections and driving business growth.`,
        footNotes: [],
      };

const generateOtherStrategiesText = (name: string, { status, type }: OtherMarketingStrategiesPayload) => {
  switch (type) {
    case OtherMarketingStrategy.EmailCampaigns:
      return emailCampaignsText(name, status);
    case OtherMarketingStrategy.WordMouthMarketing:
      return wordMouthMarketingText(name, status);
    case OtherMarketingStrategy.BrandedMaterial:
      return brandedMaterialText(name, status);
    case OtherMarketingStrategy.NewspapersMagazines:
      return newspapersMagazinesText(name, status);
    case OtherMarketingStrategy.ECommerceMarketing:
      return eCommerceMarketingText(name, status);
    case OtherMarketingStrategy.Blog:
      return blogText(name, status);
    case OtherMarketingStrategy.RadioAdvertising:
      return radioAdvertisingText(name, status);
    case OtherMarketingStrategy.InfluencerMarketing:
      return influencerMarketingText(name, status);
    case OtherMarketingStrategy.DiscountsCoupons:
      return discountsCouponsText(name, status);
    case OtherMarketingStrategy.BookingPlatforms:
      return bookingPlatformsText(name, status);
    case OtherMarketingStrategy.Newsletter:
      return newsletterText(name, status);
    default:
      return { paragraphText: '', footNotes: [] };
  }
};

export const generateOtherMarketingStrategiesText = (name: string, strategies: OtherMarketingStrategiesPayload[]) =>
  strategies
    .filter(({ status }) => status !== 'None')
    .flatMap((strategy) => [
      // paragraphs starting with bold tags must have spaces to avoid applying the bold style the whole paragraph
      { paragraphText: ` <${WORD_BOLD_TAG}>${OTHER_MARKETING_STRATEGY_ITEMS[strategy.type].name}</${WORD_BOLD_TAG}> `, footNotes: [] },
      generateOtherStrategiesText(name, strategy),
    ]);
//#endregion Other Marketing Strategies
