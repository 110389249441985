import { inject } from '@angular/core';
import { UsefulWebsitesElementDTO } from '@joorney/shell-shared-jwriter-core-api-data-access';
import { toastActions } from '@joorney/shell-shared-frontend-toast-store';
import { ConfirmationDialogService } from '@joorney/ui-shared-frontend-confirmation-dialog';
import { UsefulWebsitesFormFeatureService } from '@joorney/useful-websites-jwriter-financial-useful-websites-form-feature';
import { UsefulWebsitesCategoriesDataAccessService } from '@joorney/useful-websites-shared-frontend-useful-websites-categories-data-access';
import { UsefulWebsitesDataAccessService } from '@joorney/useful-websites-shared-frontend-useful-websites-data-access';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store, createSelector } from '@ngrx/store';
import * as _ from 'lodash';
import { catchError, concatMap, filter, map, of, switchMap, tap } from 'rxjs';
import { usefulWebsitesActions } from './useful-websites-store.actions';
import { $usefulWebsitesData, $usefulWebsitesIsMine, $usefulWebsitesSelectedRowId } from './useful-websites-store.selectors';

const $selectedRowData = createSelector($usefulWebsitesData, $usefulWebsitesSelectedRowId, (data, selectedRowId) => {
  if (selectedRowId === null) {
    return null;
  }
  return data.find(({ id }) => id === selectedRowId);
});


export const loadUsefulWebsitesData$ = createEffect(
  (action$ = inject(Actions), store = inject(Store), usefulWebsitesDataAccessService = inject(UsefulWebsitesDataAccessService)) => {
    return action$.pipe(
      ofType(usefulWebsitesActions.dataInitialization, usefulWebsitesActions.dataRefresh),
      concatLatestFrom(() => store.select($usefulWebsitesIsMine)),
      concatMap(([, isMine]) => (isMine ? usefulWebsitesDataAccessService.findMine() : usefulWebsitesDataAccessService.findAll())),
      map((data) => usefulWebsitesActions.dataLoaded({ data })),
    );
  },
  { functional: true },
);

export const loadUsefulWebsitesCategoriesData$ = createEffect(
  (action$ = inject(Actions), usefulWebsitesCategoriesDataAccessService = inject(UsefulWebsitesCategoriesDataAccessService)) => {
    return action$.pipe(
      ofType(usefulWebsitesActions.dataInitialization, usefulWebsitesActions.dataRefresh),
      concatMap(() => usefulWebsitesCategoriesDataAccessService.findAll()),
      map((data) => usefulWebsitesActions.categoriesLoaded({ data })),
    );
  },
  { functional: true },
);

export const editUsefulWebsites$ = createEffect(
  (action$ = inject(Actions), usefulWebsitesFormFeatureService = inject(UsefulWebsitesFormFeatureService)) => {
    return action$.pipe(
      ofType(usefulWebsitesActions.tableRowClicked),
      tap(({ data }) => usefulWebsitesFormFeatureService.open(data)),
    );
  },
  { functional: true, dispatch: false },
);

export const saveUsefulWebsites$ = createEffect(
  (action$ = inject(Actions), usefulWebsitesDataAccessService = inject(UsefulWebsitesDataAccessService)) => {
    return action$.pipe(
      ofType(usefulWebsitesActions.saveClicked),
      concatMap(({ data }) =>
        usefulWebsitesDataAccessService.update(data).pipe(
          map(() => usefulWebsitesActions.saveFinished()),
          catchError(() => of(usefulWebsitesActions.saveFailed())),
        ),
      ),
    );
  },
  { functional: true },
);

export const addNewUsefulWebsites$ = createEffect(
  (action$ = inject(Actions), usefulWebsitesFormFeatureService = inject(UsefulWebsitesFormFeatureService)) => {
    return action$.pipe(
      ofType(usefulWebsitesActions.addNewClicked),
      tap(() => usefulWebsitesFormFeatureService.open()),
    );
  },
  { functional: true, dispatch: false },
);

export const saveNewUsefulWebsites$ = createEffect(
  (action$ = inject(Actions), usefulWebsitesDataAccessService = inject(UsefulWebsitesDataAccessService)) => {
    return action$.pipe(
      ofType(usefulWebsitesActions.saveNewClicked),
      concatMap(({ data, reopen }) =>
        usefulWebsitesDataAccessService.create(data).pipe(
          map(({ id }) => usefulWebsitesActions.saveNewFinished({ rowId: reopen ? id : null })),
          catchError(() => of(usefulWebsitesActions.saveNewFailed())),
        ),
      ),
    );
  },
  { functional: true },
);

export const confirmUsefulWebsiteDeletion$ = createEffect(
  (action$ = inject(Actions), confirmationDialogService = inject(ConfirmationDialogService)) => {
    return action$.pipe(
      ofType(usefulWebsitesActions.deleteClicked),
      switchMap(({ id }) => confirmationDialogService.open$('Do you want to remove this funding?').pipe(map(({ action }) => ({ actionConfirmed: action, id })))),
      filter(({ actionConfirmed }) => actionConfirmed),
      map(({ id }) => usefulWebsitesActions.deleteConfirmed({ id })),
    );
  },
  { functional: true },
);

export const deleteUsefulWebsite$ = createEffect(
  (action$ = inject(Actions), usefulWebsitesDataAccessService = inject(UsefulWebsitesDataAccessService)) => {
    return action$.pipe(
      ofType(usefulWebsitesActions.deleteConfirmed),
      concatMap(({ id }) =>
        usefulWebsitesDataAccessService.delete(id).pipe(
          map(() => usefulWebsitesActions.deleteFinished()),
          catchError(() => of(usefulWebsitesActions.deleteFailed())),
        ),
      ),
    );
  },
  { functional: true },
);

export const refreshForm$ = createEffect(
  (action$ = inject(Actions), store = inject(Store)) => {
    return action$.pipe(
      ofType(usefulWebsitesActions.dataLoaded),
      concatLatestFrom(() => store.select($selectedRowData)),
      map(([, rowData]) => rowData),
      filter((rowData): rowData is UsefulWebsitesElementDTO => !_.isNil(rowData)),
      map((data) => usefulWebsitesActions.tableRowClicked({ data })),
    );
  },
  { functional: true },
);

export const displaySuccessMessage$ = createEffect(
  (action$ = inject(Actions)) => {
    return action$.pipe(
      ofType(usefulWebsitesActions.saveFinished, usefulWebsitesActions.saveNewFinished, usefulWebsitesActions.deleteFinished),
      map(() => toastActions.displaySuccessMessage({})),
    );
  },
  { functional: true },
);

export const displayErrorMessage$ = createEffect(
  (action$ = inject(Actions)) => {
    return action$.pipe(
      ofType(usefulWebsitesActions.saveFailed, usefulWebsitesActions.saveNewFailed, usefulWebsitesActions.deleteFailed),
      map(() => toastActions.displayErrorMessage({})),
    );
  },
  { functional: true },
);

export const closePanel$ = createEffect(
  (action$ = inject(Actions), usefulWebsitesFormFeatureService = inject(UsefulWebsitesFormFeatureService)) => {
    return action$.pipe(
      ofType(usefulWebsitesActions.dataFinalize, usefulWebsitesActions.deleteFinished),
      tap(() => usefulWebsitesFormFeatureService.close()),
    );
  },
  { functional: true, dispatch: false },
);

export const refreshUsefulWebsitesData$ = createEffect(
  (action$ = inject(Actions)) => {
    return action$.pipe(
      ofType(usefulWebsitesActions.saveFinished, usefulWebsitesActions.saveNewFinished, usefulWebsitesActions.deleteFinished),
      map(() => usefulWebsitesActions.dataRefresh()),
    );
  },
  { functional: true },
);

export const pinUsefulWebsite$ = createEffect(
  (action$ = inject(Actions), usefulWebsitesDataAccessService = inject(UsefulWebsitesDataAccessService)) => {
    return action$.pipe(
      ofType(usefulWebsitesActions.pinUsefulWebsiteClicked),
      concatMap(({ data }) => usefulWebsitesDataAccessService.createPin(data)),
      map(() => usefulWebsitesActions.dataRefresh()),
    );
  },
  { functional: true },
);

export const unpinUsefulWebsite$ = createEffect(
  (action$ = inject(Actions), usefulWebsitesDataAccessService = inject(UsefulWebsitesDataAccessService)) => {
    return action$.pipe(
      ofType(usefulWebsitesActions.unpinUsefulWebsiteClicked),
      concatMap(({ id }) => usefulWebsitesDataAccessService.deletePin(id)),
      map(() => usefulWebsitesActions.dataRefresh()),
    );
  },
  { functional: true },
);
