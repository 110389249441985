import { createFeature, createReducer, on } from '@ngrx/store';
import { produce } from 'immer';
import { usageTimeActions } from './usage-time.actions';
import { USAGE_TIME_ENTRY_POLLING_INTERVAL, USAGE_TIME_FEATURE_KEY } from './usage-time.constants';

export interface UsageTimesEntry {
  businessPlanId: number | null;
  page: string;
  time: number;
  url: string;
}

export interface UsageTimeState {
  entries: UsageTimesEntry[];
}

const initialState: UsageTimeState = {
  entries: [],
};

export const usageTimeFeature = createFeature({
  name: USAGE_TIME_FEATURE_KEY,
  reducer: createReducer(
    initialState,
    on(usageTimeActions.usageTimeEntryPolled, (state, { businessPlanId, page }) =>
      produce(state, (draft) => {
        const time = USAGE_TIME_ENTRY_POLLING_INTERVAL / 1000;
        const url = window.location.href;
        const existingEntry = draft.entries.find((e) => e.businessPlanId === businessPlanId && e.page === page && e.url === url);
        if (existingEntry === undefined) {
          draft.entries.push({ businessPlanId, page, time, url });
        } else {
          existingEntry.time += time;
        }
      }),
    ),
    on(usageTimeActions.usageTimeEntriesLogged, () => initialState),
  ),
});
