<!-- Dialog content -->
<div>
  <div>
    You are about to change visa type. By changing the visa type from
    {{ currentVisaType }} to {{ newVisaType }} you will create a new version of
    the project
    @if (showGainedFeatures || showLostFeatures) {
      and:
    }
  </div>
  <br />
  @if (showGainedFeatures) {
    <i>Gain the following functionalities</i>
    <ul>
      @for (feature of gainedFeatures; track feature) {
        <li>{{ feature }}</li>
      }
    </ul>
  }
  @if (showLostFeatures) {
    <i>Lose the following functionalities</i>
    <ul>
      @for (feature of lostFeatures; track feature) {
        <li>{{ feature }}</li>
      }
    </ul>
  }
</div>

<!-- Action buttons -->
<div class="mt-3 flex align-items-center justify-content-end">
  <!-- Cancel -->
  <button
    class="p-button-outlined p-button-rounded"
    (click)="cancelConfirmation()"
    pButton
    pRipple
    label="Cancel"
  ></button>
  <!-- Confirm -->
  <button
    class="p-button-rounded ml-2"
    (click)="confirmConfirmation()"
    pButton
    pRipple
    label="Confirm"
  ></button>
</div>
