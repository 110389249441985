import { MarketingSection, OtherMarketingStrategy, SocialMedia, WebsiteStatus } from './marketing-section-domain.enum';

export const MARKETING_SECTION_MENU_ITEMS = [
  { id: MarketingSection.socialMedia, name: 'Social Media' },
  { id: MarketingSection.website, name: 'Website' },
  { id: MarketingSection.eventsShows, name: 'Events/Shows' },
  { id: MarketingSection.reviewPlatforms, name: 'Review Platforms' },
  { id: MarketingSection.others, name: 'Others' },
];

export const WEBSITE_STATUS_WITH_URL = [WebsiteStatus.HasDomain, WebsiteStatus.HasWebsite];

export const SOCIAL_MEDIA_ITEMS: Record<SocialMedia, { type: SocialMedia; name: string; textName: string }> = {
  [SocialMedia.Facebook]: { type: SocialMedia.Facebook, name: 'Facebook', textName: 'Facebook' },
  [SocialMedia.Instagram]: { type: SocialMedia.Instagram, name: 'Instagram', textName: 'Instagram' },
  [SocialMedia.X]: { type: SocialMedia.X, name: 'X (Twitter)', textName: 'X (formerly known as Twitter)' },
  [SocialMedia.LinkedIn]: { type: SocialMedia.LinkedIn, name: 'LinkedIn', textName: 'LinkedIn' },
  [SocialMedia.TikTok]: { type: SocialMedia.TikTok, name: 'TikTok', textName: 'TikTok' },
  [SocialMedia.YouTube]: { type: SocialMedia.YouTube, name: 'YouTube', textName: 'YouTube' },
};

export const OTHER_MARKETING_STRATEGY_ITEMS: Record<OtherMarketingStrategy, { type: OtherMarketingStrategy; name: string }> = {
  [OtherMarketingStrategy.EmailCampaigns]: { type: OtherMarketingStrategy.EmailCampaigns, name: 'Email Campaigns' },
  [OtherMarketingStrategy.WordMouthMarketing]: { type: OtherMarketingStrategy.WordMouthMarketing, name: 'Word of Mouth Marketing' },
  [OtherMarketingStrategy.BrandedMaterial]: { type: OtherMarketingStrategy.BrandedMaterial, name: 'Branded Material' },
  [OtherMarketingStrategy.NewspapersMagazines]: { type: OtherMarketingStrategy.NewspapersMagazines, name: 'Newspapers and Magazines' },
  [OtherMarketingStrategy.ECommerceMarketing]: { type: OtherMarketingStrategy.ECommerceMarketing, name: 'E-commerce Marketing' },
  [OtherMarketingStrategy.Blog]: { type: OtherMarketingStrategy.Blog, name: 'Blog' },
  [OtherMarketingStrategy.RadioAdvertising]: { type: OtherMarketingStrategy.RadioAdvertising, name: 'Radio Advertising' },
  [OtherMarketingStrategy.InfluencerMarketing]: { type: OtherMarketingStrategy.InfluencerMarketing, name: 'Influencer Marketing' },
  [OtherMarketingStrategy.DiscountsCoupons]: { type: OtherMarketingStrategy.DiscountsCoupons, name: 'Discounts and Coupons' },
  [OtherMarketingStrategy.BookingPlatforms]: { type: OtherMarketingStrategy.BookingPlatforms, name: 'Booking Platforms' },
  [OtherMarketingStrategy.Newsletter]: { type: OtherMarketingStrategy.Newsletter, name: 'Newsletter' },
};
