import { Injectable } from '@angular/core';
import {
  GroupCreateDTO,
  GroupUpdateDTO,
  groupFeatureControllerCreateGroup,
  groupFeatureControllerDeleteGroup,
  groupFeatureControllerGetGroupList,
  groupFeatureControllerGetUserGroupList,
  groupFeatureControllerUpdateGroup,
  permissionFeatureControllerGetPermissionList,
} from '@joorney/shell-shared-jwriter-core-api-data-access';
import { throwErrorOnInvalidValue } from '@joorney/utils-shared-rxjs-utils';
import { from, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class GroupDataAccessService {
  create(groupData: GroupCreateDTO) {
    return from(groupFeatureControllerCreateGroup({ requestBody: groupData })).pipe(throwErrorOnInvalidValue());
  }

  findMine() {
    return from(groupFeatureControllerGetUserGroupList()).pipe(throwErrorOnInvalidValue());
  }

  findAll() {
    return from(groupFeatureControllerGetGroupList()).pipe(throwErrorOnInvalidValue());
  }

  findAllAvailablePermissions() {
    return from(permissionFeatureControllerGetPermissionList()).pipe(throwErrorOnInvalidValue());
  }

  update(groupData: GroupUpdateDTO) {
    return from(
      groupFeatureControllerUpdateGroup({
        id: groupData.id,
        requestBody: groupData,
      }),
    ).pipe(throwErrorOnInvalidValue());
  }

  delete(id: number) {
    return from(groupFeatureControllerDeleteGroup({ id })).pipe(map(() => void 0));
  }
}
