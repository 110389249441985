import { GeneratedContentData, GeneratedContentFundingsAndInvestments, JwTableRowClass } from '@joorney/computation-shared-frontend-sections-domain';
import { getFundingOriginLabel } from '@joorney/funding-shared-frontend-funding-utils';
import * as _ from 'lodash';

export const transformSourceOfFundsData = (data: GeneratedContentFundingsAndInvestments): GeneratedContentData[] => {
  const values = data.year[0].fundings.funding.map((fund) => fund.amount);
  const totalValues = data.year[0].fundings.totalFundingsAmount;
  const includeDecimals = values.some((v) => !_.isInteger(v)) || !_.isInteger(totalValues);
  const datasource: GeneratedContentData[] = [
    ...data.year[0].fundings.funding.map((fund) => ({
      label: fund.source ? `From ${fund.source}` : getFundingOriginLabel(fund),
      values: [fund.amount],
      isCurrency: true,
      withDigits: includeDecimals,
      noDigits: !includeDecimals,
    })),
    {
      label: 'Total Investment',
      values: [data.year[0].fundings.totalFundingsAmount],
      isCurrency: true,
      withDigits: includeDecimals,
      noDigits: !includeDecimals,
      styleClass: [JwTableRowClass.TableHeading, JwTableRowClass.TableFooter],
    },
  ];
  return datasource;
};

export const transformUseOfProceedsData = (data: GeneratedContentFundingsAndInvestments): GeneratedContentData[] => {
  const values = data.year[0].investments.investment.map((invest) => invest.amount);
  const totalValues = data.year[0].investments.totalInvestedAmount;
  const includeDecimals = values.some((v) => !_.isInteger(v)) || !_.isInteger(totalValues);
  const datasource: GeneratedContentData[] = [
    ...data.year[0].investments.investment.map((invest) => ({
      label: invest.description,
      values: [invest.amount],
      isCurrency: true,
      withDigits: includeDecimals,
      noDigits: !includeDecimals,
    })),
    {
      label: 'Total Spent from the Invested Funds:',
      values: [data.year[0].investments.totalInvestedAmount],
      isCurrency: true,
      withDigits: includeDecimals,
      noDigits: !includeDecimals,
      styleClass: [JwTableRowClass.TableHeading, JwTableRowClass.TableFooter],
    },
    {
      label: '',
      values: [null],
    },
    {
      label: 'Total of Funds Irrevocably Committed',
      values: [data.year[0].investments.totalInvestedAmount],
      isCurrency: true,
      withDigits: includeDecimals,
      noDigits: !includeDecimals,
      styleClass: [JwTableRowClass.TableHeading, JwTableRowClass.TableFooter],
    },
  ];
  return datasource;
};
