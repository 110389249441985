import { createSelector } from '@ngrx/store';
import { userFeature } from './user.reducer';

export const {
  selectGroups: $userGroups,
  selectName: $userName,
  selectUserState: $userState,
} = userFeature;

export const $userGetNameInitial = createSelector($userName, (name) => name?.charAt(0));
