import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';

interface JwWordWindow extends Window {
  replaceStateBackUp: Window['history']['replaceState'];
  pushStateBackUp: Window['history']['pushState'];
}

// Reference: https://www.npmjs.com/package/@microsoft/office-js
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
if (Office !== undefined) {
  Office.initialize = () => {
    Office.onReady()
      .then((_info: { host: Office.HostType; platform: Office.PlatformType }) => {
        // https://github.com/OfficeDev/office-js/issues/3147
        window.history.replaceState = (window as unknown as JwWordWindow).replaceStateBackUp;
        window.history.pushState = (window as unknown as JwWordWindow).pushStateBackUp;
        console.log('Office is ready');
        return bootStrapAngular();
      })
      .catch((err) => {
        console.warn('Angular not bootstrapped. Error: \n');
        console.log(err);
      });
  };
} else {
  console.log('Bootstrapping Angular, without Office JS');
  bootStrapAngular();
}

function bootStrapAngular() {
  bootstrapApplication(AppComponent, appConfig).catch((err) => console.error(err));
}
