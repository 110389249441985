import { Injectable } from '@angular/core';
import {
  UsefulWebsitesCreateDTO,
  UsefulWebsitesCreatePinDTO,
  UsefulWebsitesUpdateDTO,
  usefulWebsitesFeatureControllerCreatePinnedUsefulWebsite,
  usefulWebsitesFeatureControllerCreateUsefulWebsites,
  usefulWebsitesFeatureControllerDeletePinnedUsefulWebsite,
  usefulWebsitesFeatureControllerDeleteUsefulWebsites,
  usefulWebsitesFeatureControllerGetMyUsefulWebsitesList,
  usefulWebsitesFeatureControllerGetUsefulWebsitesList,
  usefulWebsitesFeatureControllerUpdateUsefulWebsites,
} from '@joorney/shell-shared-jwriter-core-api-data-access';
import { throwErrorOnInvalidValue } from '@joorney/utils-shared-rxjs-utils';
import { from } from 'rxjs';

export interface UsefulWebsitesUpdateData extends UsefulWebsitesUpdateDTO {
  id: number;
}

@Injectable({ providedIn: 'root' })
export class UsefulWebsitesDataAccessService {
  create(usefulWebsitesCreateDTO: UsefulWebsitesCreateDTO) {
    return from(usefulWebsitesFeatureControllerCreateUsefulWebsites({ requestBody: usefulWebsitesCreateDTO })).pipe(throwErrorOnInvalidValue());
  }

  findAll() {
    return from(usefulWebsitesFeatureControllerGetUsefulWebsitesList()).pipe(throwErrorOnInvalidValue());
  }

  findMine() {
    return from(usefulWebsitesFeatureControllerGetMyUsefulWebsitesList()).pipe(throwErrorOnInvalidValue());
  }

  update(usefulWebsitesUpdateData: UsefulWebsitesUpdateData) {
    const { id, ...data } = usefulWebsitesUpdateData;
    return from(usefulWebsitesFeatureControllerUpdateUsefulWebsites({ id, requestBody: data })).pipe(throwErrorOnInvalidValue());
  }

  delete(id: number) {
    return from(usefulWebsitesFeatureControllerDeleteUsefulWebsites({ id })).pipe(throwErrorOnInvalidValue());
  }

  createPin(usefulWebsitesCreatePinDTO: UsefulWebsitesCreatePinDTO) {
    return from(usefulWebsitesFeatureControllerCreatePinnedUsefulWebsite({ requestBody: usefulWebsitesCreatePinDTO })).pipe(throwErrorOnInvalidValue());
  }

  deletePin(id: number) {
    return from(usefulWebsitesFeatureControllerDeletePinnedUsefulWebsite({ id })).pipe(throwErrorOnInvalidValue());
  }
}
