import { MarketingStatus, OtherMarketingStrategy, SocialMedia } from './marketing-section-domain.enum';

export interface SocialMediaPayload {
  type: SocialMedia;
  status: MarketingStatus;
  url: string;
}

export interface OtherMarketingStrategiesPayload {
  type: OtherMarketingStrategy;
  status: MarketingStatus;
}
