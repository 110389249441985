import { inject, Injectable } from '@angular/core';
import { UpdateUsageTimesDTO, UpdateUsageTimesEntryDTO, usageTimesFeatureControllerSetUsageTimes } from '@joorney/shell-shared-jwriter-core-api-data-access';
import { USAGE_TIME_APP_PAGES_DATA, UsageTimesEntry } from '@joorney/usage-times-shared-frontend-usage-time-store';
import { from } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UsageTimesService {
  usageTimeAppPagesData = inject(USAGE_TIME_APP_PAGES_DATA);

  addEntries$(entries: UsageTimesEntry[]) {
    const payload: UpdateUsageTimesDTO = {
      appTag: this.usageTimeAppPagesData.appTag,
      entries: entries.map(({ page, ...e }) => ({ ...e, page: this.usageTimeAppPagesData.usageTimesPage[page] })) as unknown as UpdateUsageTimesEntryDTO[],
    };
    return from(usageTimesFeatureControllerSetUsageTimes({ requestBody: payload }));
  }
}
