export interface GeneratedContentExpensesDetail {
  expenses?: GeneratedContentExpensesDetailExpenses;
  total?: GeneratedContentExpensesDetailTotal;
}

export interface GeneratedContentExpensesDetailExpenses {
  expense?: GeneratedContentExpensesDetailExpense[];
}

export interface GeneratedContentExpensesDetailExpense {
  '@name': string;
  isOutsourcingExpense: boolean;
  year?: GeneratedContentExpensesDetailExpenseYear[];
}

export interface GeneratedContentExpensesDetailExpenseYear {
  '@n'?: number;
  amount?: number;
}

export interface GeneratedContentExpensesDetailTotal {
  year?: GeneratedContentExpensesDetailTotalYear[];
}

export interface GeneratedContentExpensesDetailTotalYear {
  '@n'?: number;
  totalAmount?: number;
}
