import { UserGroup } from '@joorney/users-shared-user-group-domain';
import { createFeature, createReducer, on } from '@ngrx/store';
import { produce } from 'immer';
import { USER_FEATURE_KEY, userActions } from './user.actions';

export interface UserState {
  name: string | null;
  groups: UserGroup[];
}

const initialState: UserState = {
  name: null,
  groups: [],
};

export const userFeature = createFeature({
  name: USER_FEATURE_KEY,
  reducer: createReducer(
    initialState,

    on(userActions.setCurrentUser, (state, { name }) =>
      produce(state, (draft) => {
        draft.name = name;
      }),
    ),

    on(userActions.setCurrentGroups, (state, { groups }) =>
      produce(state, (draft) => {
        draft.groups = groups;
      }),
    ),
  ),
});
