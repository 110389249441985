export interface BaseSalaryData {
  y?: BaseSalaryYear;
  h?: BaseSalaryHour;
  c?: BaseSalaryCustom;
}

interface BaseSalaryYear {
  baseSalary?: number;
  increaseRate?: number;
  yearlySalary?: number;
  yearlySalaryFormula?: string;
}

interface BaseSalaryHour {
  hoursInAWeek?: number;
  hourlyRate?: number;
  hourlyRateFormula?: string;
  increaseRate?: number;
  weeklyHours?: number;
}

interface BaseSalaryCustom {
  salaryEvolution?: BaseSalaryCustomEvolution;
  salaryEvolutionFormula?: BaseSalaryCustomEvolutionFormula;
}

export interface BaseSalaryCustomEvolution {
  '1'?: number;
  '2'?: number;
  '3'?: number;
  '4'?: number;
  '5'?: number;
}

interface BaseSalaryCustomEvolutionFormula {
  '1'?: string;
  '2'?: string;
  '3'?: string;
  '4'?: string;
  '5'?: string;
}
