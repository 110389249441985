import { GeneratedContentCashFlow, GeneratedContentData, JwTableRowClass } from '@joorney/computation-shared-frontend-sections-domain';

export const transformCashFlowData = (cashFlowData: GeneratedContentCashFlow, yearLabels: string[], filterEmpty = true): GeneratedContentData[] => {
  let datasource: GeneratedContentData[] = [
    {
      label: 'Beginning Cash Balance',
      values: cashFlowData.data.beginningCashBalance,
      styleClass: [JwTableRowClass.TableHeading],
      isHeading: true,
    },
    {
      label: 'Net Income',
      values: cashFlowData.data.netIncome,
    },
    {
      label: 'Non-Cash Adjustments',
      values: cashFlowData.data.depreciation,
    },
    {
      label: 'Changes in AR',
      values: cashFlowData.data.accountReceivable,
    },
    {
      label: 'Changes in AP',
      values: cashFlowData.data.accountPayable,
    },
    {
      label: 'Other Current Assets',
      values: cashFlowData.data.otherCurrentAssets ?? Array<number>(yearLabels.length).fill(0),
    },
    {
      label: 'Other Current Liabilities',
      values: cashFlowData.data.otherCurrentLiabilities,
    },
    {
      label: 'Cash From Operations',
      values: cashFlowData.data.cashFromOperations,
      styleClass: [JwTableRowClass.TableHeading],
      isHeading: true,
    },
    {
      label: 'Purchases of Long Term Assets',
      values: cashFlowData.data.longTermAssets,
    },
    {
      label: 'Cash From Investing',
      values: cashFlowData.data.cashFromInvesting,
      styleClass: [JwTableRowClass.TableHeading],
      isHeading: true,
    },
    {
      label: 'Paid-In Capital',
      values: cashFlowData.data.paidInCapital,
    },
    {
      label: 'Long Term Liabilities / Long Term Debt',
      values: cashFlowData.data.longTermLiabilities,
    },
    {
      label: 'Principal Repayment',
      values: cashFlowData.data.principalRepayment ?? Array<number>(yearLabels.length).fill(0),
    },
    {
      label: 'Dividends',
      values: cashFlowData.data.dividends,
    },
    {
      label: 'Cash From Financing',
      values: cashFlowData.data.cashFromFinancing,
      styleClass: [JwTableRowClass.TableHeading],
      isHeading: true,
    },
    {
      label: 'Ending Cash Balance',
      values: cashFlowData.data.endingCashBalance,
      styleClass: [JwTableRowClass.TableHeading],
      isHeading: true,
    },
  ];

  if (filterEmpty) {
    datasource = datasource.filter((el) => el.values?.some((v) => v !== 0));
  }

  return datasource.map((data) => ({
    ...data,
    isCurrency: true,
    noDigits: true,
  }));
};
