import { contentControlLocation } from './msoffice-content-control.utils';

export interface JwWordBreakLineInsertData {
  type: 'break-line';
}
export const jwWordMsOfficeBreakLineInsertContentControl = async (context: Word.RequestContext, contentControl: Word.ContentControl): Promise<void> => {
  return new Promise((resolve) => {
    contentControl.insertParagraph('', contentControlLocation.After);
    resolve();
  });
};
