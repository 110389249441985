import { Location } from '@angular/common';
import { HttpHeaderResponse, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalInterceptor, MsalInterceptorConfiguration, MsalService } from '@azure/msal-angular';
import { OpenAPI as wrikeApi } from '@joorney/shell-shared-jdata-wrike-api-data-access';
import { OpenAPI as coreApi } from '@joorney/shell-shared-jwriter-core-api-data-access';
import { OpenAPI as orchestratorApi } from '@joorney/shell-shared-jwriter-orchestrator-api-data-access';
import { firstValueFrom, of } from 'rxjs';

@Injectable()
export class AuthenticationInterceptorFeatureService {
  private readonly msalInterceptor: MsalInterceptor;

  constructor(
    @Inject(MSAL_INTERCEPTOR_CONFIG) private readonly msalInterceptorConfig: MsalInterceptorConfiguration,
    private readonly location: Location,
    private readonly msalService: MsalService,
    private readonly msalBroadcastService: MsalBroadcastService,
  ) {
    this.msalInterceptor = new MsalInterceptor(
      this.msalInterceptorConfig as unknown as MsalInterceptorConfiguration,
      this.msalService,
      this.location,
      this.msalBroadcastService,
      document,
    );
  }

  private fakeRequest(url: string) {
    return new HttpRequest('GET', url);
  }

  private async getToken(url: string) {
    let token = null;

    if (url !== undefined) {
      await firstValueFrom(
        this.msalInterceptor.intercept(this.fakeRequest(url), {
          handle: (req) => {
            token = req.headers.get('authorization') ?? '';
            return of(new HttpHeaderResponse());
          },
        }),
      );
    }
    return token;
  }

  addTokenToRequest() {
    [coreApi, wrikeApi, orchestratorApi].forEach((openApi) => {
      openApi.interceptors.request.use(async (request) => {
        if (request.headers !== undefined && request.url !== undefined) {
          const token = await this.getToken(request.url);
          request.headers.Authorization = token;
        }
        return request;
      });
    });
  }
}
