import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Message, MessageService } from 'primeng/api';
import { map, tap } from 'rxjs';
import { MessageOptions, toastActions } from './toast.actions';

const getDefaultSuccessMessage = (messageText?: string, messageOptions?: MessageOptions): Message => ({
  severity: 'success',
  summary: 'Success',
  detail: messageText ?? 'Operation done successfully',
  sticky: messageOptions?.manualDismiss,
});

const getDefaultErrorMessage = (messageText?: string, messageOptions?: MessageOptions): Message => ({
  severity: 'error',
  summary: 'Error',
  detail: messageText ?? 'An error occurred',
  sticky: messageOptions?.manualDismiss,
});

const getDefaultWarningMessage = (messageText?: string, messageOptions?: MessageOptions): Message => ({
  severity: 'warn',
  summary: 'Warning',
  detail: messageText ?? 'An error occurred',
  sticky: messageOptions?.manualDismiss,
});

const getDefaultInfoMessage = (messageText: string, messageOptions?: MessageOptions): Message => ({
  severity: 'info',
  summary: 'Info',
  detail: messageText,
  sticky: messageOptions?.manualDismiss,
});

export const displayDefaultSuccessMessage$ = createEffect(
  (action$ = inject(Actions), messageService = inject(MessageService)) => {
    return action$.pipe(
      ofType(toastActions.displaySuccessMessage),
      map(({ messageText, options }) => getDefaultSuccessMessage(messageText, options)),
      tap((message) => messageService.add(message)),
    );
  },
  { functional: true, dispatch: false },
);

export const displayErrorMessage$ = createEffect(
  (action$ = inject(Actions), messageService = inject(MessageService)) => {
    return action$.pipe(
      ofType(toastActions.displayErrorMessage),
      map(({ messageText, options }) => getDefaultErrorMessage(messageText, options)),
      tap((message) => messageService.add(message)),
    );
  },
  { functional: true, dispatch: false },
);

export const displayWarningMessage$ = createEffect(
  (action$ = inject(Actions), messageService = inject(MessageService)) => {
    return action$.pipe(
      ofType(toastActions.displayWarningMessage),
      map(({ messageText, options }) => getDefaultWarningMessage(messageText, options)),
      tap((message) => messageService.add(message)),
    );
  },
  { functional: true, dispatch: false },
);

export const displayInfoMessage$ = createEffect(
  (action$ = inject(Actions), messageService = inject(MessageService)) => {
    return action$.pipe(
      ofType(toastActions.displayInfoMessage),
      map(({ messageText, options }) => getDefaultInfoMessage(messageText, options)),
      tap((message) => messageService.add(message)),
    );
  },
  { functional: true, dispatch: false },
);
