import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const TAB_NAV_FEATURE_KEY = 'tab-nav';

export const tabNavActions = createActionGroup({
  source: TAB_NAV_FEATURE_KEY,
  events: {
    tabNavSectionClicked: props<{ sectionId: string | undefined }>(),
    tabNaBetaModeClicked: emptyProps(),
    goToDebugPageClicked: emptyProps(),
    goToPocPageClicked: emptyProps(),
    signOutClicked: emptyProps(),
    tabNavFinalize: emptyProps(),
  },
});
