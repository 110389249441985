import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { TokenInfo } from './token-info.interface';

const SESSIONS_TOKEN_KEY = 'sessionToken';
@Injectable({
  providedIn: 'root',
})
export class TokenService {
  setToken(token: string): void {
    localStorage.setItem(SESSIONS_TOKEN_KEY, token);
  }

  private getToken(): string | null {
    return localStorage.getItem(SESSIONS_TOKEN_KEY);
  }

  getValidToken(): string | null {
    const token = this.getToken();

    // If no Token, consider it a expired
    if (!token) {
      return null;
    }

    const decodedToken: TokenInfo = jwtDecode(token);
    const now = Date.now();
    const tokenExpirationDate = decodedToken.exp * 1000;
    const isTokenExpired = tokenExpirationDate < now;

    return !isTokenExpired ? token : null;
  }
}
