<jw-stack-layout-ui>
  <ng-container title>Payroll Taxes</ng-container>
  <form [formGroup]="form" class="flex flex-column gap-3">
    <div class="flex flex-column gap-3">
      <label>Payroll Tax Rate</label>
      <jw-percentage-input-ui
        formControlName="payrollTaxRate"
        [maxFractionDigits]="2"
      />
    </div>
  </form>
  <ng-container actions>
    <button
      class="p-button-rounded"
      [disabled]="!form.valid"
      (click)="save()"
      pButton
      pRipple
      label="Save"
      icon="pi pi-save"
    ></button>
    <button
      class="p-button-outlined p-button-rounded"
      (click)="cancel()"
      pButton
      pRipple
      label="Cancel"
    ></button>
  </ng-container>
</jw-stack-layout-ui>
