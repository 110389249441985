import { InjectionToken } from '@angular/core';
import { UsageTimesAppTag } from '@joorney/shell-shared-jwriter-core-api-data-access';
import { MemoizedSelector } from '@ngrx/store';

interface UsageTimeEntryData {
  businessPlanId: number | undefined;
  page: string;
}

interface UsageTimeAppPages {
  appTag: UsageTimesAppTag;
  usageTimesPage: Record<string, string>;
}

export const USAGE_TIME_FEATURE_KEY = 'usage-time';
export const USAGE_TIME_ENTRY_DATA = new InjectionToken<MemoizedSelector<object, UsageTimeEntryData>>('USAGE_TIME_ENTRY_DATA');
export const USAGE_TIME_APP_PAGES_DATA = new InjectionToken<UsageTimeAppPages>('USAGE_TIME_APP_PAGES_DATA');
export const USAGE_TIME_ENTRY_POLLING_INTERVAL = 10000; // 10 seconds
export const LOG_POLLING_INTERVAL = 300000; // 5 minutes
