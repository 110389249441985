import { Injectable } from '@angular/core';
import { UsefulWebsitesFormFeatureComponent } from './useful-websites-form-feature.component';
import { StackService } from '@joorney/shell-shared-frontend-feature-stack';
import { UsefulWebsitesElementDTO } from '@joorney/shell-shared-jwriter-core-api-data-access';

@Injectable()
export class UsefulWebsitesFormFeatureService {
  constructor(private stackService: StackService) {}

  open(data?: UsefulWebsitesElementDTO) {
    this.stackService.open(UsefulWebsitesFormFeatureComponent, data);
  }

  close() {
    this.stackService.close();
  }
}
