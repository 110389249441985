<span class="p-input-icon-right w-full">
  <i class="pi pi-percentage text-xs"></i>
  <p-inputNumber
    inputStyleClass="pr-4"
    #percentage
    [(ngModel)]="value"
    [disabled]="disabled"
    [placeholder]="placeholder"
    [minFractionDigits]="minFractionDigits"
    [maxFractionDigits]="maxFractionDigits"
    (onBlur)="onBlurEvent()"
    (onFocus)="onInputFocus()"
  />
</span>
