import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventType, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { userActions } from '@joorney/users-shared-frontend-user-store';
import { Store } from '@ngrx/store';
import { combineLatest, filter, map, of, tap } from 'rxjs';
import { LOGIN_ROUTE } from '../routing/authentication-routing.constant';
import { AuthenticationInterceptorFeatureService } from '@joorney/users-shared-frontend-authentication-interceptor-feature';

const REDIRECT_ON_EVENT: Record<string, string> = {
  [EventType.LOGIN_SUCCESS]: '/',
  [EventType.HANDLE_REDIRECT_END]: '/',
  [EventType.LOGOUT_END]: LOGIN_ROUTE,
  [EventType.LOGIN_FAILURE]: LOGIN_ROUTE,
};

@Injectable()
export class AuthService {
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private readonly msalGuardConfig: MsalGuardConfiguration,
    private readonly store: Store,
    private readonly router: Router,
    private readonly msalService: MsalService,
    private readonly msalBroadcastService: MsalBroadcastService,
    private readonly authenticationInterceptorFeatureService: AuthenticationInterceptorFeatureService,
  ) {
    this.authenticationInterceptorFeatureService.addTokenToRequest();
  }

  startUp() {
    return combineLatest({
      handleRedirect: this.msalService.handleRedirectObservable(),
      loginSucess: this.onLoginSuccess(),
      startupSucess: this.onStartupSuccess(),
      redirect: this.redirectOnEvent(),
    });
  }

  login() {
    const param = this.msalGuardConfig.authRequest ? { ...this.msalGuardConfig.authRequest } : undefined;
    if (this.msalGuardConfig.interactionType === InteractionType.Redirect) {
      this.msalService.loginRedirect(param as RedirectRequest);
    } else if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.msalService.loginPopup(param as PopupRequest);
    }
  }

  logout() {
    if (this.msalGuardConfig.interactionType === InteractionType.Redirect) {
      this.msalService.logoutRedirect();
    } else if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.msalService.logoutPopup();
    }
  }

  private onLoginSuccess() {
    return this.msalBroadcastService.msalSubject$.pipe(
      filter((msg) => EventType.LOGIN_SUCCESS === msg.eventType),
      tap((result) => this.msalService.instance.setActiveAccount((result.payload as AuthenticationResult).account)),
    );
  }

  private onStartupSuccess() {
    return this.msalBroadcastService.msalSubject$.pipe(
      filter((msg) => EventType.HANDLE_REDIRECT_END === msg.eventType),
      tap(() => this.store.dispatch(userActions.setCurrentUser({ name: this.msalService.instance.getActiveAccount()?.name ?? '' }))),
    );
  }

  private redirectOnEvent() {
    if (this.msalGuardConfig.interactionType === InteractionType.Redirect) {
      return of(null);
    }

    return this.msalBroadcastService.msalSubject$.pipe(
      map((msg) => REDIRECT_ON_EVENT[msg.eventType]),
      filter((url) => url !== undefined),
      tap((url) => void this.router.navigateByUrl(url)),
    );
  }
}
