import { Injectable } from '@angular/core';
import { StackService } from '@joorney/shell-shared-frontend-feature-stack';
import { PayrollTaxesFormFeatureComponent } from './payroll-taxes-form-feature.component';

@Injectable()
export class PayrollTaxesFormFeatureService {
  constructor(private stackService: StackService) {}

  open(data: number) {
    this.stackService.open(PayrollTaxesFormFeatureComponent, data);
  }

  close() {
    this.stackService.close();
  }
}
