import { DEFAULT_CONTENT_CONTROL_STYLE } from '@joorney/ms-office-jwriter-word-office-api-data-access';
import { createFeature, createReducer, on } from '@ngrx/store';
import { produce } from 'immer';
import { DATA_SYNC_FEATURE_KEY, dataSyncActions } from './data-sync.actions';

export interface ContentControl {
  id: number;
  tag: string;
  cannotEdit: boolean;
}

type ContentControls = Record<number, ContentControl>;

export interface DataSyncState {
  selectedContentControlId: number | null;
  contentControls: ContentControls;
  styleNumber: number;
}

const initialState: DataSyncState = {
  selectedContentControlId: null,
  contentControls: {},
  styleNumber: DEFAULT_CONTENT_CONTROL_STYLE,
};

export const dataSyncFeature = createFeature({
  name: DATA_SYNC_FEATURE_KEY,
  reducer: createReducer(
    initialState,
    on(dataSyncActions.dataSyncInitialization, (state) => state),
    on(dataSyncActions.globalStyleInitialization, (state, { styleNumber }) =>
      produce(state, (draft) => {
        draft.styleNumber = styleNumber;
      }),
    ),
    on(dataSyncActions.changeGlobalStyleClicked, (state, { styleNumber }) =>
      produce(state, (draft) => {
        draft.styleNumber = styleNumber;
      }),
    ),
    on(dataSyncActions.dataSyncLoaded, (state, { contentControls }) =>
      produce(state, (draft) => {
        draft.contentControls = contentControls.reduce<ContentControls>((ccs, cc) => {
          ccs[cc.id] = cc;
          return ccs;
        }, {});
      }),
    ),
    on(dataSyncActions.contentControlLoaded, (state, { contentControl }) =>
      produce(state, (draft) => {
        draft.contentControls[contentControl.id] = contentControl;
      }),
    ),
    on(dataSyncActions.contentControlDeleted, (state, { contentControlId }) =>
      produce(state, (draft) => {
        if (state.selectedContentControlId === contentControlId) {
          draft.selectedContentControlId = initialState.selectedContentControlId;
        }
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete draft.contentControls[contentControlId];
      }),
    ),
    on(dataSyncActions.contentControlEntered, (state, { contentControlId }) =>
      produce(state, (draft) => {
        draft.selectedContentControlId = contentControlId;
      }),
    ),
    on(dataSyncActions.contentControlExited, (state, { contentControlId }) => {
      // when switching to another content control, the contentControlEntered is dispatched before contentControlExited
      // we need to keep existing selectedContentControlId if it is not the same as the one we are trying to exit
      if (state.selectedContentControlId !== contentControlId) {
        return state;
      }
      return produce(state, (draft) => {
        draft.selectedContentControlId = initialState.selectedContentControlId;
      });
    }),
  ),
});
