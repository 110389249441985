import { UserGroup } from '@joorney/users-shared-user-group-domain';
import { createActionGroup, props } from '@ngrx/store';

export const USER_FEATURE_KEY = 'user';

export const userActions = createActionGroup({
  source: USER_FEATURE_KEY,
  events: {
    setCurrentUser: props<{ name: string }>(),
    setCurrentGroups: props<{ groups: UserGroup[] }>(),
  },
});
