export interface GeneratedContentCostAndBenefitAnalysis {
  directCost: number[];
  directCost_totalCost: number[];
  indirectCost_totalCost: number[];
  indirectCosts: GeneratedContentIndirectCost[];
  isIncreasing: boolean;
  netProfit: number[];
  netProfit_totalExpenses: number[];
  sales: number[];
  subtotalIndirectCost: number[];
  totalExpenses: number[];
}

interface GeneratedContentIndirectCost {
  data: GeneratedContentGlobalCostData[];
  linked: GeneratedContentLinked;
  name: string;
  rate: number | null;
  type: string;
}

interface GeneratedContentLinked {
  to: string | null;
}

export interface GeneratedContentGlobalCostData {
  formula: string;
  value: number;
}
