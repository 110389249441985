import {
  GeneratedContentBalanceSheet,
  GeneratedContentBreakEven,
  GeneratedContentCostAndBenefitAnalysis,
  GeneratedContentExpensesDetail,
  GeneratedContentFeasibilityAnalysis,
  GeneratedContentFundingsAndInvestments,
  GeneratedContentPersonnel,
  GeneratedContentProfitAndLoss,
  GeneratedContentSalesForecast,
} from '@joorney/computation-shared-frontend-sections-domain';

export interface GeneratedContent {
  salesForecast: GeneratedContentSalesForecast;
  balanceSheet: GeneratedContentBalanceSheet;
  breakEven: GeneratedContentBreakEven;
  personnel: GeneratedContentPersonnel;
  profitAndLoss: GeneratedContentProfitAndLoss;
  expensesDetail: GeneratedContentExpensesDetail;
  fundingsAndInvestments: GeneratedContentFundingsAndInvestments;
  costBenefit: GeneratedContentCostAndBenefitAnalysis;
  feasibility: GeneratedContentFeasibilityAnalysis;
}
