import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { USAGE_TIME_FEATURE_KEY } from './usage-time.constants';

export const usageTimeActions = createActionGroup({
  source: USAGE_TIME_FEATURE_KEY,
  events: {
    usageTimeEffectInitialized: emptyProps(),
    usageTimeEntryPolled: props<{
      businessPlanId: number | null;
      page: string;
    }>(),
    usageTimeEntriesLogged: emptyProps(),
  },
});
