@if (
  {
    businessPlanVersions: (businessPlanVersions$ | async) ?? [],
    businessPlanSections: (businessPlanSections$ | async) ?? [],
    businessPlanTables: (businessPlanTables$ | async) ?? [],
    displayYearInput: displayYearInput$ | async,
    yearLabelOptions: (yearLabelOptions$ | async) ?? [],
  };
  as vm
) {
  <form [formGroup]="formGroup">
    <div class="label">Project</div>
    <div>
      <jw-search-input-feature
        [formControl]="formGroup.controls.businessPlanProject"
      />
      @if (
        formGroup.get('businessPlanProject')?.invalid &&
        formGroup.get('businessPlanProject')?.touched
      ) {
        <div class="mt-3 text-red-500">
          {{ noProjectErrorMessage }}
        </div>
      }
    </div>
    <div class="label mt-3">Selection</div>
    <div class="flex flex-column row-gap-2">
      <p-dropdown
        [options]="vm.businessPlanVersions"
        placeholder="Project Version"
        styleClass="w-full"
        formControlName="businessPlanVersion"
        optionLabel="saveName"
      />
      <p-dropdown
        [options]="vm.businessPlanSections"
        placeholder="Section"
        styleClass="w-full"
        formControlName="businessPlanSection"
        optionLabel="name"
      />
      <p-dropdown
        [options]="vm.businessPlanTables"
        optionLabel="name"
        placeholder="Table"
        formControlName="businessPlanTable"
        styleClass="w-full"
      >
        <ng-template pTemplate="selectedItem" let-item>
          @if (item) {
            <ng-container
              *ngTemplateOutlet="tableSectionDropDownItem; context: { item }"
            />
          }
        </ng-template>
        <ng-template let-item pTemplate="item">
          <ng-container
            *ngTemplateOutlet="tableSectionDropDownItem; context: { item }"
          />
        </ng-template>
      </p-dropdown>
      @if (vm.displayYearInput) {
        <p-dropdown
          [options]="vm.yearLabelOptions"
          placeholder="Year"
          styleClass="w-full"
          formControlName="yearIndex"
          optionLabel="name"
          optionValue="value"
        />
      }
    </div>
    <div class="mt-3">
      <p-button
        [disabled]="loading || !formGroup.valid"
        class="py-2"
        (onClick)="insertTable()"
      >
        Insert
      </p-button>
    </div>

    @if (showErrorMessage) {
      <div class="mt-3 text-red-500">
        {{ noCursorErrorMessage }}
      </div>
    }
  </form>
}
@if (loading) {
  <p-progressSpinner />
}

<ng-template #tableSectionDropDownItem let-item="item">
  <div class="flex align-items-center gap-1">
    @for (url of item.imgUrls; track $index) {
      <img [src]="url" />
    }
    <span class="pl-1">{{ item.name }}</span>
  </div>
</ng-template>
