import { JwTableRowClass } from './table-section.enum';

export interface GeneratedContentData {
  label: string;
  values?: JwTableCellContent[];
  isHeading?: boolean;
  styleClass?: JwTableRowClass[];
  isCurrency?: boolean;
  noDigits?: boolean;
  noDigitsRounded?: boolean;
  withDigits?: boolean;
  hide?: boolean;
  isPercentage?: boolean;
}

export type JwTableCellContent = number | string | null | undefined;
