import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { STACK_CONFIG, StackConfig, StackContentComponent } from '@joorney/shell-shared-frontend-feature-stack';
import { UsefulWebsitesCreateDTO, UsefulWebsitesElementDTO } from '@joorney/shell-shared-jwriter-core-api-data-access';
import { StackLayoutUiComponent } from '@joorney/shell-shared-frontend-stack-layout-ui';
import { UsefulWebsitesUpdateData } from '@joorney/useful-websites-shared-frontend-useful-websites-data-access';
import { $usefulWebsitesCategoriesByCountryOptions, $usefulWebsitesCountryOptions, usefulWebsitesActions } from '@joorney/useful-websites-shared-frontend-useful-websites-store';
import { ObservableComponentClass } from '@joorney/utils-shared-frontend-ng-component-utils';
import { Store } from '@ngrx/store';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { of, startWith, takeUntil, tap } from 'rxjs';

interface UsefulWebsitesFormData {
  id?: number;
  name: string;
  link: string;
  countryCode: string;
  categoryCountryId: number;
}

interface UsefulWebsitesFormForm {
  name: FormControl<string>;
  link: FormControl<string>;
  countryCode: FormControl<string>;
  categoryCountryId: FormControl<number>;
}

const EMPTY_USEFUL_WEBSITES: UsefulWebsitesFormData = {
  name: '',
  link: '',
  countryCode: '',
  categoryCountryId: 0,
};

const usefulWebsitesDTOToFormData = (dto: UsefulWebsitesElementDTO | undefined): UsefulWebsitesFormData => {
  if (dto === undefined) {
    return EMPTY_USEFUL_WEBSITES;
  }
  return {
    id: dto.id ?? 0,
    name: dto.name ?? '',
    link: dto.link ?? '',
    countryCode: dto.countryCode ?? '',
    categoryCountryId: dto.categoryCountryId ?? 0,
  };
};

const usefulWebsitesFormDataToCreateDTO = (formData: UsefulWebsitesFormData): UsefulWebsitesCreateDTO => {
  return {
    name: formData.name,
    link: formData.link,
    categoryCountryId: formData.categoryCountryId,
  };
};

const usefulWebsitesFormDataToUpdateDTO = (formData: UsefulWebsitesFormData): UsefulWebsitesUpdateData => {
  return {
    id: formData.id as number,
    name: formData.name,
    link: formData.link,
    categoryCountryId: formData.categoryCountryId,
  };
};

@Component({
  selector: 'jw-useful-websites-form-feature',
  standalone: true,
  imports: [AsyncPipe, ReactiveFormsModule, InputTextModule, ButtonModule, DropdownModule, StackLayoutUiComponent],
  templateUrl: './useful-websites-form-feature.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsefulWebsitesFormFeatureComponent extends ObservableComponentClass implements OnInit, OnDestroy, StackContentComponent {
  private initialValue = usefulWebsitesDTOToFormData(this.config.data);
  readonly isNew = this.initialValue.id === undefined;
  readonly closeConfirmationMessage = 'Some changes have not been saved. Are you sure you want to leave?';
  private saved = false;
  readonly title = this.isNew ? 'Add' : 'Edit';
  readonly countryOptions$ = this.store.select($usefulWebsitesCountryOptions);
  readonly categoryOptions$ = this.store.select($usefulWebsitesCategoriesByCountryOptions);

  form = new FormGroup<UsefulWebsitesFormForm>({
    name: new FormControl<string>(this.initialValue.name, { nonNullable: true, validators: [Validators.required] }),
    link: new FormControl<string>(this.initialValue.link, { nonNullable: true, validators: [Validators.required] }),
    countryCode: new FormControl<string>(this.initialValue.countryCode, { nonNullable: true, validators: [Validators.required] }),
    categoryCountryId: new FormControl<number>(this.initialValue.categoryCountryId, { nonNullable: true, validators: [Validators.required] }),
  });

  constructor(
    @Inject(STACK_CONFIG) private config: StackConfig<UsefulWebsitesElementDTO>,
    private store: Store,
  ) {
    super();
  }

  ngOnInit(): void {
    this.form.valueChanges
      .pipe(
        startWith(this.form.value),
        tap((data) => this.store.dispatch(usefulWebsitesActions.formDataUpdated({ data }))),
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.store.dispatch(usefulWebsitesActions.formDataFinalized());
  }

  save(reopen: boolean) {
    const { name, link, categoryCountryId, countryCode } = this.form.value;
    const usefulWebsitesData: UsefulWebsitesFormData = {
      id: this.initialValue.id,
      name: name ?? '',
      link: link ?? '',
      countryCode: countryCode ?? '',
      categoryCountryId: categoryCountryId ?? 0,
    };
    if (this.isNew) {
      this.store.dispatch(usefulWebsitesActions.saveNewClicked({ data: usefulWebsitesFormDataToCreateDTO(usefulWebsitesData), reopen }));
    } else {
      this.store.dispatch(usefulWebsitesActions.saveClicked({ data: usefulWebsitesFormDataToUpdateDTO(usefulWebsitesData) }));
    }
    this.saved = true;
    if (!close) {
      this.form.reset({
        ...EMPTY_USEFUL_WEBSITES,
      });
    }
  }

  delete() {
    const id = this.initialValue.id;
    if (id === undefined) {
      return;
    }
    this.store.dispatch(usefulWebsitesActions.deleteClicked({ id }));
  }

  cancel() {
    this.config.onClose();
  }

  canClose$() {
    return of(!this.form.touched || this.saved);
  }
}
