import { Injectable, Type } from '@angular/core';
import { ConfirmationDialogService } from '@joorney/ui-shared-frontend-confirmation-dialog';
import { BehaviorSubject, Observable, map, of, switchMap, tap } from 'rxjs';

export interface StackContentComponent {
  canClose$(): Observable<boolean>;
  closeConfirmationMessage: string;
}

interface StackConfig {
  component: Type<StackContentComponent> | null;
  data: unknown;
}

const initialConfig: StackConfig = {
  component: null,
  data: null,
};

@Injectable({ providedIn: 'root' })
export class StackService {
  private readonly _config$ = new BehaviorSubject<StackConfig>(initialConfig);
  private readonly _isOpen$ = new BehaviorSubject<boolean>(false);
  private readonly _isExpanded$ = new BehaviorSubject<boolean>(false);

  readonly componentData$ = this._config$.asObservable();
  readonly isOpen$ = this._isOpen$.asObservable();
  readonly isExpanded$ = this._isExpanded$.asObservable();

  private contentComponent: StackContentComponent | null = null;
  private lastCheckedUrl: string | null = null;

  constructor(private readonly confirmationDialogService: ConfirmationDialogService) {}

  registerContent(component: StackContentComponent) {
    this.contentComponent = component;
  }

  allowChange$(confirm = true, url: string | null = null) {
    if (this.contentComponent === null || (this.lastCheckedUrl !== null && this.lastCheckedUrl === url)) {
      return of(true);
    }

    const confirmMessage = this.contentComponent.closeConfirmationMessage;
    return this.contentComponent.canClose$().pipe(
      switchMap((canClose) => (canClose || !confirm ? of({ action: canClose }) : this.confirmationDialogService.open$(confirmMessage))),
      map(({ action }) => action),
      tap((allowChange) => {
        if (allowChange) {
          this.lastCheckedUrl = url;
        }
      }),
    );
  }

  open<D = unknown, T extends StackContentComponent = StackContentComponent>(component: Type<T>, data?: D, isExpanded = false) {
    this._config$.next({ component, data });
    this._isOpen$.next(true);
    this._isExpanded$.next(isExpanded);
  }

  close() {
    this._config$.next(initialConfig);
    this._isOpen$.next(false);
    this._isExpanded$.next(false);
    this.contentComponent = null;
    this.lastCheckedUrl = null;
  }

  toggleExpand() {
    this._isExpanded$.next(!this._isExpanded$.value);
  }
}
