import { Injectable } from '@angular/core';
import {
  BusinessPlanCreateDTO,
  BusinessPlanCreateNewVersionDTO,
  BusinessPlanUpdateDTO,
  BusinessPlanUpdateParamsDTO,
  BusinessPlanUpdateStartingPointDTO,
  businessPlanFeatureControllerCreateBusinessPlan,
  businessPlanFeatureControllerCreateNewVersion,
  businessPlanFeatureControllerGetBpVisaTypeComparison,
  businessPlanFeatureControllerGetBusinessPlanById,
  businessPlanFeatureControllerGetBusinessPlanVersions,
  businessPlanFeatureControllerGetLastBusinessPlanVersion,
  businessPlanFeatureControllerUpdate,
  businessPlanFeatureControllerUpdateBpVisaType,
  businessPlanFeatureControllerUpdateParams,
  businessPlanFeatureControllerUpdateStartingPoint,
} from '@joorney/shell-shared-jwriter-core-api-data-access';
import { throwErrorOnInvalidValue } from '@joorney/utils-shared-rxjs-utils';
import { from } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BusinessPlanDataAccessService {
  getBusinessPlanById(bdIp: number) {
    return from(businessPlanFeatureControllerGetBusinessPlanById({ bpId: bdIp })).pipe(throwErrorOnInvalidValue());
  }

  getLastBusinessPlanVersion(bdIp: number) {
    return from(businessPlanFeatureControllerGetLastBusinessPlanVersion({ bpId: bdIp })).pipe(throwErrorOnInvalidValue());
  }

  getBusinessPlanVersions(bdIp: number) {
    return from(businessPlanFeatureControllerGetBusinessPlanVersions({ bpId: bdIp })).pipe(throwErrorOnInvalidValue());
  }

  getBPVisaTypeComparison(bdIp: number, visaId: string) {
    return from(businessPlanFeatureControllerGetBpVisaTypeComparison({ bpId: bdIp, visaId })).pipe(throwErrorOnInvalidValue());
  }

  updateBPVisaType(bdIp: number, visaTypeId: string) {
    return from(businessPlanFeatureControllerUpdateBpVisaType({ bpId: bdIp, requestBody: { visaTypeId } })).pipe(throwErrorOnInvalidValue());
  }

  saveAsNewVersion$(bpId: number, dto: BusinessPlanCreateNewVersionDTO) {
    return from(businessPlanFeatureControllerCreateNewVersion({ bpId, requestBody: dto })).pipe(throwErrorOnInvalidValue());
  }

  create(bpData: BusinessPlanCreateDTO) {
    return from(businessPlanFeatureControllerCreateBusinessPlan({ requestBody: bpData })).pipe(throwErrorOnInvalidValue());
  }

  configure$(bp: BusinessPlanUpdateDTO, bpId: number) {
    return from(businessPlanFeatureControllerUpdate({ bpId, requestBody: bp })).pipe(throwErrorOnInvalidValue());
  }

  chooseStartingPointOnBPSetup$(bpId: number, dto: BusinessPlanUpdateStartingPointDTO) {
    return from(businessPlanFeatureControllerUpdateStartingPoint({ bpId, requestBody: dto })).pipe(throwErrorOnInvalidValue());
  }

  updateParams(bpId: number, params: BusinessPlanUpdateParamsDTO) {
    return from(businessPlanFeatureControllerUpdateParams({ bpId, requestBody: params })).pipe(throwErrorOnInvalidValue());
  }
}
