export interface TokenInfo {
  firstName: string;
  lastName: string;
  username: string;
  roles: string[];
  exp: number;
  iat: number;
}

export interface ApiResponseRefreshToken {
  message?: string;
  token?: string;
}
