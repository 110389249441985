import { GeneratedContentData, GeneratedContentSalesForecast, JwTableRowClass } from '@joorney/computation-shared-frontend-sections-domain';
import { BusinessPlanSalesForecastType, Enums } from '@joorney/shell-shared-jwriter-core-api-data-access';
import * as _ from 'lodash';

export const transformSalesForecastData = (
  salesForecastData: GeneratedContentSalesForecast,
  salesForecastType: Enums['BusinessPlanSalesForecastType'] | null,
  yearLabels: string[],
): GeneratedContentData[] => {
  let datasource: GeneratedContentData[] = [];
  if (salesForecastType === BusinessPlanSalesForecastType.UNIT) {
    datasource = [
      // Set "Unit Sales" header
      {
        label: 'Unit Sales',
        values: Array<null>(yearLabels.length).fill(null),
        isHeading: true,
        styleClass: [JwTableRowClass.TableHeading, JwTableRowClass.TableDivider],
      },

      // Fill product for "Unit sales" value
      ...(salesForecastData.products?.product ?? []).map((p) => ({
        label: `${p['@name']}`,
        values: (p.year ?? []).map((y) => y.unitSales),
        isHeading: false,
        isCurrency: false,
      })),

      // Set "Total Unit sales" subtotal
      {
        label: 'Total Unit sales',
        values: (salesForecastData.total?.year ?? []).map((y) => y.totalUnitSales),
        isHeading: false,
        styleClass: [JwTableRowClass.TableHeading],
        isCurrency: false,
      },

      // Set "Unit Prices" header
      {
        label: 'Unit Prices',
        values: yearLabels,
        isHeading: true,
        styleClass: [JwTableRowClass.TableHeading, JwTableRowClass.TableDivider],
        isCurrency: true,
        noDigits: true,
      },

      // Fill product for "Unit Prices" value
      ...(salesForecastData.products?.product ?? []).map((p) => {
        const values = (salesForecastData.products?.product ?? []).map(({ year }) => _.flatMap(year, ({ unitPrices, directUnitCosts }) => [unitPrices, directUnitCosts]));
        const includeDecimals = values.some((v) => !_.isInteger(v));
        const unitPrices = (p.year ?? []).map((y) => y.unitPrices);
        return {
          label: `${p['@name']}${p['@perX']}`,
          values: unitPrices,
          isHeading: false,
          styleClass: '',
          isCurrency: true,
          noDigits: !includeDecimals,
          withDigits: includeDecimals,
        };
      }),
    ];
  }

  datasource = [
    ...datasource,

    // Set "Sales" header
    {
      label: 'Sales',
      values: Array<null>(yearLabels.length).fill(null),
      isHeading: true,
      styleClass: [JwTableRowClass.TableHeading, JwTableRowClass.TableDivider],
    },

    // Fill product for "sales" value
    ...(salesForecastData.products?.product ?? []).map((p) => ({
      label: `${p['@name']}`,
      values: (p.year ?? []).map((y) => y.sales),
      isHeading: false,
      styleClass: [],
      isCurrency: true,
      noDigits: true,
    })),

    // Set "Sales" total
    {
      label: 'Total Sales',
      values: (salesForecastData.total?.year ?? []).map((y) => y.totalSales),
      isHeading: false,
      styleClass: [JwTableRowClass.TableHeading],
      isCurrency: true,
      noDigits: true,
    },
  ];

  const unitCostsValues = _.flatMap(salesForecastData.products?.product, ({ year }) => year?.map(({ directUnitCosts }) => directUnitCosts));

  if (salesForecastType === BusinessPlanSalesForecastType.UNIT && unitCostsValues?.some((v) => v !== 0)) {
    datasource = [
      ...datasource,

      // Set "Direct Unit Costs" header
      {
        label: 'Unit Costs',
        values: yearLabels,
        isHeading: true,
        styleClass: [JwTableRowClass.TableHeading, JwTableRowClass.TableDivider],
        isCurrency: true,
        noDigits: true,
      },

      // Fill product for "Direct Unit Costs" value
      ...(salesForecastData.products?.product ?? []).map((p) => {
        const values = (salesForecastData.products?.product ?? []).map(({ year }) => _.flatMap(year, ({ unitPrices, directUnitCosts }) => [unitPrices, directUnitCosts]));
        const includeDecimals = values.some((v) => !_.isInteger(v));
        const directUnitCosts = (p.year ?? []).map((y) => y.directUnitCosts);
        return {
          label: `${p['@name']}${p['@perX']}`,
          values: directUnitCosts,
          isHeading: false,
          styleClass: [],
          isCurrency: true,
          noDigits: !includeDecimals,
          withDigits: includeDecimals,
        };
      }),
    ];
  }

  const directCostSalesValues = _.flatMap(salesForecastData.products?.product, ({ year }) => year?.map(({ directCostOfSales }) => directCostOfSales));

  if (directCostSalesValues?.some((v) => v !== 0)) {
    datasource = [
      ...datasource,

      // Set "Direct cost of sales" header
      {
        label: 'Direct Cost of Sales',
        values: Array<null>(yearLabels.length).fill(null),
        isHeading: true,
        styleClass: [JwTableRowClass.TableHeading, JwTableRowClass.TableDivider],
      },

      // Fill product for "Direct cost of sales" value
      ...(salesForecastData.products?.product ?? []).map((p) => ({
        label: `${p['@name']}`,
        values: (p.year ?? []).map((y) => y.directCostOfSales),
        isHeading: false,
        styleClass: [],
        isCurrency: true,
        noDigits: true,
      })),

      // Set "Direct cost of sales" subtotal
      {
        label: 'Subtotal Direct Cost of Sales',
        values: salesForecastData.total?.year?.map((y) => y.subtotalDirectCostOfSales),
        isHeading: false,
        styleClass: [JwTableRowClass.TableHeading],
        isCurrency: true,
        noDigits: true,
      },
    ];
  }
  return datasource.filter((el) => el.values?.some((v) => v !== 0));
};
