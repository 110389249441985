import { GeneratedContentData, GeneratedContentFeasibilityAnalysis, JwTableRowClass } from '@joorney/computation-shared-frontend-sections-domain';

export const transformFeasibilityAnalysisData = (data: GeneratedContentFeasibilityAnalysis, yearLabels: string[]): GeneratedContentData[] => {
  return [
    {
      label: 'Sales',
      values: data.totalSales,
      styleClass: [JwTableRowClass.TableHeading],
      isCurrency: true,
      noDigits: true,
    },
    {
      label: 'Marketing and Sales Personnel Expenses',
      values: Array<null>(yearLabels.length).fill(null),
      styleClass: [JwTableRowClass.TableHeading],
      isHeading: true,
    },
    ...data.positions.map((p) => ({
      label: p.name,
      values: p.years.map((y) => y.payrollExpenses),
      isCurrency: true,
      noDigits: true,
      isHeading: false,
    })),
    {
      label: 'Other Marketing and Sales Expenses',
      values: Array<null>(yearLabels.length).fill(null),
      styleClass: [JwTableRowClass.TableHeading],
      isHeading: true,
    },
    ...data.expenses.map((p) => ({
      label: p.name,
      values: p.data.map((y) => y.value),
      isCurrency: true,
      noDigits: true,
      isHeading: false,
    })),
    {
      label: 'Total Marketing and Sales Expenses',
      values: data.totalMarketingAndSalesExpenses,
      isCurrency: true,
      noDigits: true,
      styleClass: [JwTableRowClass.TableHeading],
      isHeading: true,
    },
    {
      label: 'Total Marketing and Sales Expenses as % of Sales',
      values: data.totalMarketingAndSalesExpensesAsPercentOfSales.map((t) => t * 100),
      isPercentage: true,
      styleClass: [JwTableRowClass.TableHeading],
      isHeading: true,
    },
  ].filter((el) => el.values.some((v) => v !== 0));
};
