import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { contextActions } from '@joorney/business-plan-shared-frontend-context-store';
import { STACK_CONFIG, StackConfig, StackContentComponent } from '@joorney/shell-shared-frontend-feature-stack';
import { StackLayoutUiComponent } from '@joorney/shell-shared-frontend-stack-layout-ui';
import { PercentageInputUiComponent } from '@joorney/ui-shared-frontend-percentage-input-ui';
import { Store } from '@ngrx/store';
import { ButtonModule } from 'primeng/button';
import { of } from 'rxjs';

interface PayrollTaxesFormForm {
  payrollTaxRate: FormControl<number>;
}

@Component({
  selector: 'jw-payroll-taxes-form-feature',
  standalone: true,
  imports: [ReactiveFormsModule, PercentageInputUiComponent, ButtonModule, StackLayoutUiComponent],
  templateUrl: './payroll-taxes-form-feature.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PayrollTaxesFormFeatureComponent implements StackContentComponent {
  readonly closeConfirmationMessage = 'Some changes have not been saved. Are you sure you want to leave?';
  private saved = false;

  constructor(
    @Inject(STACK_CONFIG) private config: StackConfig<number>,
    private store: Store,
  ) {}

  form = new FormGroup<PayrollTaxesFormForm>({
    payrollTaxRate: new FormControl<number>(this.config.data ?? 0, { nonNullable: true, validators: [Validators.required] }),
  });

  save() {
    this.store.dispatch(contextActions.payrollTaxRateSaveClicked({ data: this.form.controls.payrollTaxRate.value }));
    this.saved = true;
  }

  cancel() {
    this.config.onClose();
  }

  canClose$() {
    return of(!this.form.touched || this.saved);
  }
}
