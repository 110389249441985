import { inject } from '@angular/core';
import { UsageTimesService } from '@joorney/usage-times-shared-frontend-usage-times-data-access';
import { Actions, ROOT_EFFECTS_INIT, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { concatMap, interval, map, switchMap } from 'rxjs';
import { usageTimeActions } from './usage-time.actions';
import { LOG_POLLING_INTERVAL, USAGE_TIME_ENTRY_DATA, USAGE_TIME_ENTRY_POLLING_INTERVAL } from './usage-time.constants';
import { $usageTimeEntries } from './usage-time.selectors';

export const init$ = createEffect(
  (action$ = inject(Actions)) =>
    action$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      map(() => usageTimeActions.usageTimeEffectInitialized()),
    ),
  { functional: true },
);

export const pollEntry$ = createEffect(
  (action$ = inject(Actions), store = inject(Store), $entryData = inject(USAGE_TIME_ENTRY_DATA)) => {
    return action$.pipe(
      ofType(usageTimeActions.usageTimeEffectInitialized),
      switchMap(() => interval(USAGE_TIME_ENTRY_POLLING_INTERVAL)),
      concatLatestFrom(() => store.select($entryData)),
      map(([, { businessPlanId, page }]) => usageTimeActions.usageTimeEntryPolled({ businessPlanId: businessPlanId as number, page })),
    );
  },
  { functional: true },
);

export const pollLogEntries$ = createEffect(
  (action$ = inject(Actions), store = inject(Store), usageTimesService = inject(UsageTimesService)) => {
    return action$.pipe(
      ofType(usageTimeActions.usageTimeEffectInitialized),
      switchMap(() => interval(LOG_POLLING_INTERVAL)),
      concatLatestFrom(() => store.select($usageTimeEntries)),
      concatMap(([, entries]) => usageTimesService.addEntries$(entries)),
      map(() => usageTimeActions.usageTimeEntriesLogged()),
    );
  },
  { functional: true },
);
