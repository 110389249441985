export enum JwTableRowClass {
  TableHeading = 'table-heading',
  TableFooter = 'table-footer',
  TableDivider = 'table-divider',
  TableSection = 'table-section',
  TableSubCategory = 'table-sub-category',
  TableCategory = 'table-category',
  TableHighlightedRow = 'table-highlighted-row',
  TableRowColorWhite = 'table-row-color-white',
}
