/**
 * Return an array of @param length elements, according to the @param firstValue and @param  rate
 */
export const calculateLinearValues = (firstValue: number, rate: number, length: number): number[] => {
  let value = firstValue;
  const linearValues: number[] = [];

  new Array(length).fill(0).forEach(() => {
    linearValues.push(value);
    value = (value * rate) / 100 + value;
  });

  return linearValues;
};
