export interface GeneratedContentProfitAndLoss {
  year?: GeneratedContentProfitAndLossYear[];
}

export interface GeneratedContentProfitAndLossYear {
  '@n'?: number;
  profitBeforeInterestAndTaxes?: number;
  ebitda?: number;
  interestIncurred?: number;
  taxesIncurred?: number;
  totalTax?: number;
  netProfit?: number;
  netProfitSalesRatio?: number;
}
