import { Injectable } from '@angular/core';
import { usefulWebsitesCategoriesFeatureControllerGetAll } from '@joorney/shell-shared-jwriter-core-api-data-access';
import { throwErrorOnInvalidValue } from '@joorney/utils-shared-rxjs-utils';
import { from } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UsefulWebsitesCategoriesDataAccessService {
  findAll() {
    return from(usefulWebsitesCategoriesFeatureControllerGetAll()).pipe(throwErrorOnInvalidValue());
  }
}
