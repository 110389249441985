export enum MarketingSection {
  socialMedia = 'socialMedia',
  website = 'website',
  eventsShows = 'eventsShows',
  reviewPlatforms = 'reviewPlatforms',
  others = 'others',
}

export enum WebsiteStatus {
  HasNoWebsite,
  HasDomain,
  HasWebsite,
}

export enum SocialMedia {
  Facebook,
  Instagram,
  X,
  LinkedIn,
  TikTok,
  YouTube,
}

export enum OtherMarketingStrategy {
  EmailCampaigns,
  WordMouthMarketing,
  BrandedMaterial,
  NewspapersMagazines,
  ECommerceMarketing,
  Blog,
  RadioAdvertising,
  InfluencerMarketing,
  DiscountsCoupons,
  BookingPlatforms,
  Newsletter,
}

export type MarketingStatus = 'None' | 'Has' | 'Will';
