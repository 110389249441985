import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { GroupDataAccessService } from '@joorney/users-shared-frontend-group-data-access';
import { UserGroup } from '@joorney/users-shared-user-group-domain';
import { userActions } from '@joorney/users-shared-frontend-user-store';
import { Store } from '@ngrx/store';
import _ from 'lodash';
import { map, tap } from 'rxjs';
import { NO_ACCESS_ROUTE } from '../routing/authentication-routing.constant';

const isNewUser$ = () => {
  const store = inject(Store);
  return inject(GroupDataAccessService)
    .findMine()
    .pipe(
      map((groups) => groups.map((g) => g.name as UserGroup)),
      tap((groups) => store.dispatch(userActions.setCurrentGroups({ groups }))),
      map((groups) => _.isEmpty(groups)),
    );
};

export const authGuard: CanActivateFn = () => {
  const msalService = inject(MsalService);
  const router = inject(Router);
  return isNewUser$().pipe(
    map((isNewUser) => {
      console.log('authGuard', isNewUser);
      if (isNewUser) {
        return router.createUrlTree([NO_ACCESS_ROUTE]);
      }
      return msalService.instance.getActiveAccount() === null ? true : router.createUrlTree(['']);
    }),
  );
};

export const authNoAccessGuard: CanActivateFn = () => {
  const msalService = inject(MsalService);
  const router = inject(Router);
  return isNewUser$().pipe(
    map((isNewUser) => {
      if (isNewUser) {
        return true;
      }
      return msalService.instance.getActiveAccount() === null ? true : router.createUrlTree(['']);
    }),
  );
};
