import _ from 'lodash';
import { contentControlLocation } from './msoffice-content-control.utils';

const HIGHLIGHT_COLOR = 'yellow';
export const WORD_HIGHLIGHT_TAG = 'mark';
export const WORD_BOLD_TAG = 'bold';
export const WORD_FOOTNOTE_TAG = 'note';

export enum JwWordTextInsertLocation {
  Before = 'Before',
  After = 'After',
}

export interface JwWordTextInsertData {
  type: 'text';
  paragraphText: string;
  footNotes?: string[];
  insertLocation?: 'Before' | 'After';
  textAlignment?: 'Left' | 'Centered' | 'Right' | 'Justified';
}

const replaceTag = async (tag: string, context: Word.RequestContext, text: Word.Paragraph, transform: (result: Word.Range, index: number) => void) => {
  const results = text.search(`[<]${tag}[>]*[</]${tag}[>]`, { matchWildcards: true });
  results.load('length');
  await context.sync();

  results.items.forEach((result) => result.load('text'));
  await context.sync();

  for (let i = 0; i < results.items.length; i++) {
    const result = results.items[i];
    const newText = result.text.replace(new RegExp(`<${tag}>(.*?)</${tag}>`, 'g'), '$1');
    result.insertText(newText, 'Replace');
    transform(result, i);
  }
  await context.sync();
};

export const jwWordMsOfficeTextInsertContentControl = async (
  context: Word.RequestContext,
  contentControl: Word.ContentControl,
  { paragraphText, footNotes, insertLocation = 'After', textAlignment = 'Justified' }: JwWordTextInsertData,
): Promise<void> => {
  const text = contentControl.insertParagraph(paragraphText, contentControlLocation[insertLocation]);
  text.load('font');
  text.style = 'Normal'; // Reset characters and paragraph style to default.
  text.font.name = 'Calibri';
  text.alignment = textAlignment;
  await replaceTag(WORD_HIGHLIGHT_TAG, context, text, (result) => (result.font.highlightColor = HIGHLIGHT_COLOR));
  await replaceTag(WORD_BOLD_TAG, context, text, (result) => (result.font.bold = true));
  if (footNotes === undefined || _.isEmpty(footNotes)) {
    return;
  }
  await replaceTag(WORD_FOOTNOTE_TAG, context, text, (result, i) => {
    const footnoteRange = result.getRange('End').insertFootnote(footNotes[i]);
    footnoteRange.body.font.name = 'Calibri';
  });
};
