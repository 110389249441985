import { GeneratedContentCostAndBenefitAnalysis, GeneratedContentData, JwTableRowClass } from '@joorney/computation-shared-frontend-sections-domain';

export const transformCostAndBenefitAnalysisData = (data: GeneratedContentCostAndBenefitAnalysis, yearLabels: string[]): GeneratedContentData[] => {
  return [
    {
      label: 'Sales',
      values: data.sales,
      styleClass: [JwTableRowClass.TableHeading, JwTableRowClass.TableSection],
      isCurrency: true,
      noDigits: true,
      isHeading: true,
    },
    {
      label: 'Net Profit',
      values: data.netProfit,
      isHeading: true,
      styleClass: [JwTableRowClass.TableHeading, JwTableRowClass.TableSection],
      isCurrency: true,
      noDigits: true,
    },
    {
      label: 'Expenses',
      values: Array<null>(yearLabels.length).fill(null),
      isHeading: true,
      styleClass: [JwTableRowClass.TableHeading, JwTableRowClass.TableSection],
    },
    {
      label: 'Direct Cost',
      values: data.directCost,
      isHeading: true,
      isCurrency: true,
      noDigits: true,
    },
    {
      label: 'Direct Cost/Total Cost',
      values: data.directCost_totalCost.map((item) => item * 100),
      isHeading: true,
      isPercentage: true,
    },
    {
      label: 'Indirect Cost',
      values: Array<null>(yearLabels.length).fill(null),
      isHeading: true,
      styleClass: [JwTableRowClass.TableHeading, JwTableRowClass.TableSection],
    },
    ...data.indirectCosts.map((indirectCost) => ({
      label: indirectCost.name,
      values: indirectCost.data.map((datum) => datum.value),
      isHeading: false,
      isCurrency: true,
      noDigits: true,
    })),
    {
      label: 'Subtotal Indirect Cost',
      values: data.subtotalIndirectCost,
      isHeading: true,
      styleClass: [JwTableRowClass.TableHeading, JwTableRowClass.TableSection],
      isCurrency: true,
      noDigits: true,
    },
    {
      label: 'Indirect Cost/Total Cost',
      values: data.indirectCost_totalCost.map((item) => item * 100),
      isHeading: true,
      isPercentage: true,
      isCurrency: true,
      noDigits: true,
    },
    {
      label: 'Total Expenses',
      values: data.totalExpenses,
      isHeading: true,
      styleClass: [JwTableRowClass.TableHeading, JwTableRowClass.TableSection],
      isCurrency: true,
      noDigits: true,
    },
    {
      label: 'Net Profit/Total Expenses',
      values: data.netProfit_totalExpenses.map((item) => item * 100),
      isHeading: true,
      styleClass: [JwTableRowClass.TableHeading, JwTableRowClass.TableSection],
      isPercentage: true,
      isCurrency: true,
      noDigits: true,
    },
  ].filter((el) => el.values?.some((v) => v !== 0));
};
