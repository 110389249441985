import { Injectable } from '@angular/core';
import {
  OperatingExpensesCreateDTO,
  OperatingExpensesUpdateDTO,
  operatingExpensesFeatureControllerCalculateCustomRate,
  operatingExpensesFeatureControllerCreateOperatingExpenses,
  operatingExpensesFeatureControllerDeleteOperatingExpenses,
  operatingExpensesFeatureControllerGetOperatingExpensesList,
  operatingExpensesFeatureControllerGetOutsourcingExpenses,
  operatingExpensesFeatureControllerReorderOperatingExpenses,
  operatingExpensesFeatureControllerUpdateOperatingExpenses,
} from '@joorney/shell-shared-jwriter-core-api-data-access';
import { throwErrorOnInvalidValue } from '@joorney/utils-shared-rxjs-utils';
import { from } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OperatingExpensesDataAccessService {
  create(bpId: number, dto: OperatingExpensesCreateDTO) {
    return from(operatingExpensesFeatureControllerCreateOperatingExpenses({ authorization: '', bpId, requestBody: dto })).pipe(throwErrorOnInvalidValue());
  }

  findAll(bpId: number) {
    return from(operatingExpensesFeatureControllerGetOperatingExpensesList({ authorization: '', bpId })).pipe(throwErrorOnInvalidValue());
  }

  findAllOutsourcings(bpId: number) {
    return from(operatingExpensesFeatureControllerGetOutsourcingExpenses({ authorization: '', bpId })).pipe(throwErrorOnInvalidValue());
  }

  update(bpId: number, dto: OperatingExpensesUpdateDTO) {
    return from(operatingExpensesFeatureControllerUpdateOperatingExpenses({ authorization: '', bpId, id: dto.id, requestBody: dto })).pipe(throwErrorOnInvalidValue());
  }

  saveItemsOrder(bpId: number, newOrders: number[]) {
    return from(operatingExpensesFeatureControllerReorderOperatingExpenses({ authorization: '', bpId, requestBody: { orderExpenses: newOrders } })).pipe(
      throwErrorOnInvalidValue(),
    );
  }

  delete(bpId: number, id: number) {
    return from(operatingExpensesFeatureControllerDeleteOperatingExpenses({ authorization: '', bpId, id })).pipe(throwErrorOnInvalidValue());
  }

  calculateCustomRate(bpId: number, dto: OperatingExpensesCreateDTO) {
    return from(operatingExpensesFeatureControllerCalculateCustomRate({ authorization: '', bpId, requestBody: dto })).pipe(throwErrorOnInvalidValue());
  }
}
