import { JwwNavSection } from '@joorney/layout-shared-frontend-tab-nav-domain';
import { convertEnumToArray } from '@joorney/utils-shared-ts-utils';
import { createFeature, createReducer, on } from '@ngrx/store';
import { produce } from 'immer';
import { TAB_NAV_FEATURE_KEY, tabNavActions } from './tab-nav.actions';

export interface TabNavState {
  activeSectionId: JwwNavSection | undefined;
  showBetaMode: boolean;
}

const initialState: TabNavState = {
  activeSectionId: JwwNavSection.Financial,
  showBetaMode: false,
};

export const tabNavFeature = createFeature({
  name: TAB_NAV_FEATURE_KEY,
  reducer: createReducer(
    initialState,
    on(tabNavActions.tabNavSectionClicked, (state, { sectionId }) => {
      if (sectionId !== undefined && !convertEnumToArray(JwwNavSection).includes(sectionId)) {
        return state;
      }
      return produce(state, (draft) => {
        draft.activeSectionId = sectionId as JwwNavSection;
      });
    }),
    on(tabNavActions.tabNaBetaModeClicked, (state) =>
      produce(state, (draft) => {
        draft.showBetaMode = !state.showBetaMode;
        draft.activeSectionId = draft.showBetaMode ? JwwNavSection.Beta : JwwNavSection.Financial;
      }),
    ),
    on(tabNavActions.tabNavFinalize, () => initialState),
  ),
});
