import { MonoTypeOperatorFunction, Observable, ObservableInput, ObservedValueOf, OperatorFunction, concatMap, last, map, pipe, scan, switchMap, takeWhile, tap, timer } from 'rxjs';

export const debug =
  (...messages: unknown[]) =>
  <T>(source: Observable<T>) =>
    new Observable<T>((observer) =>
      source.subscribe({
        next(x) {
          // eslint-disable-next-line no-console
          console.log(...messages, x);
          observer.next(x);
        },
        error(err: unknown) {
          console.error(...messages, err);
          observer.error(err);
        },
        complete() {
          observer.complete();
        },
      }),
    );

export const switchTap =
  <T, O extends ObservableInput<T>, R extends Observable<unknown>>(project: (value: T, index: number) => R): OperatorFunction<T, ObservedValueOf<O>> =>
  (source) =>
    source.pipe(switchMap((value, index) => project(value, index).pipe(map(() => value)) as Observable<ObservedValueOf<O>>));

export const concatTap =
  <T, O extends ObservableInput<T>, R extends Observable<unknown>>(project: (value: T, index: number) => R): OperatorFunction<T, ObservedValueOf<O>> =>
  (source) =>
    source.pipe(concatMap((value, index) => project(value, index).pipe(map(() => value)) as Observable<ObservedValueOf<O>>));

export const throwErrorOnInvalidValue = <T>(message = 'Error'): OperatorFunction<T, NonNullable<T>> =>
  pipe(
    map((result: T) => {
      if (!result) {
        throw new Error(message);
      }
      return result;
    }),
  );

const attemptsGuardFactory = (maxAttempts: number) => {
  return (attemptsCount: number) => {
    if (attemptsCount > maxAttempts) {
      throw new Error('Exceeded maxAttempts');
    }
  };
};

export const pollWhile = <T>(pollInterval: number, isPollingActive: (res: T) => boolean, maxAttempts = Infinity, emitOnlyLast = false): MonoTypeOperatorFunction<T> => {
  return (source$) => {
    const poll$ = timer(0, pollInterval).pipe(
      scan((attempts) => ++attempts, 0),
      tap(attemptsGuardFactory(maxAttempts)),
      switchMap(() => source$),
      takeWhile(isPollingActive, true),
    );

    return emitOnlyLast ? poll$.pipe(last()) : poll$;
  };
};
