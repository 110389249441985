import { Enums } from '@joorney/shell-shared-jwriter-core-api-data-access';

export interface GeneratedContentBreakEven {
  '@type'?: Enums['BusinessPlanBreakEvenType'];
  table: GeneratedContentBreakEvenTable;
  chart: GeneratedContentBreakEvenChart;
}

export interface GeneratedContentBreakEvenTable {
  averagePerUnitRevenue?: number;
  averagePerUnitVariableCost?: number;
  estimatedMonthlyFixedCost?: number;
  monthlyUnitsBreakEven?: number;
  monthlyRevenueBreakEven?: number;
  dailyRevenueBreakEven?: number;
  annualRevenueBreakEven?: number;
  estimatedDailyFixedCost?: number;
  estimatedAnnualFixedCost?: number;
}

export interface GeneratedContentBreakEvenChart {
  categoriesX: { X: number[] };
  series: GeneratedContentBreakEvenChartSeries;
}

export interface GeneratedContentBreakEvenChartSeries {
  serieRevenues: { Y: number[] };
  serieFixedCosts: { Y: number[] };
  serieTotalCosts: { Y: number[] };
}
