import { FundingType, GeneratedContentFunding, GeneratedContentFundingsAndInvestments } from '@joorney/computation-shared-frontend-sections-domain';
import { WORD_HIGHLIGHT_TAG } from '@joorney/ms-office-jwriter-word-office-api-data-access';
import { noDigitsCurrencyTransform, withDigitsCurrencyTransform } from '@joorney/utils-shared-frontend-ng-value-format-utils';
import { enumerateValuesToString } from '@joorney/utils-shared-ts-utils';
import * as _ from 'lodash';

const APPLICANT_TITLES = ['Mr.', 'Mrs.', 'Ms.'];
const ASSET_FUNDING_TYPES = [FundingType.Asset, FundingType.Funding];

const applicantsSourceOfFundsTexts = (applicantNamesText: string, applicantsInvestmentAmount: string, companyName: string) => [
  `${applicantNamesText} personally invested a total of ${applicantsInvestmentAmount} in ${companyName}.`,
  `The Company has financed all investments from ${applicantNamesText}’s personal funds, without any loans from financial institutions.`,
];

const corporateSourceOfFundsTexts = (corporateNamesText: string, corporateInvestmentAmount: string, companyName: string) => [
  `${corporateNamesText} invested a total of ${corporateInvestmentAmount} into ${companyName}.`,
  `The Company has financed all investments from ${corporateNamesText}'s corporate funds, without any loans from financial institutions.`,
];

const loansDetailTexts = (loanFunds: GeneratedContentFunding[]) =>
  loanFunds.map(
    ({ termMonths, interestRate }, i) =>
      `The loan ${loanFunds.length === 1 ? '' : `${i + 1} `}repayment period is ${termMonths} months, while the interest rate equals ${interestRate}%.`,
  );

const loansSourceOfFundsTexts = (loanInvestmentAmount: string, companyName: string, loansNumber: number) => [
  `${companyName} secured ${loanInvestmentAmount} through ${loansNumber === 1 ? `a corporate loan from <${WORD_HIGHLIGHT_TAG}>Bank</${WORD_HIGHLIGHT_TAG}>` : `multiple corporate loans from <${WORD_HIGHLIGHT_TAG}>Banks</${WORD_HIGHLIGHT_TAG}>`}.`,
  `The Company has financed all investments from ${loansNumber === 1 ? 'this loan' : 'these loans'}.`,
];

const applicantsAndCorporateSourceOfFundsTexts = (
  applicantNamesText: string,
  applicantsInvestmentAmount: string,
  corporateNamesText: string,
  corporateInvestmentAmount: string,
  companyName: string,
) => [
  `${applicantNamesText} personally invested a total of ${applicantsInvestmentAmount} in ${companyName} and ${corporateNamesText} invested a total of ${corporateInvestmentAmount} into the Company.`,
  'The Company has financed all investments from the above mentioned personal and corporate funds, without any loans from financial institutions.',
];

const applicantsAndLoansSourceOfFundsTexts = (
  applicantNamesText: string,
  applicantsInvestmentAmount: string,
  loanInvestmentAmount: string,
  loansNumber: number,
  companyName: string,
) => [
  `${applicantNamesText} personally invested a total of ${applicantsInvestmentAmount} in ${companyName} and the Company secured ${loanInvestmentAmount} through ${loansNumber === 1 ? `a corporate loan from <${WORD_HIGHLIGHT_TAG}>Bank</${WORD_HIGHLIGHT_TAG}>` : `multiple corporate loans from <${WORD_HIGHLIGHT_TAG}>Banks</${WORD_HIGHLIGHT_TAG}>`}.`,
];

const corporateAndLoansSourceOfFundsTexts = (
  corporateNamesText: string,
  corporateInvestmentAmount: string,
  loanInvestmentAmount: string,
  loansNumber: number,
  companyName: string,
) => [
  `${corporateNamesText} invested a total of ${corporateInvestmentAmount} in ${companyName} and the Company secured ${loanInvestmentAmount} through ${loansNumber === 1 ? `a corporate loan from <${WORD_HIGHLIGHT_TAG}>Bank</${WORD_HIGHLIGHT_TAG}>` : `multiple corporate loans from <${WORD_HIGHLIGHT_TAG}>Banks</${WORD_HIGHLIGHT_TAG}>`}.`,
];

const multipleSourceOfFundsTexts = (
  applicantNamesText: string,
  applicantsInvestmentAmount: string,
  corporateNamesText: string,
  corporateInvestmentAmount: string,
  loanInvestmentAmount: string,
  loansNumber: number,
  totalInvestmentAmount: string,
  companyName: string,
) => [
  `${applicantNamesText} personally invested a total of ${applicantsInvestmentAmount} in ${companyName}, ${corporateNamesText} invested a total of ${corporateInvestmentAmount} into the Company, while ${companyName} also took ${loansNumber === 1 ? `a corporate loan equaling ${loanInvestmentAmount} from <${WORD_HIGHLIGHT_TAG}>Bank</${WORD_HIGHLIGHT_TAG}>` : `multiple corporate loans equaling ${loanInvestmentAmount} from <${WORD_HIGHLIGHT_TAG}>Banks</${WORD_HIGHLIGHT_TAG}>`}. The total investment in the Company from the above-mentioned funds stands at ${totalInvestmentAmount}.`,
];

export const generateSourceOfFundsText = (fundingsAndInvestments: GeneratedContentFundingsAndInvestments, companyName: string, locale: string) => {
  const fundings = fundingsAndInvestments.year[0].fundings.funding;
  const includeDecimals = fundings.some((fund) => !_.isInteger(fund.amount));
  const currencyFormatter = includeDecimals ? withDigitsCurrencyTransform : noDigitsCurrencyTransform;

  const applicantFunds = fundings.filter((funding) => ASSET_FUNDING_TYPES.includes(funding.type) && APPLICANT_TITLES.some((title) => (funding.source ?? '').includes(title)));
  const applicantNamesText = enumerateValuesToString(
    _.chain(applicantFunds)
      .map((f) => f.source ?? '')
      .uniq()
      .value(),
  );
  const applicantsInvestmentAmountFormatted = currencyFormatter(
    _.sumBy(applicantFunds, (f) => f.amount),
    locale,
  );

  const corporateFunds = fundings.filter((funding) => ASSET_FUNDING_TYPES.includes(funding.type) && APPLICANT_TITLES.every((title) => !(funding.source ?? '').includes(title)));
  const corporateNamesText = enumerateValuesToString(corporateFunds.map((funding) => funding.source ?? ''));
  const corporateInvestmentAmountFormatted = currencyFormatter(
    _.sumBy(corporateFunds, (f) => f.amount),
    locale,
  );

  const loanFunds = fundings.filter((funding) => funding.type === FundingType.Loan);
  const loansText = loansDetailTexts(loanFunds);
  const loanInvestmentAmountFormatted = currencyFormatter(
    _.sumBy(loanFunds, (f) => f.amount),
    locale,
  );

  const totalInvestmentAmountFormatted = currencyFormatter(
    _.sumBy(fundings, (f) => f.amount),
    locale,
  );

  // Applicant is the sole source of funds
  if (applicantFunds.length && !corporateFunds.length && !loanFunds.length) {
    return applicantsSourceOfFundsTexts(applicantNamesText, applicantsInvestmentAmountFormatted, companyName);
  }
  // Corporate funds are the sole source of funds
  if (!applicantFunds.length && corporateFunds.length && !loanFunds.length) {
    return corporateSourceOfFundsTexts(corporateNamesText, corporateInvestmentAmountFormatted, companyName);
  }

  // Loan is the sole source of funds
  if (!applicantFunds.length && !corporateFunds.length && loanFunds.length) {
    return [...loansSourceOfFundsTexts(loanInvestmentAmountFormatted, companyName, loanFunds.length), ...loansText];
  }

  // Personal and corporate funds are the source of funds
  if (applicantFunds.length && corporateFunds.length && !loanFunds.length) {
    return applicantsAndCorporateSourceOfFundsTexts(applicantNamesText, applicantsInvestmentAmountFormatted, corporateNamesText, corporateInvestmentAmountFormatted, companyName);
  }

  // Personal and loan funds are the source of funds
  if (applicantFunds.length && !corporateFunds.length && loanFunds.length) {
    return [
      ...applicantsAndLoansSourceOfFundsTexts(applicantNamesText, applicantsInvestmentAmountFormatted, loanInvestmentAmountFormatted, loanFunds.length, companyName),
      ...loansText,
    ];
  }

  // Corporate and loan funds are the source of funds
  if (!applicantFunds.length && corporateFunds.length && loanFunds.length) {
    return [
      ...corporateAndLoansSourceOfFundsTexts(corporateNamesText, corporateInvestmentAmountFormatted, loanInvestmentAmountFormatted, loanFunds.length, companyName),
      ...loansText,
    ];
  }

  // Multiple investment sources
  if (applicantFunds.length && corporateFunds.length && loanFunds.length) {
    return multipleSourceOfFundsTexts(
      applicantNamesText,
      applicantsInvestmentAmountFormatted,
      corporateNamesText,
      corporateInvestmentAmountFormatted,
      loanInvestmentAmountFormatted,
      loanFunds.length,
      totalInvestmentAmountFormatted,
      companyName,
    );
  }

  return [];
};
