import { Injectable } from '@angular/core';
import {
  employeeFeatureControllerGetBusinessPlanApplicants,
  employeeFeatureControllerGetListEmployeesInPosition,
  employeeFeatureControllerUpdateEmployeesInPosition,
  EmployeeInPositionDTO,
  EmployeesInPositionDTO,
  InfoPositionDTO,
  UpdateEmployeeInPositionDTO,
} from '@joorney/shell-shared-jwriter-core-api-data-access';
import { throwErrorOnInvalidValue } from '@joorney/utils-shared-rxjs-utils';
import _ from 'lodash';
import { from, map } from 'rxjs';

export interface PP_TempListEmployee {
  employees?: PP_SaveEmployee[];
  infoPosition?: InfoPositionDTO[];
}

export interface PP_SaveEmployee extends Pick<EmployeeInPositionDTO, 'id' | 'dates' | 'alreadyHired' | 'title' | 'startingMonth'> {
  startDate: number;
  endDate: number;
  status: 'new' | 'old' | 'deleted';
  fullName: string;
  displayedName: string;
  applicant: number | null;
  reset: boolean;
  '0'?: boolean;
  '1'?: boolean;
  '2'?: boolean;
  '3'?: boolean;
  '4'?: boolean;
}

@Injectable({ providedIn: 'root' })
export class EmployeeDataAccessService {
  getBusinessPlanApplicants(bdIp: number) {
    return from(employeeFeatureControllerGetBusinessPlanApplicants({ bpId: bdIp })).pipe(throwErrorOnInvalidValue());
  }

  getList(businessPlanId: number, positionId: number, positionName: string) {
    return from(employeeFeatureControllerGetListEmployeesInPosition({ authorization: '', bpId: businessPlanId, positionId })).pipe(
      map((employees) => this.formatEmployeesData(employees, positionName)),
      throwErrorOnInvalidValue(),
    );
  }

  saveChanges(data: UpdateEmployeeInPositionDTO[], businessPlanId: number, positionId: number) {
    return from(employeeFeatureControllerUpdateEmployeesInPosition({ authorization: '', bpId: businessPlanId, positionId, requestBody: { employees: data } })).pipe(
      throwErrorOnInvalidValue(),
    );
  }

  private formatEmployeesData(listEmployees: EmployeesInPositionDTO, positionName: string): PP_TempListEmployee {
    const employees: PP_SaveEmployee[] = [];
    listEmployees.employees?.forEach((e, index) => {
      const emp: PP_SaveEmployee = {
        id: e.id,
        dates: e.dates,
        startDate: e.dates[0],
        endDate: e.dates[1],
        status: 'old',
        alreadyHired: e.alreadyHired,
        fullName: e.name,
        displayedName: e.name || `${positionName} - ${index + 1}`,
        applicant: e.idApplicant,
        reset: false,
        title: e.title,
        startingMonth: e.startingMonth,
      };
      for (let i = 0; i < listEmployees.infoPosition.length; i++) {
        if (e.dates[0] <= i && e.dates[1] >= i) {
          _.set(emp, i, true);
        } else {
          _.set(emp, i, false);
        }
      }
      employees.push(emp);
    });

    return { employees, infoPosition: listEmployees.infoPosition };
  }
}
