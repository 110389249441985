export interface GeneratedContentPersonnel {
  positions?: GeneratedContentPersonnelPositions;
  total?: GeneratedContentPersonnelTotal;
  hiringPlan?: GeneratedContentPersonnelHiringPlan;
}

export interface GeneratedContentPersonnelPositions {
  position?: GeneratedContentPersonnelPosition[];
}

export interface GeneratedContentPersonnelPosition {
  year?: GeneratedContentPersonnelPositionYear[];
  '@name': string;
}

export interface GeneratedContentPersonnelPositionYear {
  numberOfEmployees?: number;
  payrollExpenses?: number;
  designatedSalaryPerPosition?: number;
  '@n'?: number;
}

export interface GeneratedContentPersonnelTotal {
  year?: GeneratedContentPersonnelTotalYear[];
}

export interface GeneratedContentPersonnelTotalYear {
  '@n'?: number;
  totalNumberOfEmployees?: number;
  totalPayrollExpenses?: number;
  totalPayrollTaxes?: number;
}

export interface GeneratedContentPersonnelHiringPlan {
  year?: GeneratedContentPersonnelHiringPlanYear[];
}

export interface GeneratedContentPersonnelHiringPlanYear {
  '@n'?: number;
  event?: string[];
}
