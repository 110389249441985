<div class="p-3">
  {{ message }}
</div>

<div class="text-right">
  <p-button [text]="true" (click)="cancel()" i18n-label="" label="Cancel" />
  <p-button
    [rounded]="true"
    (onClick)="accept()"
    i18n-label=""
    label="OK"
    styleClass="ml-2"
  />
</div>
