import { formatCurrency, formatNumber } from '@angular/common';
import { GeneratedContentData, JwTableCellContent, JwTableRowClass } from '@joorney/computation-shared-frontend-sections-domain';
import { JwWordTableColor } from '@joorney/ms-office-jwriter-word-sections-domain';
import * as _ from 'lodash';
import { contentControlLocation } from './msoffice-content-control.utils';

const JW_WORD_TABLE_FONTNAME = 'Calibri';
const JW_WORD_TABLE_FONTSIZE = 9;
const JW_WORD_TABLE_FIRST_COL_WIDTH = 280;
const DEFAULT_LOCALE = 'en-US';
export const JW_WORD_TABLE_PERSONNEL_SUMMARY_FIRST_COL_WIDTH = 50;

export enum JwWordAlignment {
  Left = 'Left',
  Centered = 'Centered',
  Right = 'Right',
  Justified = 'Justified',
}
export interface JwWordTableClass {
  horizontalAlignment?: JwWordAlignment;
  firstColHorizontalAlignment?: JwWordAlignment;
  firstColWidth?: number;
  firstColBold?: true;
  firstColColor?: JwWordTableColor;
  firstColBackgroundColor?: JwWordTableColor;
}

export interface JwWordTableInsertData {
  type: 'table';
  tableTitle: string;
  columnTitles: string[];
  generatedContentData: GeneratedContentData[];
  headerRowClass?: JwTableRowClass[];
  tableClass?: JwWordTableClass;
  insertLocation?: 'Before' | 'After';
}

const updateWordTableStyle = (table: Word.Table, tableClass?: JwWordTableClass) => {
  table.load(['font', 'horizontalAlignment', 'verticalAlignment']);
  table.styleBuiltIn = 'NoSpacing';
  table.font.name = JW_WORD_TABLE_FONTNAME;
  table.font.size = JW_WORD_TABLE_FONTSIZE;
  table.font.bold = false;
  table.font.italic = false;
  table.font.color = JwWordTableColor.Black;
  table.font.highlightColor = '';
  table.horizontalAlignment = tableClass?.horizontalAlignment || Word.Alignment.centered;
  table.verticalAlignment = Word.VerticalAlignment.center;
  table.getCell(0, 0).columnWidth = tableClass?.firstColWidth || JW_WORD_TABLE_FIRST_COL_WIDTH;
};

const updateWordTableClass = (table: Word.Table, rowCount: number, tableClass?: JwWordTableClass) => {
  table.load(['font', 'shadingColor']);
  if (tableClass?.firstColHorizontalAlignment !== undefined) {
    Array(rowCount)
      .fill(null)
      .forEach((_, i) => (table.getCell(i, 0).horizontalAlignment = tableClass?.firstColHorizontalAlignment as JwWordAlignment));
  }
  if (tableClass?.firstColBackgroundColor !== undefined) {
    Array(rowCount)
      .fill(null)
      .forEach((_, i) => {
        if (i === 0) {
          return;
        }
        table.getCell(i, 0).shadingColor = tableClass?.firstColBackgroundColor as JwWordTableColor;
      });
  }
  if (tableClass?.firstColBold !== undefined) {
    Array(rowCount)
      .fill(null)
      .forEach((_, i) => (table.getCell(i, 0).body.font.bold = tableClass?.firstColBold as boolean));
  }
  if (tableClass?.firstColColor !== undefined) {
    Array(rowCount)
      .fill(null)
      .forEach((_, i) => (table.getCell(i, 0).body.font.color = tableClass?.firstColColor as JwWordTableColor));
  }
};

const updateWordTableHeaderStyle = (rowHeader: Word.TableRow, headerRowClass?: JwTableRowClass[]) => {
  rowHeader.load(['font', 'shadingColor']);
  rowHeader.font.bold = true;
  rowHeader.font.color = JwWordTableColor.White;
  if (headerRowClass?.length) {
    rowHeader.shadingColor = getPreviewTableShadingColor(headerRowClass);
  } else {
    rowHeader.shadingColor = JwWordTableColor.Black;
  }
};

const getPreviewTableFontColor = (styleClass: JwTableRowClass[] | undefined): JwWordTableColor => {
  if (styleClass?.includes(JwTableRowClass.TableRowColorWhite)) {
    return JwWordTableColor.White;
  }
  return JwWordTableColor.Black;
};

const getPreviewTableShadingColor = (styleClass: JwTableRowClass[] | undefined): JwWordTableColor => {
  if (styleClass?.includes(JwTableRowClass.TableDivider)) {
    return JwWordTableColor.TableDivider;
  }
  if (styleClass?.includes(JwTableRowClass.TableSubCategory)) {
    return JwWordTableColor.TableSubCategory;
  }
  if (styleClass?.includes(JwTableRowClass.TableCategory)) {
    return JwWordTableColor.TableCategory;
  }
  if (styleClass?.includes(JwTableRowClass.TableSection)) {
    return JwWordTableColor.TableSection;
  }
  if (styleClass?.includes(JwTableRowClass.TableHighlightedRow)) {
    return JwWordTableColor.TableHighlightedRow;
  }
  if (styleClass?.includes(JwTableRowClass.TableFooter)) {
    return JwWordTableColor.TableFooter;
  }
  return JwWordTableColor.White;
};

const updateWordTableRowStyle = (wordTableRows: Word.TableRow[], generatedContentDatas: GeneratedContentData[]) => {
  wordTableRows.forEach((row, index) => {
    row.load(['font', 'shadingColor']);
    if (generatedContentDatas[index].styleClass?.includes(JwTableRowClass.TableHeading)) {
      row.font.bold = true;
    }
    row.font.color = getPreviewTableFontColor(generatedContentDatas[index].styleClass);
    row.shadingColor = getPreviewTableShadingColor(generatedContentDatas[index].styleClass);
  });
};

const jwFormatCurrency = (value: number, locale: string, noDigits = true) => {
  return noDigits ? formatCurrency(value, locale, '$', 'USD', '1.0-0') : formatCurrency(value, locale, '$', 'USD');
};

const formatCellValue = (row: GeneratedContentData, value: JwTableCellContent, locale: string): string => {
  if (value === null || value === undefined) {
    return '';
  }
  if (!_.isNumber(value)) {
    return value;
  }
  if (row.isPercentage) {
    return `${formatNumber(value, locale, '1.2-2')}%`;
  }
  const expressionAbsoluteValue = Math.abs(value);
  let formattedValue = '';
  if (row.isCurrency) {
    if (row.noDigits) {
      formattedValue = jwFormatCurrency(expressionAbsoluteValue, locale);
    } else if (row.noDigitsRounded) {
      const expressionAbsoluteRoundedValue = Math.round(expressionAbsoluteValue);
      formattedValue = jwFormatCurrency(expressionAbsoluteRoundedValue, locale);
    } else if (row.withDigits) {
      formattedValue = jwFormatCurrency(expressionAbsoluteValue, locale, false);
    }
  } else {
    formattedValue = formatNumber(expressionAbsoluteValue, locale, '1.0-0');
  }
  return +value >= 0 ? formattedValue : `(${formattedValue})`;
};

const getTableHeaderValue = (tableTitle: string, columnTitles: string[]) => {
  return [tableTitle, ...columnTitles];
};

const getTableRowValues = (generatedContentDatas: GeneratedContentData[], locale: string): string[][] => {
  return generatedContentDatas.map((row) => {
    const rowValues = (row.values ?? []).map((value) => formatCellValue(row, value, locale));
    return [row.label, ...rowValues];
  });
};

export const jwWordMsOfficeTableInsertContentControl = async (
  context: Word.RequestContext,
  contentControl: Word.ContentControl,
  locale: string | null,
  { tableTitle, columnTitles, generatedContentData: generatedContentDatas, headerRowClass, tableClass, insertLocation = 'After' }: JwWordTableInsertData,
): Promise<void> => {
  const generatedContentDatasFiltered = generatedContentDatas.filter((row) => !row.hide);
  const rowCount = generatedContentDatasFiltered.length + 1;
  const columnCount = columnTitles.length + 1;
  const tableData = [getTableHeaderValue(tableTitle, columnTitles), ...getTableRowValues(generatedContentDatasFiltered, locale ?? DEFAULT_LOCALE)];
  const table = contentControl.insertTable(rowCount, columnCount, contentControlLocation[insertLocation], tableData);
  updateWordTableStyle(table, tableClass);
  updateWordTableHeaderStyle(table.rows.getFirst(), headerRowClass);
  table.rows.load(['items']);
  await context.sync();
  updateWordTableRowStyle(table.rows.items.slice(1), generatedContentDatasFiltered);
  updateWordTableClass(table, rowCount, tableClass);
  table.autoFitWindow();
  await context.sync();
};
