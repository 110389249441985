<div>
  <form [formGroup]="formGroup">
    <span class="p-input-icon-right w-full">
      @if (loadingSuggestions) {
        <i class="pi pi-spin pi-spinner"></i>
      }
      @if (value && !loadingSuggestions && JWFProjectUrl()) {
        <i class="flex">
          <i
            class="pi pi-sync mr-2 text-primary cursor-pointer"
            (click)="refreshBPProject()"
          ></i>
          <a [href]="JWFProjectUrl()" target="_blank" class="ml-1">
            <i class="pi pi-folder-open text-primary custom-pi-folder-size"></i>
          </a>
        </i>
      }
      <input
        #searchInput
        class="w-full"
        type="text"
        pInputText
        placeholder="Project Title"
        formControlName="searchTerm"
        (click)="searchInputOnClick()"
        (blur)="searchInputOnBlur()"
        (keydown)="onKeyDown($event.key)"
      />
    </span>
    <p-overlayPanel #op styleClass="overflow-auto m-0">
      <ng-template pTemplate="content">
        @if (businessPlanProjects.length) {
          <div>
            @for (bp of businessPlanProjects; track bp.id; let isLast = $last) {
              <div
                class="grid grid-nogutter bp-suggestion-row cursor-pointer h-4rem"
                [class.border-bottom]="!isLast"
                (click)="selectBPProject(bp)"
              >
                <div
                  class="col-5 bp-suggestions-label overflow-hidden px-3 my-3"
                >
                  {{ bp.lastBPCompanyName }}
                </div>
                <div
                  class="col-5 bp-suggestions-label overflow-hidden px-2 my-3"
                >
                  {{ bp.lastBPVersionApplicantName || '-' }}
                </div>
                <div class="col-2 bp-suggestions-label overflow-hidden my-3">
                  {{ bp.visaType }}
                </div>
              </div>
            }
          </div>
        } @else {
          <div
            class="grid grid-nogutter row align-items-center cursor-pointer h-4rem"
          >
            <div class="col-12 bp-suggestions-label overflow-hidden px-3 my-3">
              No results found
            </div>
          </div>
        }
      </ng-template>
    </p-overlayPanel>
  </form>
</div>
