import { GeneratedContentData, GeneratedContentPersonnel, GeneratedContentPersonnelHiringPlan, JwTableRowClass } from '@joorney/computation-shared-frontend-sections-domain';
import { noDigitsCurrencyTransform } from '@joorney/utils-shared-frontend-ng-value-format-utils';
import _ from 'lodash';

const NUMBERS_IN_FULL: string[] = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];

export const transformPersonnelPlanNbEmployeeData = (data: GeneratedContentPersonnel): GeneratedContentData[] => {
  const datasource: GeneratedContentData[] = (data.positions?.position ?? [])
    .map((p) => ({
      label: p['@name'],
      values: (p.year ?? []).map((y) => y.numberOfEmployees),
      isHeading: false,
    }))
    .filter(({ values }) => values.some((v) => v !== 0));
  // Set total
  datasource.push({
    label: 'Total Employees',
    values: (data.total?.year ?? []).map((y) => y.totalNumberOfEmployees),
    isHeading: true,
    styleClass: [JwTableRowClass.TableHeading, JwTableRowClass.TableFooter],
  });
  return datasource;
};

export const transformPersonnelPlanDesignatedSalaryData = (data: GeneratedContentPersonnel): GeneratedContentData[] => {
  return (data.positions?.position ?? []).map((p) => ({
    label: p['@name'],
    values: (p.year ?? []).map((y) => y.designatedSalaryPerPosition),
    isHeading: false,
    isCurrency: true,
    noDigits: true,
  }));
};
export const transformPersonnelPlanData = (data: GeneratedContentPersonnel): GeneratedContentData[] => {
  const datasource: GeneratedContentData[] = (data.positions?.position ?? []).map((p) => ({
    label: p['@name'],
    values: (p.year ?? []).map((y) => y.payrollExpenses),
    isHeading: false,
    isCurrency: true,
    noDigits: true,
  }));

  // Set total Number of Employees
  datasource.push({
    label: 'Total Employees',
    values: (data.total?.year ?? []).map((y) => y.totalNumberOfEmployees),
    isHeading: true,
    styleClass: [JwTableRowClass.TableHeading, JwTableRowClass.TableFooter],
    isCurrency: false,
  });

  // Set total Payroll of Expenses
  datasource.push({
    label: 'Total Payroll Expenses',
    values: (data.total?.year ?? []).map((y) => y.totalPayrollExpenses),
    isHeading: true,
    styleClass: [JwTableRowClass.TableHeading, JwTableRowClass.TableFooter],
    isCurrency: true,
    noDigits: true,
  });
  return datasource;
};

export const transformPersonnelSummaryData = (data: GeneratedContentPersonnel, yearLabels: string[]): GeneratedContentData[] => {
  const alreadyHiredEmployeesValues = getHiringPlanValue(data.hiringPlan, -1);
  const alreadyHiredEmployees = {
    label: 'Already Hired',
    // -1 because the getHiringPlanValue function expects the index to be 0-based to match the yearLabels by doing index + 1.
    // The alreadyHiredEmployees is year[0] in the hiring plan.
    // Data for "Year 1" is year[1] in the hiring plan whereas the yearLabels is year[0].
    values: [alreadyHiredEmployeesValues],
    isHeading: false,
    isCurrency: true,
    noDigits: true,
    hide: _.isEmpty(alreadyHiredEmployeesValues),
  };
  const employeesPerYear = yearLabels.map((label, index) => ({
    label,
    values: [getHiringPlanValue(data.hiringPlan, index)],
    isHeading: false,
    isCurrency: true,
    noDigits: true,
  }));
  return _.dropRightWhile([alreadyHiredEmployees, ...employeesPerYear], (item) => item.values[0]?.trim() === '');
};

export const transformPersonnelSummaryText = (data: GeneratedContentPersonnel, yearLabels: string[], locale: string) => {
  const firstYear = yearLabels[0];
  const firstYearExpenses = data.total?.year?.[0].totalPayrollExpenses ?? 0;
  const lastYear = yearLabels[yearLabels.length - 1];
  const lastYearEmployees = data.total?.year?.[data.total?.year.length - 1].totalNumberOfEmployees ?? 0;
  const lastYearExpenses = data.total?.year?.[data.total?.year.length - 1].totalPayrollExpenses ?? 0;
  const firstYearExpensesFormatted = noDigitsCurrencyTransform(firstYearExpenses, locale);
  const lastYearExpensesFormatted = noDigitsCurrencyTransform(lastYearExpenses, locale);

  return `The Company will reach a total number of ${getNumberOfEmployees(lastYearEmployees)} employees in ${lastYear}. The Company expects its payroll expenses to increase from ${firstYearExpensesFormatted} in ${firstYear} to ${lastYearExpensesFormatted} in ${lastYear}.`;
};

const getNumberOfEmployees = (numberOfEmployees: number): number | string => {
  if (numberOfEmployees < 10) {
    return NUMBERS_IN_FULL[numberOfEmployees];
  }
  return numberOfEmployees;
};

const getHiringPlanValue = (hiringPlan: GeneratedContentPersonnelHiringPlan | undefined, index: number): string | undefined => {
  if (!hiringPlan?.year?.[index + 1]?.event || !Array.isArray(hiringPlan.year[index + 1].event)) {
    return '';
  }
  return hiringPlan.year[index + 1].event?.map((val) => `• ${val}`).join('\n');
};
