import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BusinessPlanDataAccessService } from '@joorney/business-plan-shared-frontend-business-plan-data-access';
import { GeneratedContentCashFlow } from '@joorney/computation-shared-frontend-sections-domain';
import { environment } from '@joorney/shell-shared-frontend-environment-data-access';
import {
  BusinessPlanYearLabel,
  employeeFeatureControllerGetListEmployeesInPosition,
  Enums,
  positionFeatureControllerGetPositionList,
} from '@joorney/shell-shared-jwriter-core-api-data-access';
import { throwErrorOnInvalidValue } from '@joorney/utils-shared-rxjs-utils';
import { combineLatest, from, map, Observable } from 'rxjs';
import { GeneratedContent } from './generated-computed-data.interfaces';

type GetYearLabelsParams = { accountingPeriod: number; yearLabel: Enums['BusinessPlanYearLabel']; startingYear: number | null };

export const getYearLabels = (businessPlan: GetYearLabelsParams): string[] => {
  const { accountingPeriod, yearLabel } = businessPlan;
  const startingYear = businessPlan.startingYear ?? 0;
  const accountingTable = Array(accountingPeriod).fill(null);

  if (yearLabel === BusinessPlanYearLabel.FIXED) {
    return accountingTable.map((_, i) => `${startingYear + i}`);
  }

  if (yearLabel === BusinessPlanYearLabel.FIXED_SPLIT) {
    return accountingTable.map((_, i) => `${startingYear + i}/${startingYear + i + 1}`);
  }

  return accountingTable.map((_, i) => `Year ${i + 1}`);
};

@Injectable({
  providedIn: 'root',
})
export class GeneratedComputedDataService {
  constructor(
    private httpClient: HttpClient,
    private businessPlanDataAccessService: BusinessPlanDataAccessService,
  ) {}

  getData(bpId: number): Observable<GeneratedContent> {
    return this.httpClient.get<GeneratedContent>(`${environment.apiUrlLegacy}/bp/${bpId}/generate/data`, { params: { type: 'json' } });
  }

  getCashFlowData(bpId: number): Observable<GeneratedContentCashFlow> {
    return this.httpClient.get<GeneratedContentCashFlow>(`${environment.apiUrlLegacy}/bp/${bpId}/cash-flow`);
  }

  getTableData(businessPlanId: number) {
    const data$ = this.getData(businessPlanId);
    const businessPlan$ = this.businessPlanDataAccessService.getBusinessPlanById(businessPlanId);
    return combineLatest({ businessPlan: businessPlan$, generatedContent: data$ }).pipe(
      map(({ generatedContent, businessPlan }) => ({
        generatedContent,
        businessPlan,
        yearLabels: getYearLabels(businessPlan),
      })),
    );
  }

  getCashFlowTableData(businessPlanId: number) {
    const data$ = this.getCashFlowData(businessPlanId);
    const businessPlan$ = this.businessPlanDataAccessService.getBusinessPlanById(businessPlanId);
    return combineLatest({ businessPlan: businessPlan$, generatedContent: data$ }).pipe(
      map(({ generatedContent, businessPlan }) => ({
        generatedContent,
        yearLabels: getYearLabels(businessPlan),
      })),
    );
  }

  getPersonnelPlanPositionsData(bpId: number) {
    return from(positionFeatureControllerGetPositionList({ authorization: '', bpId })).pipe(throwErrorOnInvalidValue());
  }

  getEmployeesData(bpId: number, positionId: number) {
    return from(employeeFeatureControllerGetListEmployeesInPosition({ authorization: '', bpId: bpId, positionId })).pipe(throwErrorOnInvalidValue());
  }
}
