import { StartupFundingSubEventType } from '@joorney/startup-funding-shared-frontend-startup-funding-domain';

interface FundingOriginLabelData {
  type: string | null;
  interestRate?: number | null;
  origin?: string | null;
}

export const getFundingOriginLabel = (funding: FundingOriginLabelData) =>
  // Use lower case for comparison. PHP returns type in capital case whereas core-api uses lower case. eg: 'Loan' vs 'loan'
  funding.type?.toLowerCase() === `${StartupFundingSubEventType.Loan}` ? `Loan at ${funding.interestRate || 0}%` : funding.origin ?? '';
