import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { environment } from '@joorney/shell-shared-frontend-environment-data-access';

@Injectable()
export class GoogleTagManagerFeatureService {
  private isAppended = false;

  constructor(@Inject(DOCUMENT) private doc: Document) {}

  appendGoogleTagManagerScript(): void {
    const googleTagManagerId = environment.apiKeys.googleTagManager;
    if (this.isAppended || !googleTagManagerId) {
      return;
    }
    const dataLayerScript = this.doc.createElement('script');
    dataLayerScript.innerHTML = 'dataLayer = [];';
    const script = this.doc.createElement('script');
    script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${googleTagManagerId}');
    `;
    const noscript = this.doc.createElement('noscript');
    const iframe = this.doc.createElement('iframe');
    iframe.height = '0';
    iframe.width = '0';
    iframe.src = `https://www.googletagmanager.com/ns.html?id=${googleTagManagerId}`;
    noscript.appendChild(iframe);
    const headElement = this.doc.getElementsByTagName('head')[0];
    const bodyElement = this.doc.getElementsByTagName('body')[0];
    headElement.appendChild(dataLayerScript);
    headElement.appendChild(script);
    bodyElement.insertBefore(noscript, bodyElement.firstChild);

    this.isAppended = true;
  }
}
