import { BaseSalaryData } from './salary.interface';

export interface GeneratedContentFeasibilityAnalysis {
  positions: GeneratedContentFeasibilityAnalysisPosition[];
  expenses: GeneratedContentFeasibilityAnalysisExpense[];
  totalSales: number[];
  totalMarketingAndSalesExpenses: number[];
  totalMarketingAndSalesExpensesAsPercentOfSales: number[];
  positionsDismissed: GeneratedContentFeasibilityAnalysisPosition[];
  expensesDismissed: GeneratedContentFeasibilityAnalysisExpense[];
}

interface GeneratedContentFeasibilityAnalysisPosition {
  id: number;
  type: string;
  name: string;
  namePlural: string;
  fulltime: true;
  baseSalaryData: BaseSalaryData;
  years: PositionYear[];
  hasEmployeeCustomized: boolean;
  nbEmployeeFormula: NbEmployeeFormula;
}

interface GeneratedContentFeasibilityAnalysisExpense {
  id: number;
  name: string;
  type: string;
  linked: {
    to: string | null;
  };
  typeCustomValue: string;
  annualIncreaseRate: number;
  annualCost: number;
  annualCostFormula: string;
  data: GlobalCostData[];
}

interface PositionYear {
  employeeNumber: number;
  payrollExpenses: number;
}

type NbEmployeeFormula = Record<string, string | null>;

interface GlobalCostData {
  formula: string;
  value: number;
}
