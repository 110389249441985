import { NgModule } from '@angular/core';
import { BusinessPlanCompareVisaTypeModalFeatureService } from '@joorney/business-plan-jwriter-financial-business-plan-compare-visa-type-modal-feature';
import { PayrollTaxesFormFeatureService } from '@joorney/business-plan-jwriter-financial-payroll-taxes-form-feature';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as contextEffects from './context.effects';
import { contextFeature } from './context.reducer';

@NgModule({
  imports: [StoreModule.forFeature(contextFeature), EffectsModule.forFeature([contextEffects])],
  providers: [BusinessPlanCompareVisaTypeModalFeatureService, PayrollTaxesFormFeatureService],
})
export class ContextStoreModule {}
