import { JwWordBusinessPlanTable } from '@joorney/ms-office-jwriter-word-sections-domain';
import { Observable, from } from 'rxjs';
import { JwWordBreakLineInsertData, jwWordMsOfficeBreakLineInsertContentControl } from './msoffice-break-line.utils';
import { JwWordChartInsertData, JwWordOrgChartInsertData, jwWordMsOfficeChartInsertContentControl, jwWordMsOfficeOrgChartInsertContentControl } from './msoffice-chart.utils';
import { JwWordTableInsertData, jwWordMsOfficeTableInsertContentControl } from './msoffice-table.utils';
import { JwWordTextInsertData, jwWordMsOfficeTextInsertContentControl } from './msoffice-text.utils';

export type JwWordMsOfficeContentControlEventFunction = (contentControlIds: number[]) => void;
export type JwWordInsertContents = JwWordChartInsertData | JwWordTableInsertData | JwWordTextInsertData | JwWordBreakLineInsertData | JwWordOrgChartInsertData;

export const contentControlLocation: Record<'Before' | 'After', 'Start' | 'End'> = {
  Before: 'Start',
  After: 'End',
};

export const jwWordMsOfficeContentControlAttachListener = async (
  onInitialized: JwWordMsOfficeContentControlEventFunction,
  onAdded: JwWordMsOfficeContentControlEventFunction,
  onDeleted: JwWordMsOfficeContentControlEventFunction,
  onEntered: JwWordMsOfficeContentControlEventFunction,
  onExited: JwWordMsOfficeContentControlEventFunction,
) => {
  await Word.run(async (context) => {
    const contentControls = context.document.contentControls;
    contentControls.load('items');
    await context.sync();
    const jwContentControls = contentControls.items.filter(({ tag }) => jwWordMsOfficeContentControlIsAJwwControl(tag));
    onInitialized(jwContentControls.map(({ id }) => id));
    console.log('jwWordMsOfficeContentControlAttachListener', jwContentControls);

    const attachEventHandler = (item: Word.ContentControl) => {
      if (item.onDataChanged) {
        item.onEntered.add(async (event) => {
          onEntered(event.ids);
          return Promise.resolve();
        });
        item.onExited.add(async (event) => {
          onExited(event.ids);
          return Promise.resolve();
        });
        item.onDeleted.add(async (event) => {
          onDeleted(event.ids);
          return Promise.resolve();
        });
        item.track();
      } else {
        console.warn('jwWordMsOfficeContentControlAttachListener No event handler');
      }
    };

    context.document.onContentControlAdded.add(async ({ ids }) => {
      await Word.run(async (contentControlAddedContext) => {
        const item = contentControlAddedContext.document.contentControls.getById(ids[0]);
        item.load('tag');
        await contentControlAddedContext.sync();
        if (!jwWordMsOfficeContentControlIsAJwwControl(item.tag)) {
          return;
        }
        onAdded(ids);
        attachEventHandler(item);
      });
    });

    jwContentControls.forEach((item) => {
      attachEventHandler(item);
    });
  });
};

const removeDefaultParagraph = async (
  contentControl: Word.ContentControl,
  context: Word.RequestContext,
  elements: JwWordInsertContents[],
  defaultEmptyParagraph: Word.Paragraph,
) => {
  contentControl.paragraphs.load('items');
  await context.sync();
  const canRemove = contentControl.paragraphs.items.length > 1;
  const hasNoChart = !elements.some((el) => el.type === 'chart');
  const hasExtraParagraphs = elements.length < contentControl.paragraphs.items.length;
  if (canRemove && hasExtraParagraphs && hasNoChart) {
    defaultEmptyParagraph.delete();
  }
};

export const jwWordMsOfficeHasParentContentControl = () =>
  from(
    Word.run(async (context: Word.RequestContext) => {
      const parentContentControl = context.document.getSelection().parentContentControlOrNullObject;
      await context.sync();
      return !parentContentControl.isNullObject;
    }),
  );

export const jwWordMsOfficeContentControlInsert = (
  tag: string,
  elements: JwWordInsertContents[],
  locale: string | null,
  styleNumber: number,
  currentRange?: Word.Range,
): Observable<void> => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const promise = Word.run(async (context: Word.RequestContext) => {
    const originalRange = currentRange?.select() ?? context.document.getSelection();
    const contentControl = originalRange.insertContentControl();
    // insertContentControl creates a CC with an empty paragraph, which is not needed
    // get reference before inserting, delay delete until custom contents are inserted to avoid a runtime error
    const defaultEmptyParagraph = contentControl.paragraphs.getFirst();
    contentControl.title = 'CustomJoorneyCC';
    contentControl.tag = tag;
    contentControl.font.name = 'Calibri';
    jwWordMsOfficeContentControlApplyStyle(contentControl, styleNumber);
    await context.sync();
    for (const element of elements) {
      switch (element.type) {
        case 'chart':
          await jwWordMsOfficeChartInsertContentControl(context, contentControl, element);
          break;
        case 'break-line':
          await jwWordMsOfficeBreakLineInsertContentControl(context, contentControl);
          break;
        case 'org-chart':
          await jwWordMsOfficeOrgChartInsertContentControl(context, contentControl, element);
          break;
        case 'text':
          await jwWordMsOfficeTextInsertContentControl(context, contentControl, element);
          break;
        case 'table':
          await jwWordMsOfficeTableInsertContentControl(context, contentControl, locale, element);
          break;
        default:
          break;
      }
    }
    await removeDefaultParagraph(contentControl, context, elements, defaultEmptyParagraph);
    contentControl.getRange().font.name = 'Calibri';
    await context.sync();
  });
  return from(promise);
};

export const jwWordMsOfficeContentControlUpdate = async (id: number, elements: JwWordInsertContents[], locale: string | null, newTag: string, styleNumber: number) => {
  await Word.run(async (context: Word.RequestContext) => {
    // Content controls that contain OOXML are not well supported by Office Online
    // Instead of updating, they have to be deleted and inserted at the same location
    const range = context.document.contentControls.getById(id).getRange();
    range.clear();
    // Ensure that the new content control will be inserted in the same location by placing the cursor in the removed content control
    range.select();
    await context.sync();
    void jwWordMsOfficeContentControlInsert(newTag, elements, locale, styleNumber, range);
    await context.sync();
  });
};

type WordMsOfficeContentControlData = { id: number; tag: string; cannotEdit: boolean };
const wordMsOfficeGetAllContentControls = (filterCallback: (contentControl: Word.ContentControl) => boolean = () => true): Promise<WordMsOfficeContentControlData[]> =>
  Word.run(async (context) => {
    const contentControls = context.document.contentControls;
    contentControls.load(['id', 'tag', 'cannotEdit']);
    await context.sync();
    return contentControls.items.filter(filterCallback).map(({ id, tag, cannotEdit }) => ({ id, tag, cannotEdit }));
  });

export const jwWordMsOfficeContentControlGetAllUpdatable = () =>
  wordMsOfficeGetAllContentControls((contentControl) => jwWordMsOfficeContentControlIsAJwwControl(contentControl.tag));

export const jwWordMsOfficeContentControlGetAll = wordMsOfficeGetAllContentControls;

export const jwWordMsOfficeContentControlGetById = async (id: number) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const result = await Word.run(async (context) => {
    const contentControl = context.document.contentControls.getById(id);
    contentControl.load(['tag', 'cannotEdit']);
    await context.sync();
    const { tag, cannotEdit } = contentControl;
    return { id, tag, cannotEdit };
  });
  return result;
};

export const jwWordMsOfficeContentControlSetEditable = async (id: number | null, editable: boolean) => {
  if (!id) {
    throw new Error('Content control id is required');
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const result = await Word.run(async (context) => {
    const contentControl = context.document.contentControls.getById(id);
    contentControl.load(['cannotEdit']);
    await context.sync();
    contentControl.cannotEdit = !editable;
    await context.sync();
    return editable;
  });
  return result;
};

export const jwWordMsOfficeContentControlUpdateStyle = async (id: number | null, styleNumber: number) => {
  if (!id) {
    throw new Error('Content control id is required');
  }
  await Word.run(async (context) => {
    const contentControl = context.document.contentControls.getById(id);
    await context.sync();
    jwWordMsOfficeContentControlApplyStyle(contentControl, styleNumber);
    await context.sync();
  });
};

export const DEFAULT_CONTENT_CONTROL_STYLE = 3;
const jwWordMsOfficeContentControlApplyStyle = (contentControl: Word.ContentControl, styleNumber: number) => {
  styleNumber = [1, 2, 3].includes(styleNumber) ? styleNumber : DEFAULT_CONTENT_CONTROL_STYLE;
  switch (styleNumber) {
    case 1:
      contentControl.appearance = 'BoundingBox';
      contentControl.color = 'red';
      break;
    case 2:
      contentControl.appearance = 'Tags';
      contentControl.color = 'blue';
      break;
    case 3:
      contentControl.appearance = 'Hidden';
      contentControl.color = 'green';
      break;
  }
};

export const jwWordMsOfficeContentControlCreateTag = (bpId: number, tableTag: JwWordBusinessPlanTable, yearIndex: number | null = null) => {
  const uniqId = Date.now();
  return `JWW_${uniqId}_${bpId}_${tableTag}${yearIndex === null ? '' : `/${yearIndex}`}`;
};

export const jwWordMsOfficeContentControlIsAJwwControl = (tag: string) => {
  return tag.startsWith('JWW_');
};

export const jwWordMsOfficeContentControlExtractTagInfos = (tag: string) => {
  const [, , bpId, ...tableData] = tag.split('_');
  const [tableTag, yearIndex = null] = tableData.join('_').split('/');
  return {
    bpId: parseInt(bpId, 10),
    yearIndex: yearIndex ? parseInt(yearIndex, 10) : null,
    tableTag: tableTag as JwWordBusinessPlanTable,
  };
};
