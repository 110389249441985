import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { JW_WORD_ROUTING_DICTIONARY } from '@joorney/shell-jwriter-word-main-routing-domain';
import { AuthService } from '@joorney/users-shared-frontend-authentication-feature';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs';
import { tabNavActions } from './tab-nav.actions';

export const goToDebugPage$ = createEffect(
  (action$ = inject(Actions), router = inject(Router)) => {
    return action$.pipe(
      ofType(tabNavActions.goToDebugPageClicked),
      map(() => void router.navigate([JW_WORD_ROUTING_DICTIONARY.debug.url])),
    );
  },
  { functional: true, dispatch: false },
);

export const goToPocPage$ = createEffect(
  (action$ = inject(Actions), router = inject(Router)) => {
    return action$.pipe(
      ofType(tabNavActions.goToPocPageClicked),
      map(() => void router.navigate([JW_WORD_ROUTING_DICTIONARY.poc.url])),
    );
  },
  { functional: true, dispatch: false },
);

export const signOut$ = createEffect(
  (action$ = inject(Actions), authService = inject(AuthService)) => {
    return action$.pipe(
      ofType(tabNavActions.signOutClicked),
      tap(() => authService.logout()),
    );
  },
  { functional: true, dispatch: false },
);
