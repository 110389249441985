import { WrikeProjectGetDTO } from '@joorney/shell-shared-jdata-wrike-api-data-access';
import { BusinessPlanApplicantDTO, BusinessPlanByIdDTO, BusinessPlanFeatureConfigurationDTO, BusinessPlanVersionsDTO } from '@joorney/shell-shared-jwriter-core-api-data-access';
import { createFeature, createReducer, on } from '@ngrx/store';
import { produce } from 'immer';
import { CONTEXT_FEATURE_KEY, contextActions } from './context.actions';

export type CurrentWrikeProject = WrikeProjectGetDTO;

export interface ContextState {
  currentBusinessPlan: BusinessPlanByIdDTO | null;
  currentBusinessPlanYearLabels: string[];
  businessPlanVersions: BusinessPlanVersionsDTO[];
  businessplanApplicants: BusinessPlanApplicantDTO[];
  featureConfiguration: BusinessPlanFeatureConfigurationDTO | null;
  lastBPVersionId: number | undefined;
  lastBPVersionName: string | undefined;
  wrikeProject: CurrentWrikeProject | null;
}

const initialState: ContextState = {
  currentBusinessPlan: null,
  currentBusinessPlanYearLabels: [],
  businessPlanVersions: [],
  businessplanApplicants: [],
  featureConfiguration: null,
  lastBPVersionId: undefined,
  lastBPVersionName: undefined,
  wrikeProject: null,
};

export const contextFeature = createFeature({
  name: CONTEXT_FEATURE_KEY,
  reducer: createReducer(
    initialState,
    on(contextActions.initCurrentBusinessPlanFinished, (state, { businessPlan }) =>
      produce(state, (draft) => {
        const { lastBPVersionId, lastBPVersionName, ...currentBusinessPlan } = businessPlan;
        draft.currentBusinessPlan = currentBusinessPlan;
        draft.lastBPVersionId = lastBPVersionId;
        draft.lastBPVersionName = lastBPVersionName;
        draft.wrikeProject = null;
      }),
    ),
    on(contextActions.initBusinessPlanVersionsFinished, (state, { businessPlanVersions }) =>
      produce(state, (draft) => {
        draft.businessPlanVersions = businessPlanVersions;
      }),
    ),
    on(contextActions.initCurrentBusinessPlanFailed, () => initialState),
    on(contextActions.businessPlanLeft, () => initialState),
    on(contextActions.setCurrentBusinessPlanYearLabels, (state, { yearLabels }) =>
      produce(state, (draft) => {
        draft.currentBusinessPlanYearLabels = yearLabels;
      }),
    ),
    on(contextActions.addNewBusinessPlanVersion, (state, { businessPlanId, businessPlanSaveName, businessPlanSaveNote }) =>
      produce(state, (draft) => {
        draft.businessPlanVersions.push({ id: businessPlanId, saveName: businessPlanSaveName, saveNote: businessPlanSaveNote });
      }),
    ),
    on(contextActions.updateBusinessPlanVersion, (state, { businessPlanId, businessPlanSaveName, businessPlanSaveNote }) => {
      const bpVersionIndex = state.businessPlanVersions.findIndex((bp) => bp.id === businessPlanId);
      if (bpVersionIndex < 0) {
        return state;
      }
      return produce(state, (draft) => {
        if (draft.currentBusinessPlan?.id === businessPlanId) {
          draft.currentBusinessPlan = { ...draft.currentBusinessPlan, saveName: businessPlanSaveName, saveNote: businessPlanSaveNote };
        }
        draft.businessPlanVersions[bpVersionIndex] = { id: businessPlanId, saveName: businessPlanSaveName, saveNote: businessPlanSaveNote };
      });
    }),
    on(contextActions.businessPlanApplicantsReceived, (state, { applicants }) =>
      produce(state, (draft) => {
        draft.businessplanApplicants = applicants;
      }),
    ),
    on(contextActions.businessPlanFeatureConfigurationReceived, (state, { featureConfiguration }) =>
      produce(state, (draft) => {
        draft.featureConfiguration = featureConfiguration;
      }),
    ),
    on(contextActions.businessPlanWrikeProjectReceived, (state, { wrikeProject }) =>
      produce(state, (draft) => {
        draft.wrikeProject = wrikeProject;
      }),
    ),
    on(contextActions.currentBusinessPlanUnselected, () => initialState),
  ),
});
