import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { toastActions } from '@joorney/shell-shared-frontend-toast-store';
import { Store } from '@ngrx/store';
import { Observable, catchError } from 'rxjs';

@Injectable()
export class AuthUnauthorizedInterceptor {
  constructor(private store: Store) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
    // console.log('Interceptor', {token: request.headers.get('Authorization')});
    return next(request).pipe(
      catchError((err: unknown) => {
        if (err instanceof HttpErrorResponse && err.status === 401) {
          this.store.dispatch(toastActions.displayErrorMessage({ messageText: 'Unauthorized' }));
          console.error('AuthExpirationInterceptor ERROR Unauthorized', err.url, window.location.origin);
        }
        throw err;
      }),
    );
  }
}

export const authUnauthorizedInterceptor: HttpInterceptorFn = (request, next) => {
  return inject(AuthUnauthorizedInterceptor).intercept(request, next);
};
