export enum JwWordBusinessPlanTable {
  PersonnelPlanNumberOfEmployees = 'PERSONNEL_PLAN_NUMBER_OF_EMPLOYEES',
  PersonnelPlanDesignatedSalary = 'PERSONNEL_PLAN_DESIGNATED_SALARY',
  PersonnelPlanOrgChart = 'PERSONNEL_PLAN_ORG_CHART',
  PersonnelPlan = 'PERSONNEL_PLAN',
  PersonnelSummary = 'PERSONNEL_SUMMARY',
  ApplicantPosition = 'APPLICANT_POSITION',
  BreakEvenAnalysisTable = 'BREAK_EVEN_ANALYSIS_TABLE',
  BreakEvenAnalysisChart = 'BREAK_EVEN_ANALYSIS_CHART',
  SalesForecast = 'SALES_FORECAST',
  ProfitAndLoss = 'PROFIT_AND_LOSS',
  ProfitAndLossChart = 'PROFIT_AND_LOSS_CHART',
  CashFlowTable = 'CASH_FLOW_TABLE',
  BalanceSheetTable = 'BALANCE_SHEET_TABLE',
  CostBenefitAnalysis = 'COST_BENEFIT_ANALYSIS',
  FeasibilityAnalysis = 'FEASIBILITY_ANALYSIS',
  SourceOfFunds = 'SOURCE_OF_FUNDS',
  UseOfProceeds = 'USE_OF_PROCEEDS',
  CompaniesImpactOnEconomy = 'COMPANIES_IMPACT_ON_ECONOMY',
  Conclusion = 'CONCLUSION',
  ExecutiveSummary = 'EXECUTIVE_SUMMARY',
}

export enum JwWordBusinessPlanSectionName {
  PersonnelPlan = 'PERSONNEL_PLAN',
  BreakEvenAnalysis = 'BREAK_EVEN_ANALYSIS',
  SalesForecast = 'SALES_FORECAST',
  ProfitAndLoss = 'PROFIT_AND_LOSS',
  CashFlow = 'CASH_FLOW',
  BalanceSheet = 'BALANCE_SHEET',
  Feasibility = 'FEASIBILITY',
  SummaryOfInvestment = 'SUMMARY_OF_INVESTMENT',
  CompanyImpactOnEconomy = 'COMPANY_IMPACT_ON_ECONOMY',
  Conclusion = 'CONCLUSION',
  ExecutiveSummary = 'EXECUTIVE_SUMMARY',
}

export enum JwWordTableColor {
  White = '#ffffff',
  Black = '#000000',
  Gray = '#595959',
  TableFooter = '#808080',
  TableCategory = '#262626',
  TableSubCategory = '#404040',
  TableSection = '#d9d9d9',
  TableHighlightedRow = '#f2f2f2',
  TableDivider = '#c0c0c0',
}
