import { Injectable } from '@angular/core';
import { featureConfigurationFeatureControllerGetByBusinessPlanId } from '@joorney/shell-shared-jwriter-core-api-data-access';
import { throwErrorOnInvalidValue } from '@joorney/utils-shared-rxjs-utils';
import { from } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FeatureConfigurationDataAccessService {
  getByBusinessPlanId(bdIp: number) {
    return from(featureConfigurationFeatureControllerGetByBusinessPlanId({ bpId: bdIp })).pipe(throwErrorOnInvalidValue());
  }
}
