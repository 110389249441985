export enum StartupFundingEventType {
  Funding,
  Expense,
}

export enum StartupFundingEventTypeName {
  Funding = 'Funding',
  Expense = 'Expense',
}

export enum StartupFundingSubEventType {
  Loan = 'loan',
  Asset = 'asset',
  Cash = 'cash',
  LongTermAsset = 'longterm_asset',
  InitialExpense = 'initial_expense',
}
