import { formatCurrency, getCurrencySymbol, getLocaleCurrencyCode } from '@angular/common';

export const noDigitsCurrencyTransform = (value: number, locale: string) => {
  const code = getLocaleCurrencyCode(locale) ?? 'USD';
  const symbol = getCurrencySymbol(code, 'narrow', locale);
  return formatCurrency(value, locale, symbol, code, '1.0-0');
};

export const withDigitsCurrencyTransform = (value: number, locale: string) => {
  const code = getLocaleCurrencyCode(locale) ?? 'USD';
  const symbol = getCurrencySymbol(code, 'narrow', locale);
  return formatCurrency(value, locale, symbol, code);
};
