import { OpenAPI as JWriterCoreApiOpenAPI } from '@joorney/shell-shared-jwriter-core-api-data-access';
import { OpenAPI as JDataWrikeApiOpenAPI } from '@joorney/shell-shared-jdata-wrike-api-data-access';
import { OpenAPI as JWriterOrchestratorApiOpenAPI } from '@joorney/shell-shared-jwriter-orchestrator-api-data-access';
import { environment } from '@joorney/shell-shared-frontend-environment-data-access';

export const apiConfiguratorInit = () => {
  // Set the base server address using the environment variable.
  JWriterCoreApiOpenAPI.BASE = environment.coreApiUrl.replace(/\/api$/g, '');
  JDataWrikeApiOpenAPI.BASE = environment.wrikeApiUrl.replace(/\/api$/g, '');
  JWriterOrchestratorApiOpenAPI.BASE = environment.orchestratorApiUrl.replace(/\/api$/g, '');
};
