export enum BusinessPlanAction {
  NewBP = 'new-bp',
  SetupBP = 'setup-bp',
}

export enum BusinessPlanBasedType {
  Unit = 'Unit',
  Value = 'Value',
}

export enum FeatureConfigurationValue {
  BreakEvenAnalysis = 'breakEvenAnalysis',
  CostBenefitAnalysis = 'costBenefitAnalysis',
  FeasibilityAnalysis = 'feasibilityAnalysis',
  FixedDateView = 'fixedDateView',
  PersonnelTable = 'personnelTable',
  CashFlowTable = 'cashFlowTable',
}
