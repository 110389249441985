import { Enums } from '@joorney/shell-shared-jwriter-core-api-data-access';

export interface GeneratedContentSalesForecast {
  products?: GeneratedContentSalesForecastProducts;
  total?: GeneratedContentSalesForecastTotal;
  '@type'?: string;
}

interface GeneratedContentSalesForecastProducts {
  product?: GeneratedContentSalesForecastProduct[];
}

interface GeneratedContentSalesForecastProduct {
  '@name': string;
  '@perX'?: string;
  year?: GeneratedContentSalesForecastProductYear[];
  type: Enums['SalesForecastProductType'];
}

interface GeneratedContentSalesForecastProductYear {
  '@n'?: number;
  unitSales?: number;
  unitPrices?: number;
  sales?: number;
  directUnitCosts?: number;
  directCostOfSales?: number;
}

interface GeneratedContentSalesForecastTotal {
  year?: GeneratedContentSalesForecastTotalYear[];
}

interface GeneratedContentSalesForecastTotalYear {
  '@n'?: number;
  totalUnitSales?: number;
  totalSales?: number;
  subtotalDirectCostOfSales?: number;
  totalGrossMargin?: number;
  totalGrossMarginRate?: number;
}
