import {
  UsefulWebsitesCategoriesElementDTO,
  UsefulWebsitesCreateDTO,
  UsefulWebsitesCreatePinDTO,
  UsefulWebsitesElementDTO,
} from '@joorney/shell-shared-jwriter-core-api-data-access';
import { UsefulWebsitesUpdateData } from '@joorney/useful-websites-shared-frontend-useful-websites-data-access';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { UsefulWebsitesFormData } from './useful-websites-store.reducer';

export const USEFUL_WEBSITES_FEATURE_KEY = 'useful-websites';

export const usefulWebsitesActions = createActionGroup({
  source: USEFUL_WEBSITES_FEATURE_KEY,
  events: {
    dataInitialization: props<{ isMine?: boolean }>(),
    dataFinalize: emptyProps(),
    dataLoaded: props<{ data: UsefulWebsitesElementDTO[] }>(),
    categoriesLoaded: props<{ data: UsefulWebsitesCategoriesElementDTO[] }>(),
    tableRowClicked: props<{ data: UsefulWebsitesElementDTO }>(),
    addNewClicked: emptyProps(),
    saveNewClicked: props<{ data: UsefulWebsitesCreateDTO; reopen: boolean }>(),
    saveNewFinished: props<{ rowId: number | null }>(),
    saveNewFailed: emptyProps(),
    saveClicked: props<{ data: UsefulWebsitesUpdateData }>(),
    saveFinished: emptyProps(),
    saveFailed: emptyProps(),
    deleteClicked: props<{ id: number }>(),
    deleteConfirmed: props<{ id: number }>(),
    deleteFinished: emptyProps(),
    deleteFailed: emptyProps(),
    dataRefresh: emptyProps(),
    formDataUpdated: props<{ data: UsefulWebsitesFormData }>(),
    categoryFilterChanged: props<{ id: number }>(),
    pinUsefulWebsiteClicked: props<{ data: UsefulWebsitesCreatePinDTO }>(),
    unpinUsefulWebsiteClicked: props<{ id: number }>(),
    formDataFinalized: emptyProps,
  },
});
