import { JwWordBusinessPlanSectionName, JwWordBusinessPlanTable } from './business-plan.enum';

export interface JwWordTableSection {
  name: string;
  tag: JwWordBusinessPlanSectionName;
  tables: JwWordTable[];
  disabled?: boolean;
}

export interface JwWordTable {
  name: string;
  tag: JwWordBusinessPlanTable;
  imgUrls: string[];
  disabled?: boolean;
}
