import { OtherMarketingStrategiesPayload, SocialMediaPayload, WebsiteStatus } from '@joorney/marketing-jwriter-word-marketing-section-domain';
import { JwWordTextInsertData } from '@joorney/ms-office-jwriter-word-office-api-data-access';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const MARKETING_SECTION_FEATURE_KEY = 'marketing-section';

export const marketingSectionActions = createActionGroup({
  source: MARKETING_SECTION_FEATURE_KEY,
  events: {
    marketingSectionClicked: props<{ id: string }>(),
    marketingSectionReturnClicked: emptyProps(),
    marketingSectionInsertSocialMediaClicked: props<{ socialMedia: SocialMediaPayload[] }>(),
    marketingSectionInsertWebsiteClicked: props<{ websiteStatus: WebsiteStatus; websiteUrl: string }>(),
    marketingSectionInsertEventsClicked: props<{ isEngaged: boolean; events: string[] }>(),
    marketingSectionInsertReviewPlatformsClicked: props<{ reviewPlatforms: string[] }>(),
    marketingSectionInsertOtherMarketingStrategiesClicked: props<{ otherStrategies: OtherMarketingStrategiesPayload[] }>(),
    marketingSectionParagraphsGenerated: props<{ content: JwWordTextInsertData[] }>(),
  },
});
