import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { makeEnvironmentProviders } from '@angular/core';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalInterceptor, MsalService } from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { environment } from '@joorney/shell-shared-frontend-environment-data-access';
import { AuthenticationInterceptorFeatureService } from '@joorney/users-shared-frontend-authentication-interceptor-feature';
import { AuthService } from '../auth-service/auth.service';

const log: { [T in LogLevel]: (s: string) => void } = {
  [LogLevel.Error]: console.error,
  [LogLevel.Info]: console.info,
  [LogLevel.Verbose]: console.debug,
  [LogLevel.Warning]: console.warn,
  [LogLevel.Trace]: () => null,
};

export const provideAuth = (interactionType: InteractionType.Popup | InteractionType.Redirect) => {
  return makeEnvironmentProviders([
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useValue: new PublicClientApplication({
        auth: {
          clientId: environment.msal.clientId,
          authority: `https://login.microsoftonline.com/${environment.msal.tenantId}`,
          redirectUri: '/',
          postLogoutRedirectUri: '/',
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage,
        },
        system: {
          loggerOptions: {
            loggerCallback: (level: LogLevel, message: string): void => log[level](message),
            piiLoggingEnabled: environment.production ? false : true,
            logLevel: LogLevel.Error,
          },
        },
      }),
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useValue: {
        interactionType,
        authRequest: {
          scopes: [`${environment.msal.clientId}/${environment.msal.customScope}`],
        },
      },
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useValue: {
        interactionType,
        protectedResourceMap: new Map<string, Array<string>>()
          .set('https://graph.microsoft.com/v1.0/me', ['user.read'])
          .set(environment.coreApiUrl, [`${environment.msal.clientId}/${environment.msal.customScope}`])
          .set(environment.wrikeApiUrl, [`${environment.msal.clientId}/${environment.msal.customScope}`])
          .set(environment.apiUrlLegacy, [`${environment.msal.clientId}/${environment.msal.customScope}`]),
      },
    },
    MsalService,
    MsalBroadcastService,
    MsalGuard,
    AuthenticationInterceptorFeatureService,
    AuthService,
  ]);
};
