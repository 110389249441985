import { Injectable } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, of, tap } from 'rxjs';
import { ConfirmationDialogComponent, ConfirmationDialogData, ConfirmationDialogResult } from './confirmation-dialog.component';

@Injectable({ providedIn: 'root' })
export class ConfirmationDialogService {
  private ref: DynamicDialogRef | null = null;

  constructor(private readonly dialogService: DialogService) {}

  open$(message: string): Observable<ConfirmationDialogResult> {
    if (this.ref === null) {
      this.ref = this.dialogService.open(ConfirmationDialogComponent, {
        header: 'Confirmation',
        closable: false,
        modal: true,
        data: { message } as ConfirmationDialogData,
        dismissableMask: true,
      });
    }

    // navigation events happen too quickly for dialogService to clear its cache before opening a new dialog
    if (this.ref === null) {
      return of({ action: true });
    }

    return this.ref.onClose.pipe(tap(() => (this.ref = null)));
  }
}
