// Important: values with capital letter are used in the backend
// check: src/Service/InvestmentFundingService.php:503 => getTypeFunding (php back)
export enum FundingType {
  Asset = 'Asset',
  Funding = 'Cash',
  Loan = 'Loan',
}

// Important: values with capital letter are used in the backend
// check: src/Service/InvestmentFundingService.php => getTypeInvestment (php backend)
export enum InvestmentType {
  InitialExpense = 'Initial_expense',
  LongTermAsset = 'Longterm_asset',
}

export interface GeneratedContentFundingsAndInvestments {
  year: GeneratedContentYearFundingsAndInvestments[];
}

export interface GeneratedContentYearFundingsAndInvestments {
  '@n': number;
  fundings: {
    funding: GeneratedContentFunding[];
    totalFundingsAmount: number;
  };
  investments: {
    investment: GeneratedContentInvestment[];
    totalCumulatedDepreciation: number;
    totalDepreciation: number;
    totalInvestedAmount: number;
    totalLongTermAsset: number;
  };
}

export interface GeneratedContentFunding {
  amount: number;
  source: string | null;
  type: FundingType;
  depreciation?: number;
  termMonths?: number;
  interestRate?: number;
}

export interface GeneratedContentInvestment {
  amount: number;
  depreciation: number;
  description: string;
  type: InvestmentType;
}
