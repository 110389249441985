export interface GeneratedContentCashFlow {
  data: GeneratedContentCashFlowData;
}

export interface GeneratedContentCashFlowData {
  netIncome: number[];
  otherCurrentAssets: number[];
  accountReceivable: number[];
  longTermAssets: number[];
  longTermLiabilities: number[];
  paidInCapital: number[];
  otherCurrentLiabilities: number[];
  accountPayable: number[];
  dividends: number[];
  depreciation: number[];
  principalRepayment: number[];
  cashFromOperations: number[];
  cashFromInvesting: number[];
  cashFromFinancing: number[];
  beginningCashBalance: number[];
  endingCashBalance: number[];
}
