import { provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, enableProdMode, isDevMode } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withEnabledBlockingInitialNavigation, withHashLocation } from '@angular/router';
import { InteractionType } from '@azure/msal-browser';
import { BusinessPlanCompareVisaTypeModalFeatureService } from '@joorney/business-plan-jwriter-financial-business-plan-compare-visa-type-modal-feature';
import { PayrollTaxesFormFeatureService } from '@joorney/business-plan-jwriter-financial-payroll-taxes-form-feature';
import { contextEffects, contextFeature } from '@joorney/business-plan-shared-frontend-context-store';
import { dataSyncEffects, dataSyncFeature } from '@joorney/data-sync-jwriter-word-data-sync-store';
import { tabNavEffects, tabNavFeature } from '@joorney/layout-jwriter-word-tab-nav-store';
import { marketingSectionEffects, marketingSectionFeature } from '@joorney/marketing-jwriter-word-marketing-section-store';
import { JW_WORD_MAIN_ROUTING_ROUTES } from '@joorney/shell-jwriter-word-main-routing-feature';
import { apiConfiguratorInit } from '@joorney/shell-shared-frontend-api-configurator-utils';
import { environment } from '@joorney/shell-shared-frontend-environment-data-access';
import { GoogleTagManagerFeatureService } from '@joorney/shell-shared-frontend-google-tag-manager-feature';
import { provideToastStore } from '@joorney/shell-shared-frontend-toast-store';
import { $usageTimeEntryData, usageTimeAppPages } from '@joorney/usage-times-jwriter-word-usage-time-utils';
import { USAGE_TIME_APP_PAGES_DATA, USAGE_TIME_ENTRY_DATA, usageTimeEffects, usageTimeFeature } from '@joorney/usage-times-shared-frontend-usage-time-store';
import { UsefulWebsitesFormFeatureService } from '@joorney/useful-websites-jwriter-financial-useful-websites-form-feature';
import { usefulWebsitesEffects, usefulWebsitesFeature } from '@joorney/useful-websites-shared-frontend-useful-websites-store';
import { AuthUnauthorizedInterceptor, authUnauthorizedInterceptor, provideAuth } from '@joorney/users-shared-frontend-authentication-feature';
import { userFeature } from '@joorney/users-shared-frontend-user-store';
import { provideEffects } from '@ngrx/effects';
import { provideRouterStore, routerReducer } from '@ngrx/router-store';
import { provideState, provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { DialogService } from 'primeng/dynamicdialog';

if (environment.production) {
  enableProdMode();
}

apiConfiguratorInit();

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    AuthUnauthorizedInterceptor,
    provideHttpClient(withInterceptorsFromDi(), withInterceptors([authUnauthorizedInterceptor])),
    provideRouter(JW_WORD_MAIN_ROUTING_ROUTES, withEnabledBlockingInitialNavigation(), withHashLocation()), // withDebugTracing()
    provideStore({ router: routerReducer }),
    provideState(userFeature),
    provideState(contextFeature),
    provideState(usefulWebsitesFeature),
    provideState(marketingSectionFeature),
    provideState(dataSyncFeature),
    provideState(tabNavFeature),
    provideState(usageTimeFeature),
    provideEffects([contextEffects, usefulWebsitesEffects, marketingSectionEffects, dataSyncEffects, tabNavEffects, usageTimeEffects]),
    provideRouterStore(),
    provideStoreDevtools({
      maxAge: !isDevMode() ? 25 : 5000, // Retains last 25 states
      logOnly: !isDevMode(), // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
      trace: false, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
      traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
    }),
    provideToastStore(),
    provideAuth(InteractionType.Popup),
    DialogService,
    BusinessPlanCompareVisaTypeModalFeatureService,
    PayrollTaxesFormFeatureService,
    UsefulWebsitesFormFeatureService,
    GoogleTagManagerFeatureService,
    { provide: USAGE_TIME_ENTRY_DATA, useValue: $usageTimeEntryData },
    { provide: USAGE_TIME_APP_PAGES_DATA, useValue: usageTimeAppPages },
  ],
};
