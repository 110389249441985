import { BP_VISA_TYPE_COUNTRY_NAME } from '@joorney/business-plan-shared-frontend-business-plan-domain';
import { createSelector } from '@ngrx/store';
import { contextFeature } from './context.reducer';

export const {
  selectBusinessPlanVersions: $contextBusinessPlanVersions,
  selectCurrentBusinessPlan: $contextCurrentBusinessPlan,
  selectBusinessplanApplicants: $contextBusinessPlanApplicants,
  selectContextState: $contextGetState,
  selectCurrentBusinessPlanYearLabels: $contextCurrentBusinessPlanYearLabels,
  selectFeatureConfiguration: $contextCurrentBusinessPlanFeatureConfiguration,
  selectLastBPVersionId: $contextCurrentBusinessPlanLastBusinessPlanVersionId,
  selectLastBPVersionName: $contextCurrentBusinessPlanLastBusinessPlanVersionName,
  selectWrikeProject: $contextBusinessPlanWrikeProject,
} = contextFeature;

export const $contextHasCurrentBusinessPlan = createSelector($contextCurrentBusinessPlan, (currentBusinessPlan) => currentBusinessPlan !== null);

export const $contextCurrentBusinessPlanId = createSelector($contextCurrentBusinessPlan, (currentBusinessPlan) => currentBusinessPlan?.id);

export const $contextCurrentBusinessPlanAccountingPeriod = createSelector($contextCurrentBusinessPlan, (currentBusinessPlan) => currentBusinessPlan?.accountingPeriod);

export const $contextCurrentBusinessPlanStartingPoint = createSelector($contextCurrentBusinessPlan, (currentBusinessPlan) => currentBusinessPlan?.startingPoint);

export const $contextCurrentBusinessPlanIsOutdated = createSelector($contextCurrentBusinessPlan, (bp) => (bp === null ? false : bp.isOutdated));

export const $contextCurrentBusinessPlanParentId = createSelector($contextCurrentBusinessPlan, (currentBusinessPlan) => currentBusinessPlan?.bpInitialId);

export const $contextCurrentBusinessPlanName = createSelector($contextCurrentBusinessPlan, (currentBusinessPlan) => currentBusinessPlan?.name);

export const $contextCurrentBusinessPlanSaveName = createSelector($contextCurrentBusinessPlan, (currentBusinessPlan) => currentBusinessPlan?.saveName);

export const $contextCurrentBusinessPlanType = createSelector($contextCurrentBusinessPlan, (currentBusinessPlan) => currentBusinessPlan?.visaType);

export const $contextCurrentBusinessPlanSalesForecastType = createSelector($contextCurrentBusinessPlan, (currentBusinessPlan) => currentBusinessPlan?.salesForecastType);

export const $contextCurrentBusinessPlanVersion = createSelector($contextCurrentBusinessPlan, (currentBusinessPlan) => currentBusinessPlan?.id);

export const $contextBusinessPlanVisaTypeColor = createSelector($contextCurrentBusinessPlan, (currentBusinessPlan) => currentBusinessPlan?.visaTypeColor);

export const $contextBusinessPlanVisaTypeAcronym = createSelector($contextCurrentBusinessPlan, (currentBusinessPlan) => currentBusinessPlan?.visaTypeAcronym);

export const $contextBusinessPlanVisaTypeCountryCode = createSelector($contextCurrentBusinessPlan, (currentBusinessPlan) => currentBusinessPlan?.visaTypeCountryCode);

export const $contextBusinessPlanVisaTypeCountryName = createSelector(
  $contextCurrentBusinessPlan,
  (currentBusinessPlan) => BP_VISA_TYPE_COUNTRY_NAME[currentBusinessPlan?.visaTypeCountryCode ?? ''],
);

export const $contextCurrentBusinessPlanYearLabel = createSelector($contextCurrentBusinessPlan, (businessPlan) => businessPlan?.yearLabel);

export const $contextCurrentBusinessPlanRateTaxPayroll = createSelector($contextCurrentBusinessPlan, (businessPlan) => businessPlan?.rateTaxPayroll);
