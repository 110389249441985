/*###################################################################*/
/* VERSIONING
/*###################################################################*/

import { BusinessPlanUpdateDTO, Enums } from '@joorney/shell-shared-jwriter-core-api-data-access';

/*###################################################################*/
/* PREVIOUS VERSION
/*###################################################################*/

/**
 * LIST BP
 */
export interface ApplicantItem {
  id?: number;
  name?: string;
  firstName?: string;
  lastName?: string;
  title?: string;
}

export interface Applicant {
  id?: number;
  name?: string;
  title?: string;
}

/**
 * CRUD
 */
export interface BusinessPlanStepBusinessPlan extends Pick<BusinessPlanUpdateDTO, 'yearLabel' | 'startingYear' | 'wrikeProjectId'> {
  type?: string;
}

export type BusinessPlanStepCompanyApplicants = Pick<BusinessPlanUpdateDTO, 'name' | 'applicants'>;

export interface BusinessPlanStepFinancialSettings
  extends Pick<BusinessPlanUpdateDTO, 'saleOnCredit' | 'collectionDelayInDays' | 'percentageSalesOnCredit' | 'paymentDelay' | 'paymentDelayInDays'> {
  accountingPeriod?: number;
  startingPoint?: Enums['BusinessPlanStartingPoint'];
}
