import * as _ from 'lodash';

export enum Tendency {
  Same,
  IncreaseContD,
  DecreaseContD,
  Increase,
  Decrease,
}

export enum VisaTypeCountryCode {
  USA = 'USA',
  CAN = 'CAN',
  NZL = 'NZL',
  AUS = 'AUS',
}

export interface TendencyResult {
  from: number;
  to: number;
  tendency: Tendency;
  value: number;
}

const TENDENCIES_EQUIVALENCE_TABLE = {
  [Tendency.Same]: Tendency.Same,
  [Tendency.Increase]: Tendency.IncreaseContD,
  [Tendency.Decrease]: Tendency.DecreaseContD,
};

export const tendenciesCalculationFromValues = (values: number[]): TendencyResult[] => {
  if (_.isEmpty(values)) {
    return [];
  }

  return values.reduce((acc, curr, index) => {
    // We cannot calculate tendency for the first element
    if (index === 0) {
      return acc;
    }

    const prev = values[index - 1];
    // What is the tendency between the current and the previous value
    const currentTendency = curr > prev ? Tendency.Increase : curr < prev ? Tendency.Decrease : Tendency.Same;

    // If there is a previous tendency, and it is the same or an equivalent as the current one, we can merge them
    if (acc.length > 0) {
      const lastTendency = acc[acc.length - 1].tendency;

      if (lastTendency === currentTendency || lastTendency === TENDENCIES_EQUIVALENCE_TABLE[currentTendency]) {
        acc[acc.length - 1].to = index;
        acc[acc.length - 1].value = curr;
        acc[acc.length - 1].tendency = TENDENCIES_EQUIVALENCE_TABLE[currentTendency];
        return acc;
      }
    }

    // Push the first tendency
    acc.push({ from: index - 1, to: index, tendency: currentTendency, value: curr });
    return acc;
  }, [] as TendencyResult[]);
};

export const MANAGING_POSITIONS_EQUIVALENCE: Record<string, string> = {
  'General Manager': 'General Manager',
  'Chief Executive Officer': 'CEO',
  CEO: 'CEO',
  President: 'President',
};
export const MANAGING_POSITIONS = Object.keys(MANAGING_POSITIONS_EQUIVALENCE);

const TITLE_POSITION = 0;
const SUBJECT_PRONOUN_CONVERTER: Record<string, string> = {
  mr: 'He',
  mrs: 'She',
  ms: 'She',
};
const OBJECT_PRONOUN_CONVERTER: Record<string, string> = {
  mr: 'His',
  mrs: 'Her',
  ms: 'Her',
};
const POSSESSIVE_ADJECTIVE_CONVERTER: Record<string, string> = {
  mr: 'Him',
  mrs: 'Her',
  ms: 'Her',
};

const getCountryName = (visaTypeCountryCode: VisaTypeCountryCode) => {
  switch (true) {
    case visaTypeCountryCode === VisaTypeCountryCode.USA:
      return 'United States of America';
    case visaTypeCountryCode === VisaTypeCountryCode.CAN:
      return 'Canada';
    case visaTypeCountryCode === VisaTypeCountryCode.NZL:
      return 'New Zealand';
    case visaTypeCountryCode === VisaTypeCountryCode.AUS:
      return 'Australia';
    default:
      return '';
  }
};

export const applicantPositionText = (companyName: string, visaTypeCountryCode: VisaTypeCountryCode, applicantPositions: { positionName: string; applicantName: string }[]) => {
  return applicantPositions.map(({ positionName, applicantName }) => {
    const title = applicantName.split(' ')[TITLE_POSITION].replace('.', '').toLowerCase();
    const subjectPronoun = SUBJECT_PRONOUN_CONVERTER[title] ?? '';
    const objectPronoun = OBJECT_PRONOUN_CONVERTER[title] ?? '';
    const possessiveAdjective = POSSESSIVE_ADJECTIVE_CONVERTER[title] ?? '';
    const countryName = getCountryName(visaTypeCountryCode);
    return `The Applicant ${applicantName} will occupy the position of ${positionName} at ${companyName} and oversee the Company’s expansion. ${subjectPronoun} will be responsible for developing the Company’s entire base of operations. ${objectPronoun} extensive professional experience has equipped ${possessiveAdjective.toLowerCase()} with the skills necessary to succeed in leading the growth and development of the Company in ${countryName}.`;
  });
};
