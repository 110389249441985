import { contextActions } from '@joorney/business-plan-shared-frontend-context-store';
import { stackClosed } from '@joorney/shell-shared-frontend-feature-stack';
import { UsefulWebsitesCategoriesElementDTO, UsefulWebsitesElementDTO } from '@joorney/shell-shared-jwriter-core-api-data-access';
import { createFeature, createReducer, on } from '@ngrx/store';
import { produce } from 'immer';
import { USEFUL_WEBSITES_FEATURE_KEY, usefulWebsitesActions } from './useful-websites-store.actions';

export interface UsefulWebsitesFormData {
  link?: string;
  countryCode?: string;
  categoryCountryId?: number;
}

export interface UsefulWebsitesFeatureState {
  data: UsefulWebsitesElementDTO[];
  categories: UsefulWebsitesCategoriesElementDTO[];
  formData: UsefulWebsitesFormData;
  selectedRowId: number | null;
  selectedCategoryCountryId: number | null;
  isMine: boolean;
}

const initialState: UsefulWebsitesFeatureState = {
  data: [],
  categories: [],
  formData: {},
  selectedRowId: null,
  selectedCategoryCountryId: null,
  isMine: false,
};

export const usefulWebsitesFeature = createFeature({
  name: USEFUL_WEBSITES_FEATURE_KEY,
  reducer: createReducer(
    initialState,
    on(usefulWebsitesActions.dataInitialization, (state, { isMine }) =>
      produce(state, (draft) => {
        draft.isMine = isMine ?? false;
      }),
    ),
    on(usefulWebsitesActions.dataLoaded, (state, { data }) =>
      produce(state, (draft) => {
        draft.data = data;
      }),
    ),
    on(usefulWebsitesActions.categoriesLoaded, (state, { data }) =>
      produce(state, (draft) => {
        draft.categories = data;
      }),
    ),
    on(usefulWebsitesActions.addNewClicked, (state) =>
      produce(state, (draft) => {
        draft.selectedRowId = null;
      }),
    ),
    on(usefulWebsitesActions.tableRowClicked, (state, { data }) =>
      produce(state, (draft) => {
        draft.selectedRowId = data.id;
      }),
    ),
    on(usefulWebsitesActions.saveNewFinished, (state, { rowId }) =>
      produce(state, (draft) => {
        draft.selectedRowId = rowId;
      }),
    ),
    on(stackClosed, (state) =>
      produce(state, (draft) => {
        draft.selectedRowId = null;
      }),
    ),
    on(usefulWebsitesActions.formDataUpdated, (state, { data }) =>
      produce(state, (draft) => {
        draft.formData = data;
      }),
    ),
    on(usefulWebsitesActions.formDataFinalized, (state) =>
      produce(state, (draft) => {
        draft.formData = initialState.formData;
      }),
    ),
    on(usefulWebsitesActions.categoryFilterChanged, (state, { id }) =>
      produce(state, (draft) => {
        draft.selectedCategoryCountryId = id;
      }),
    ),
    on(contextActions.currentBusinessPlanUnselected, (state) =>
      produce(state, (draft) => {
        draft.selectedCategoryCountryId = null;
      }),
    ),
    on(usefulWebsitesActions.dataFinalize, () => initialState),
  ),
});
