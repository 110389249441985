import { Injectable } from '@angular/core';
import { wrikeProjectFeatureControllerGetProjectById, wrikeProjectFeatureControllerGetProjects } from '@joorney/shell-shared-jdata-wrike-api-data-access';
import { throwErrorOnInvalidValue } from '@joorney/utils-shared-rxjs-utils';
import { from } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class WrikeProjectDataAccessService {
  getProjects(title: string, limit: number) {
    return from(wrikeProjectFeatureControllerGetProjects({ title, limit })).pipe(throwErrorOnInvalidValue());
  }

  findById(wrikeProjectId: string) {
    return from(wrikeProjectFeatureControllerGetProjectById({ wrikeProjectId })).pipe(throwErrorOnInvalidValue());
  }
}
