import { makeEnvironmentProviders } from '@angular/core';
import { Provider } from '@nestjs/common';
import { provideEffects } from '@ngrx/effects';
import { MessageService } from 'primeng/api';
import * as toastEffects from './toast.effects';

export const TOAST_STORE_PROVIDERS: Provider[] = [MessageService];

export function provideToastStore() {
  return makeEnvironmentProviders([provideEffects([toastEffects]), ...TOAST_STORE_PROVIDERS]);
}
