import { inject } from '@angular/core';
import { $routeBusinessPlanIdParam } from '@feature/business-plan/core/store/router/router.selectors';
import { BusinessPlanCompareVisaTypeModalFeatureService } from '@joorney/business-plan-jwriter-financial-business-plan-compare-visa-type-modal-feature';
import { PayrollTaxesFormFeatureService } from '@joorney/business-plan-jwriter-financial-payroll-taxes-form-feature';
import { BusinessPlanDataAccessService } from '@joorney/business-plan-shared-frontend-business-plan-data-access';
import { EmployeeDataAccessService } from '@joorney/business-plan-shared-frontend-employee-data-access';
import { FeatureConfigurationDataAccessService } from '@joorney/business-plan-shared-frontend-feature-configuration-data-access';
import { getYearLabels } from '@joorney/computation-shared-frontend-generated-computed-data-data-access';
import { toastActions } from '@joorney/shell-shared-frontend-toast-store';
import { WrikeProjectDataAccessService } from '@joorney/wrike-shared-frontend-wrike-project-data-access';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store, createSelector } from '@ngrx/store';
import { catchError, combineLatest, concatMap, filter, map, of, switchMap, tap } from 'rxjs';
import { contextActions } from './context.actions';
import { $contextCurrentBusinessPlanId, $contextCurrentBusinessPlanRateTaxPayroll, $contextCurrentBusinessPlanType } from './context.selectors';

export const initBusinessPlanFromRouteParam$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) => {
    return actions$.pipe(
      ofType(contextActions.initBusinessPlanFromRouteParam),
      switchMap(() => store.select($routeBusinessPlanIdParam)),
      filter((businessPlanId) => businessPlanId !== undefined),
      map((businessPlanId) => contextActions.initWithBusinessPlanId({ businessPlanId: businessPlanId as number })),
    );
  },
  { functional: true },
);

export const resetBusinessPlanFromRouteParam$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) => {
    return actions$.pipe(
      ofType(contextActions.initBusinessPlanFromRouteParam),
      switchMap(() => store.select($routeBusinessPlanIdParam)),
      filter((businessPlanId) => businessPlanId === undefined),
      map(() => contextActions.businessPlanLeft()),
    );
  },
  { functional: true },
);

export const updateBusinessPlanYearLabels$ = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(contextActions.initCurrentBusinessPlanFinished),
      map(({ businessPlan }) => (businessPlan ? getYearLabels(businessPlan) : [])),
      map((yearLabels) => contextActions.setCurrentBusinessPlanYearLabels({ yearLabels })),
    );
  },
  { functional: true },
);

export const initWithBusinessPlanId$ = createEffect(
  (actions$ = inject(Actions), businessPlanDataAccessService = inject(BusinessPlanDataAccessService)) => {
    return actions$.pipe(
      ofType(contextActions.initWithBusinessPlanId),
      switchMap(({ businessPlanId }) =>
        combineLatest({
          businessPlan: businessPlanDataAccessService.getBusinessPlanById(businessPlanId),
          lastBusinessPlanVersion: businessPlanDataAccessService.getLastBusinessPlanVersion(businessPlanId),
        }).pipe(
          map(({ businessPlan, lastBusinessPlanVersion }) => ({ ...businessPlan, lastBPVersionId: lastBusinessPlanVersion.id, lastBPVersionName: lastBusinessPlanVersion.name })),
          map((businessPlan) => contextActions.initCurrentBusinessPlanFinished({ businessPlan })),
          catchError(() => of(contextActions.initCurrentBusinessPlanFailed())),
        ),
      ),
    );
  },
  { functional: true },
);

export const initBusinessPlanVersions$ = createEffect(
  (actions$ = inject(Actions), businessPlanDataAccessService = inject(BusinessPlanDataAccessService)) => {
    return actions$.pipe(
      ofType(contextActions.initWithBusinessPlanId),
      switchMap(({ businessPlanId }) => businessPlanDataAccessService.getBusinessPlanVersions(businessPlanId)),
      map((businessPlanVersions) => contextActions.initBusinessPlanVersionsFinished({ businessPlanVersions })),
    );
  },
  { functional: true },
);

export const initBusinessPlanApplicants$ = createEffect(
  (actions$ = inject(Actions), employeeDataAccessService = inject(EmployeeDataAccessService)) => {
    return actions$.pipe(
      ofType(contextActions.initWithBusinessPlanId),
      switchMap(({ businessPlanId }) => employeeDataAccessService.getBusinessPlanApplicants(businessPlanId)),
      map((applicants) => contextActions.businessPlanApplicantsReceived({ applicants })),
    );
  },
  { functional: true },
);

export const initBusinessPlanFeatureConfiguration$ = createEffect(
  (actions$ = inject(Actions), featureConfigurationDataAccessService = inject(FeatureConfigurationDataAccessService)) => {
    return actions$.pipe(
      ofType(contextActions.initWithBusinessPlanId),
      switchMap(({ businessPlanId }) => featureConfigurationDataAccessService.getByBusinessPlanId(businessPlanId)),
      map((featureConfiguration) => contextActions.businessPlanFeatureConfigurationReceived({ featureConfiguration })),
    );
  },
  { functional: true },
);

export const initBusinessPlanWrikeProject$ = createEffect(
  (actions$ = inject(Actions), wrikeProjectDataAccessService = inject(WrikeProjectDataAccessService)) => {
    return actions$.pipe(
      ofType(contextActions.initCurrentBusinessPlanFinished),
      map(({ businessPlan: { wrikeProjectId } }) => wrikeProjectId),
      filter((wrikeProjectId) => wrikeProjectId !== null),
      switchMap((wrikeProjectId) => wrikeProjectDataAccessService.findById(wrikeProjectId as string)),
      map((wrikeProject) => contextActions.businessPlanWrikeProjectReceived({ wrikeProject })),
    );
  },
  { functional: true },
);

const $contextCompareInfo = createSelector({
  visaType: $contextCurrentBusinessPlanType,
  bpId: $contextCurrentBusinessPlanId,
});
export const compareBusinessPlanVisaType$ = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    businessPlanDataAccessService = inject(BusinessPlanDataAccessService),
    businessPlanCompareVisaTypeModalFeatureService = inject(BusinessPlanCompareVisaTypeModalFeatureService),
  ) => {
    return actions$.pipe(
      ofType(contextActions.businessPlanVisaTypeChanged),
      concatLatestFrom(() => store.select($contextCompareInfo)),
      filter(([{ newVisaTypeId }, { visaType }]) => newVisaTypeId !== visaType),
      // * we use switchMap instead of concatMap to always have the latest comparison. This is to ensure the user is viewing the correct information on the latests change he made.
      switchMap(([{ newVisaTypeId }, { visaType, bpId }]) =>
        businessPlanDataAccessService
          .getBPVisaTypeComparison(bpId as number, newVisaTypeId)
          .pipe(map((data) => ({ ...data, currentVisaType: visaType ?? '', newVisaType: newVisaTypeId }))),
      ),
      switchMap((data) => businessPlanCompareVisaTypeModalFeatureService.openDialog$(data).pipe(map((isConfirmed) => ({ isConfirmed, newVisaTypeId: data.newVisaType })))),
      map(({ isConfirmed, newVisaTypeId }) =>
        isConfirmed ? contextActions.businessPlanVisaTypeChangeConfirmed({ newVisaTypeId }) : contextActions.businessPlanVisaTypeChangeDeclined(),
      ),
    );
  },
  { functional: true },
);

export const updateBusinessPlanVisaType$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store), businessPlanDataAccessService = inject(BusinessPlanDataAccessService)) => {
    return actions$.pipe(
      ofType(contextActions.businessPlanVisaTypeChangeConfirmed),
      concatLatestFrom(() => store.select($contextCurrentBusinessPlanId)),
      concatMap(([{ newVisaTypeId }, bpId]) => businessPlanDataAccessService.updateBPVisaType(bpId as number, newVisaTypeId)),
      map(({ newBusinessPlanId }) => contextActions.businessPlanVisaTypeChangeFinished({ versionId: newBusinessPlanId })),
    );
  },
  { functional: true },
);

export const refreshContextBusinessPlan$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) => {
    return actions$.pipe(
      ofType(contextActions.businessPlanModified),
      concatLatestFrom(() => store.select($contextCurrentBusinessPlanId)),
      filter(([, businessPlanId]) => businessPlanId !== null),
      map(([, businessPlanId]) => contextActions.initWithBusinessPlanId({ businessPlanId: businessPlanId as number })),
    );
  },
  { functional: true },
);

export const editPayrollTaxRate = createEffect(
  (action$ = inject(Actions), store = inject(Store), payrollTaxesFormFeatureService = inject(PayrollTaxesFormFeatureService)) => {
    return action$.pipe(
      ofType(contextActions.payrollTaxRateEdited),
      concatLatestFrom(() => store.select($contextCurrentBusinessPlanRateTaxPayroll)),
      tap(([, rateTaxPayroll]) => payrollTaxesFormFeatureService.open(rateTaxPayroll as number)),
    );
  },
  { functional: true, dispatch: false },
);

export const savePayrollTaxRate = createEffect(
  (action$ = inject(Actions), store = inject(Store), businessPlanDataAccessService = inject(BusinessPlanDataAccessService)) => {
    return action$.pipe(
      ofType(contextActions.payrollTaxRateSaveClicked),
      concatLatestFrom(() => store.select($contextCurrentBusinessPlanId)),
      concatMap(([{ data }, bpId]) =>
        businessPlanDataAccessService.updateParams(bpId as number, { rateTaxPayroll: data }).pipe(
          map(() => contextActions.payrollTaxRateSaveFinished()),
          catchError(() => of(contextActions.payrollTaxRateSaveFailed())),
        ),
      ),
    );
  },
  { functional: true },
);

export const displaSuccessMessage$ = createEffect(
  (action$ = inject(Actions)) => {
    return action$.pipe(
      ofType(contextActions.payrollTaxRateSaveFinished),
      concatMap(() => [toastActions.displaySuccessMessage({}), contextActions.businessPlanModified()]),
    );
  },
  { functional: true },
);

export const displayErrorMessage$ = createEffect(
  (action$ = inject(Actions)) => {
    return action$.pipe(
      ofType(contextActions.payrollTaxRateSaveFailed),
      map(() => toastActions.displayErrorMessage({})),
    );
  },
  { functional: true },
);
