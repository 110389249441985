import { GeneratedContentPersonnel } from '@joorney/computation-shared-frontend-sections-domain';
import { noDigitsCurrencyTransform } from '@joorney/utils-shared-frontend-ng-value-format-utils';

export const generateConclusionText = (
  personnelData: GeneratedContentPersonnel,
  totalSales: number[],
  companyName: string,
  firstApplicantName: string,
  yearLabels: string[],
  locale: string,
) => {
  const firstYearPosition = 0;
  const lastYearPosition = yearLabels.length - 1;

  const firstYear = yearLabels[firstYearPosition];
  const lastYear = yearLabels[lastYearPosition];

  const lastYearEmployees = personnelData.total?.year?.[lastYearPosition].totalNumberOfEmployees ?? 0;

  const firstYearPayrollExpenses = personnelData.total?.year?.[firstYearPosition].totalPayrollExpenses ?? 0;
  const lastYearPayrollExpenses = personnelData.total?.year?.[lastYearPosition].totalPayrollExpenses ?? 0;
  const firstYearPayrollExpensesFormatted = noDigitsCurrencyTransform(firstYearPayrollExpenses, locale);
  const lastYearPayrollExpensesFormatted = noDigitsCurrencyTransform(lastYearPayrollExpenses, locale);

  const firstYearTotalSales = totalSales[firstYearPosition] ?? 0;
  const lastYearTotalSales = totalSales[lastYearPosition] ?? 0;
  const firstYearTotalSalesFormatted = noDigitsCurrencyTransform(firstYearTotalSales, locale);
  const lastYearTotalSalesFormatted = noDigitsCurrencyTransform(lastYearTotalSales, locale);

  return `By the end of ${lastYear}, ${companyName} will reach a total of ${lastYearEmployees} in-house employees. Payroll expenses are expected to increase from ${firstYearPayrollExpensesFormatted} in ${firstYear} to ${lastYearPayrollExpensesFormatted} in ${lastYear}. Under ${firstApplicantName} management, the Company’s total sales are expected to increase from ${firstYearTotalSalesFormatted} in ${firstYear} to ${lastYearTotalSalesFormatted} in ${lastYear}. `;
};
