export interface GeneratedContentBalanceSheet {
  year: GeneratedContentBalanceSheetYear[];
}

export interface GeneratedContentBalanceSheetYear {
  '@n'?: number;
  assets?: GeneratedContentBalanceSheetYearAssets;
  capital?: GeneratedContentBalanceSheetYearCapital;
  liabilities?: GeneratedContentBalanceSheetYearLiabilities;
  totalLiabilitiesAndCapital?: number;
}

export interface GeneratedContentBalanceSheetYearAssets {
  currentAssets?: GeneratedContentBalanceSheetYearAssetsCurrentAssets;
  longTermAssets?: GeneratedContentBalanceSheetYearAssetsLongTermAssets;
  totalAssets?: number;
}

export interface GeneratedContentBalanceSheetYearAssetsCurrentAssets {
  cashAndOtherCurrentAssets?: number;
  accountReceivable?: number;
  totalCurrentAssets?: number;
}

export interface GeneratedContentBalanceSheetYearAssetsLongTermAssets {
  longTermAssetsAmount?: number;
  accumulatedDepreciation?: number;
  totalLongTermAssets?: number;
}

export interface GeneratedContentBalanceSheetYearCapital {
  paidinCapital?: number;
  retainedEarnings?: number;
  earnings?: number;
  totalCapital?: number;
}

export interface GeneratedContentBalanceSheetYearLiabilities {
  accountPayable?: number;
  currentLiabilities?: number;
  longTermLiabilities?: number;
  totalLiabilities?: number;
}
