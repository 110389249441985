import { $contextHasCurrentBusinessPlan } from '@joorney/business-plan-shared-frontend-context-store';
import { JwwNavSection } from '@joorney/layout-shared-frontend-tab-nav-domain';
import { environment } from '@joorney/shell-jwriter-word-environment-data-access';
import { createSelector } from '@ngrx/store';
import * as mpackage from 'package.json';
import { tabNavActions } from './tab-nav.actions';
import { tabNavFeature } from './tab-nav.reducer';

const { version } = mpackage;

export const { selectActiveSectionId: $tabNavActiveSectionId, selectShowBetaMode: $tabNavShowBetaMode } = tabNavFeature;

export const $tabNavSectionListFiltered = createSelector($tabNavShowBetaMode, $contextHasCurrentBusinessPlan, (showBetaMode, hasSelectedBusinessPlan) => {
  const items = [
    { id: JwwNavSection.Financial, label: 'Financial', icon: 'pi pi-table' },
    { id: JwwNavSection.KnowledgeBase, label: 'Knowledge Base', icon: 'pi pi-align-justify' },
    { id: JwwNavSection.MarketingSection, label: 'Marketing Section', icon: 'pi pi-megaphone' },
    { id: JwwNavSection.DataSync, label: 'Data Sync', icon: 'pi pi-sync' },
    { id: JwwNavSection.Beta, label: 'Beta', icon: 'pi pi-list', visible: environment.showContentAndBetaTab && showBetaMode },
  ].filter((i) => i.visible !== false);
  return hasSelectedBusinessPlan ? items : items.filter(({ id }) => [JwwNavSection.Beta, JwwNavSection.Financial].includes(id));
});

export const $tabNavSubMenuItems = createSelector($tabNavShowBetaMode, (showBetaMode) =>
  [
    {
      label: showBetaMode ? 'Exit Beta Mode' : 'Enter Beta Mode',
      action: tabNavActions.tabNaBetaModeClicked(),
      visible: environment.showContentAndBetaTab,
    },
    {
      label: 'Debug',
      action: tabNavActions.goToDebugPageClicked(),
      visible: environment.showContentAndBetaTab,
    },
    {
      label: 'Poc Page',
      action: tabNavActions.goToPocPageClicked(),
      visible: environment.showContentAndBetaTab,
    },
    {
      label: 'Sign out',
      action: tabNavActions.signOutClicked(),
    },
    { label: `v${version}`, disabled: true },
  ].filter(({ visible }) => visible !== false),
);
